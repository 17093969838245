<div class="container">
  <div class="container-inventory" [@inOutAnimation]>
    <div class="header">
      <h1>{{'NEW_USER' | translate}}</h1>
      <app-back-arrow-settings [callback]="setListingUsers"></app-back-arrow-settings>
    </div>
    <section>
      <div class="white-card" *ngIf="!loadModal; else confirmation" [@inOutAnimation]>
          <div class="alert-span-exeption">
            <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
          </div>

          <form action="post" [formGroup]="signUpForm" (ngSubmit)="onSignUp()">
            <div class="flex-row">
              <div class="form-group">
                <label for="firstname-input">{{'NAME' | translate}}</label>
                <input type="text" name="firstname-input" class="textInput" id="firstname-input" formControlName="givenName" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['givenName'].touched && signUpFormValidation['givenName'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenName'].errors && signUpFormValidation['givenName'].errors['required']">
                    {{'REQUIRED_FIELD' | translate}}
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenName'].errors && (signUpFormValidation['givenName'].errors['pattern'] || signUpFormValidation['givenName'].errors['minlength'])">
                    {{"INVALID_NAME_FORMAT" | translate}}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="lastname-input">{{'LAST_NAME' | translate}}</label>
                <input type="text" name="lastname-input" class="textInput" id="lastname-input" formControlName="familyName" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['familyName'].touched && signUpFormValidation['familyName'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['familyName'].errors && signUpFormValidation['familyName'].errors['required']">
                    {{'REQUIRED_FIELD' | translate}}
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['familyName'].errors && (signUpFormValidation['familyName'].errors['pattern'] || signUpFormValidation['familyName'].errors['minlength'])">
                    {{"INVALID_NAME_FORMAT" | translate}}
                  </label>
                </div>
              </div>
            </div>
            <div class="flex-row">
              <div class="form-group">
                <label for="email-input">{{'EMAIL' | translate}}</label>
                <input type="text" name="email" class="textInput" id="email-input" formControlName="givenEmail" (focus)="exceptionMessage = ''">
                <div class="alert-span" *ngIf="signUpFormValidation['givenEmail'].touched && signUpFormValidation['givenEmail'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['required']" [@inOutAnimation]>
                    {{'REQUIRED_FIELD' | translate}}
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenEmail'].errors && signUpFormValidation['givenEmail'].errors['pattern'] && signUpFormValidation['givenEmail'].errors['email']" [@inOutAnimation]>
                    {{ 'INVALID_EMAIL_FORMAT' | translate }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label for="password-input">{{ 'TEMPORARY_PASSWORD' | translate }}</label>
                <div class="password-input">
                  <input [type]="isPasswordVisible ? 'text':'password'" id="password-input" name="password" class="textInput" formControlName="givenPassword" (focus)="exceptionMessage = ''">
                  <i
                    class="password-icon"
                    [ngClass]="isPasswordVisible ? 'hide-password' : 'show-password'"
                    (click)="isPasswordVisible = !isPasswordVisible"
                  ></i>
                </div>
                <div class="alert-span" *ngIf="signUpFormValidation['givenPassword'].touched && signUpFormValidation['givenPassword'].invalid">
                  <label class="error-message" *ngIf="signUpFormValidation['givenPassword'].touched && signUpFormValidation['givenPassword'].errors && signUpFormValidation['givenPassword'].errors['required']" [@inOutAnimation]>
                    {{'REQUIRED_FIELD' | translate}}
                  </label>
                  <label class="error-message" *ngIf="signUpFormValidation['givenPassword'].errors && signUpFormValidation['givenPassword'].errors['minlength']">
                    >{{ 'PASSWORD_MINIMUM_LENGTH' | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div class="permissions-container" *ngIf="permissionsFormGroup" [formGroup]="permissionsFormGroup">
              <h2>{{ 'ACCESS_PERMISSIONS' | translate }}</h2>
              <div class="option-container">
                <div class="options-column">
                  <mat-slide-toggle formControlName="home" class="switch">{{'START' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="sales" class="switch">{{'ORDERS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="pos" class="switch">POS</mat-slide-toggle>
                  <mat-slide-toggle formControlName="cashRegisters" class="switch">{{'CASH_REGISTER' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="products" class="switch">{{'PRODUCTS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="giftCards" class="switch">{{'GIFT_CARDS' | translate}}</mat-slide-toggle>
                </div>
                <div class="options-column">
                  <mat-slide-toggle formControlName="coupons" class="switch">{{'COUPONS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="clients" class="switch">{{'CLIENTS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="exportClients" class="switch"> {{'DOWNLOAD_FILE' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="calendar" class="switch">Agenda</mat-slide-toggle>
                  <mat-slide-toggle formControlName="config" class="switch">{{'CONFIGURATION' | translate}}</mat-slide-toggle>
                </div>
              </div>
              <h4 *ngIf="configAcces">{{ 'CONFIGURATION_PERMISSIONS' | translate }}</h4>
              <div class="option-container" *ngIf="configAcces">
                <div class="options-column">
                  <mat-slide-toggle formControlName="generalInfo" class="switch">{{'GENERAL_INFORMATION' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="userRoles" class="switch">{{'USERS_AND_ROLES' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="categories" class="switch">{{'CATEGORIES' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="collections" class="switch">{{'COLLECTIONS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="supliers" class="switch">{{'SUPPLIERS' | translate}}</mat-slide-toggle>
                </div>
                <div class="options-column">
                  <mat-slide-toggle formControlName="shopPoints" class="switch">{{ 'POINTS_OF_SALE' | translate }}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="paymentMethods" class="switch">{{'PAYMENT_METHODS' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="themingService" class="switch">{{'STORE_THEME' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="pages" class="switch">{{'PAGES' | translate}}</mat-slide-toggle>
                  <mat-slide-toggle formControlName="faqs" class="switch">{{'FREQUENT_QUESTIONS' | translate}}</mat-slide-toggle>
                </div>
              </div>
            </div>

          <div class="form-buttons">
            <button class="create-btn" type="submit" [disabled]="signUpForm.invalid">{{'CREATE' | translate}}</button>
          </div>
        </form>
      </div>

      <ng-template #confirmation>
        <div class="white-card" [@inOutAnimation]>
          <div class="confirmation-modal">
            <h3>{{ 'USER_CREATED_SUCCESS' | translate }}</h3>
            <img src="../../../assets/illustrations/account-created.png">
            <p>{{ 'CREATE_ANOTHER_USER' | translate }}</p>
            <div class="modal-buttons">
              <button class="submit-btn disable" (click)="onCreateAnotherUser()">{{ 'YES_ADD_ANOTHER' | translate }}</button>
              <button class="submit-btn remove" (click)="setListingUsers()">{{ 'NO_GO_TO_USERS' | translate }}</button>
            </div>
          </div>
        </div>
      </ng-template>
    </section>
  </div>
</div>
