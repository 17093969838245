import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as feather from 'feather-icons';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService, ShopTheming } from 'src/app/theming.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  loading = true;
  productId: number = 0;
  product: any;
  modules = {};

  productImages: any = [];
  productImagesToDelete: any = [];

  imageSizes: any = {
    thumbnail: 300,
    small: 400,
    medium: 600,
    main: undefined,
  };

  currency = "";
  sale = 0
  discountPercentage = 0;
  discountPrice = 0;
  firstSale=1;

  pdfFileName: string | null | undefined;
  pdfFile: File | null = null;
  productFile: string = '';
  pdfSrc: string | null = null;
  timestamp: number;

  enabledSave = true;
  duplicatedVariants = false;
  emptyVariantValue = false;

  variantsOne: any = {
    variantType: '',
    variantValues: []
  };

  variantsTwo: any = {
    variantType: '',
    variantValues: []
  };

  variantsThree: any = {
    variantType: '',
    variantValues: []
  };

  taxSelected: any;
  taxActive: any;
  taxes: any[] = [];
  items: any = [];
  variants: any[] = [];
  deleteItems: any[] = [];
  categories: any;
  subCategories: any
  subSubCategories: any;
  industryTypes: any;
  suppliers: any;
  collections: any;
  variantValuesNames = <any[]>[];
  activeShopPoint = 0;
  shopPoints: any;
  productType: number = 1;
  originalImage: File | undefined;
  mainImage: string = '';
  mediumImage: string = '';
  smallImage: string = '';
  thumbnailImage: string = '';
  generalImages:any = []
  selectedImage: string = '';
  selectedItem: number = 0;
  checkIcon: string = '';
  itemRelationships: { [key: string]: string[] } = {};
  relationshipOptions: string[] = [
    "Titular","Conyuge", "Unión Libre", "Padre", "Madre", "Hijo", "Hija",
     "Suegro", "Suegra", "Gato", "Perro"
   ];

  noItemImage = 'https://storetest.mobydyg.com/assets/Static/missing-image.png'

  public shopTheme: ShopTheming[] = [];
  constructor(
    public appComponent: AppComponent,
    public route: ActivatedRoute,
    public router: Router,
    public apiService: ApiService,
    public themingService: ThemingService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    public el: ElementRef
  ) {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        //['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [
          {
            size: ['small', false, 'large', 'huge'],
            // size: [
            //   '8px',
            //   '10px',
            //   '12px',
            //   '14px',
            //   '16px',
            //   '18px',
            //   '20px',
            //   '22px',
            //   '24px',
            // ],
          },
        ], // custom dropdown

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        //['link', 'image', 'video'], // link and image, video
        ['link'],
        ['emoji'],
      ],
    };
    console.log(this.route.paramMap);

    this.route.paramMap.subscribe((params) => {
      let productId = Number(params.get('productId'));
      console.log("PRODUCT ID: ",productId);
      if (productId != this.productId) {
        this.appComponent.goToTop();
        this.productId = productId;
      }
    });
    this.timestamp = Date.now();
    this.shopTheme = this.themingService.shopTheming;
   }

   async ngOnInit(): Promise<void> {
    this.shopPoints = this.themingService.shopTheming[0].shopsLocation;
    await this.loadProduct().then(async () => {
      if (this.product?.product?.productFile) {
        this.pdfSrc = this.product.product.productFile;
        this.pdfFileName = this.product.product.productFile.split('/').pop();
      } else {
        this.pdfSrc = null;
        this.pdfFileName = null;
      }
      await this.getTaxes();
      await this.getCategory();
      await this.getSubCategoryByCategoryId(this.product.product.productCategoryId);
      await this.getSubSubCategoryByCategoryId(this.product.product.productSubCategoryId);
      await this.getSuppliers();
      await this.getCollections();
      await this.getIndustry();
    });
    this.checkIcon = feather.icons.check.toSvg();
  }

  selectImage(isSingleImage: number){
    if(isSingleImage === 0){
      this.selectedItem = 0
    }
    $('#btn-upload').click()
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.originalImage = file;

    // Crear una instancia de FileReader para leer el contenido del archivo
    const reader = new FileReader();

    // Callback que se ejecuta cuando se termina de leer el archivo
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;

      // Callback que se ejecuta cuando la imagen se carga completamente
      image.onload = async () => {
        // Redimensionar la imagen al tamaño máximo deseado
        const main = this.resizeImage(image, 800, 800);
        const medium = this.resizeImage(image, 600, 600);
        const small = this.resizeImage(image, 400, 400);
        const thumbnail = this.resizeImage(image, 300, 300);

        let timestamp = Date.now().toString()
        const mainFile = this.convertCanvasToFile(main, `${timestamp}-main.png`);
        const mediumFile = this.convertCanvasToFile(medium, `${timestamp}-medium.png`);
        const smallFile = this.convertCanvasToFile(small, `${timestamp}-small.png`);
        const thumbnailFile = this.convertCanvasToFile(thumbnail, `${timestamp}-thumbnail.png`);

        // Convertir las imágenes redimensionadas a cadenas base64
        this.mainImage = this.convertImageToBase64(main);
        this.mediumImage = this.convertImageToBase64(medium);
        this.smallImage = this.convertImageToBase64(small);
        this.thumbnailImage = this.convertImageToBase64(thumbnail);

        const images = await this.uploadS3Images(mainFile, mediumFile, smallFile, thumbnailFile);

        // Limpiar el input de tipo file
        const fileInput = event.target;
        fileInput.value = null;

        for (const item of images) {
          if(item.imageType === 'small'){
            this.generalImages.push({
              items: [],
              url: item.imageURL
            })
          }
        }

        const newItems = this.items.filter((item:any) => {
          return !this.generalImages.find((el:any) => el.items.includes(item.itemId))
        }).filter((item:any) => item.itemId > 0)

        const payload = newItems.map((item: any) => {
          return {
            itemId: item.itemId,
            imageURLs: images
          }
        })

        await this.apiService.createItemImages(payload)

        if(this.selectedItem !== 0){
          const newImage = images.find((item:any) => item.imageURL.includes('small')).imageURL
          if(newImage){
            this.asociateImage(newImage)
          }
        }

      };
    };

    // Leer el contenido del archivo como una URL de datos
    reader.readAsDataURL(file);
  }

  async deleteProductImage(image: string){
    this.generalImages = this.generalImages.filter((item: any) => item.url != image);
    image = image.replace('https://mobydyg-files.s3.amazonaws.com/', '').replace('small', 'size')
    const sizes = ['small', 'medium', 'thumbnail', 'main'];
    const deleteS3Primises = []
    const deleteImages = []
    for (const item of sizes) {
      const url = image.replace('size', item)
      deleteS3Primises.push(this.apiService.deleteFile(
        url
      ))
      //delete from db
      deleteImages.push({imageURL: `https://mobydyg-files.s3.amazonaws.com/${url}`});
    }
    await Promise.all(deleteS3Primises);
    await this.apiService.deleteItemImages(deleteImages);
  }

  private async uploadS3Images(mainFile: File, mediumFile: File, smallFile: File, thumbnailFile: File): Promise<any[]>{
    const data = [
      {
        url: `${this.themingService.shopId}/${environment.stage}/images/item-images/${mainFile.name}`,
        file: mainFile,
        options: {position: 1, type: 'main'}
      },
      {
        url: `${this.themingService.shopId}/${environment.stage}/images/item-images/${mediumFile.name}`,
        file: mediumFile,
        options: {position: 1, type: 'medium'}
      },
      {
        url: `${this.themingService.shopId}/${environment.stage}/images/item-images/${smallFile.name}`,
        file: smallFile,
        options: {position: 1, type: 'small'}
      },
      {
        url: `${this.themingService.shopId}/${environment.stage}/images/item-images/${thumbnailFile.name}`,
        file: thumbnailFile,
        options: {position: 1, type: 'thumbnail'}
      }
    ];

    const imagePromises = []

    for (const item of data) {
      item.options.position = Number(this.generalImages.length) + 1
      imagePromises.push(this.apiService.uploadFile(
        item.url,
        item.file,
        item.options
      ))
    }

    await Promise.all(imagePromises)

    const result = data.map(item => {
      return {
        imageURL: `https://mobydyg-files.s3.amazonaws.com/${item.url}`,
        position: item.options.position,
        imageType: item.options.type,
        isGeneral: 1
      }
    })
    return result;
  }

  private resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;

    // Redimensionar la imagen mientras se mantenga la proporción de aspecto
    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    // Dibujar la imagen redimensionada en el lienzo
    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, width, height);

    return canvas;
  }

  private convertImageToBase64(image: HTMLCanvasElement): string {
    return image.toDataURL('image/jpeg');
  }

  convertCanvasToFile(canvas: HTMLCanvasElement, fileName: string): File {
    const dataURL = canvas.toDataURL('image/jpeg');
    const base64Data = dataURL.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    return new File([blob], fileName);
  }

  openSelectImageMoldal(itemId: number){
    this.selectedImage = ''
    this.selectedItem = itemId
    const selectedItemImage = this.generalImages.find((item:any) => item.items.includes(itemId))
    if(selectedItemImage){
      this.selectedImage = selectedItemImage.url
    }
    $('#selectImagesModal').modal({ backdrop: 'static', keyboard: false });
    $('#selectImagesModal').modal('show');
    const input = document.querySelector('#item-image') as HTMLInputElement;
    const inputImage = document.querySelector('#image') as HTMLInputElement;
    if(input){
      input.value = itemId.toString()
    }
    if(inputImage){
      inputImage.value = this.selectedImage
    }
  }

  asociateImage(image: string){
    if(this.selectedImage !== image){
      this.selectedImage = image
    }else{
      this.selectedImage = ''
    }
    const input = document.querySelector('#image') as HTMLInputElement;
    if(input){
      input.value = image
    }
  }

  confirmImage(){
    const input = document.querySelector('#item-image') as HTMLInputElement;
    const inputImage = document.querySelector('#image') as HTMLInputElement;

    this.generalImages = this.generalImages.map((item: any) => {
      item.items = item.items.filter((item:any) => item !== Number(input.value))
      if(item.url === this.selectedImage && this.selectedImage !== ''){
        item.items.push(Number(input.value))
      }/*else if(this.selectedImage === '' && inputImage.value === item.url){
        item.items = item.items.filter((item:any) => item !== Number(input.value))
      }*/
      return item
    })

    const removeSingleImage = this.generalImages.some((item:any) => item.items.includes(Number(input.value)))

    let itemImages: any[] = []

    if(removeSingleImage){
      const image = inputImage.value.replace('small', 'size')
      itemImages = this.getItemImages(image, 0, 1);
    }else{
      const images = this.generalImages.map((item: any) => item.url)
      for (const [position, image] of images.entries()) {
        itemImages = itemImages.concat(this.getItemImages(image.replace('small','size'), 1, (position+1)))
      }
    }

    const payload = [{
      itemId: Number(input.value),
      imageURLs: itemImages
    }]
    this.apiService.createItemImages(payload)
    $('#selectImagesModal').modal('hide');
  }

  getItemImages(image: string, isGeneral: number, position: number){
    const sizes = ['small', 'medium', 'thumbnail', 'main'];
    const itemImages = []
    for (const item of sizes) {
      const imageURLs =
      {
        imageURL: image.replace('size', item),
        position,
        imageType: item,
        isGeneral
      }
      itemImages.push(imageURLs)
    }
    return itemImages
  }

  getImageByItem(itemId: number): string{
    const imageURL = this.generalImages.find((item:any) => item.items.includes(itemId));
    if(!imageURL){
      return this.noItemImage;
    }
    return imageURL.url;
  }



  async loadProduct() {
    this.loading = true;
    console.log("Theming: ",this.themingService.shopTheming);
    console.log(this.themingService.shopTheming[0].currencyCode)
    if(this.themingService.shopTheming[0].currencyCode != "CRC"){
      this.currency = "$";
    }else{
      this.currency = "₡";
    }
    await this.apiService
      .getProductById(this.productId, this.themingService.shopId)
      .then((data) => {
        console.log("Get product by id ",data);
        this.product = data;
        this.productType = this.product.product.productType;
        console.log(this.product.product.productDescription)
        //this.product.product.productDescription = decodeURI(this.product.product.productDescription);
        this.productImages = (<any>data).items[0].images || {};
        for (const key of Object.keys(this.productImages)) {
          this.productImages[key] = this.productImages[key] || [];
          for (let i = 0; i < this.productImages[key].length; i++) {
            this.productImages[key][i].imageURL =
              this.appComponent.randomCacheID(
                this.productImages[key][i].imageURL
              );
          }
        }

        //all images
        this.generalImages = []
        const dataItems = (<any>data).items
        const linkedImages = dataItems.map((item:any) => {
          return {
            item: item.itemId,
            url: item.images?.small.map((el:any) => el.imageURL.split('?')[0])
          }
        })

        const imagesUrls = dataItems.map((item:any) => item.images?.small.map((el:any) => el.imageURL?.split('?')[0]))

        let urls: any[] = []
        for (const url of imagesUrls) {
          urls = urls.concat(url)
        }

        urls = urls.map((item:any) => item?.split('?')[0])
        urls = urls.filter((element, index) => {
            return urls.indexOf(element) === index;
        }).filter((item:any) => item);

        const generalImagesArr = []
        for (const url of urls) {
          const items = linkedImages.filter((item:any) => item.url?.includes(url) && item.url.length === 1 ).map((item:any) => item.item)
          generalImagesArr.push({
            url,
            items: items
          })
        }

        this.generalImages = generalImagesArr

        this.product.product.productBaseCost = this.product.product.productBaseCost;
        this.product.product.productSellPrice = this.product.product.productSellPrice;

        this.product.variants[0] != undefined ? this.variantsOne = {
          ...this.product.variants[0]
        } : this.variantsOne = {
          variantType: '',
          variantValues: []
        };

        this.product.variants[1] != undefined ? this.variantsTwo = {
          ...this.product.variants[1]
        } : this.variantsTwo = {
          variantType: '',
          variantValues: []
        };

        this.product.variants[2] != undefined ? this.variantsThree = {
          ...this.product.variants[2]
        } : this.variantsThree = {
          variantType: '',
          variantValues: []
        };

        this.variants = this.product.variants;

        this.items = this.product.items.map((item: any) => {
          let itemShopPoints: any[] = [];
          let totalStock = 0;
          // this.product.shopPoints.forEach((shopPoint: any) => {
          //   totalStock += shopPoint.itemQuantity;
          //   itemShopPoints.push(
          //     {
          //       shopPointId: shopPoint.shopPointId,
          //       itemQuantity: shopPoint.itemQuantity
          //     }
          //   )
          // });


          let isAnyUnlimited = false;
          this.shopPoints.forEach((shopPoint: any) => {
            let arrayIndex = this.product.shopPoints.findIndex((sp: any) => sp.itemId == item.itemId && sp.shopPointId == shopPoint.shopPointId);
            if(arrayIndex >= 0){
              console.log(`index del item ${item.itemId} con shoppoint ${shopPoint.shopPointId} es ${arrayIndex}`);
              //const itemShopPoint = item.itemShopPoints[arrayIndex]
              let itemShopPoint = this.product.shopPoints[arrayIndex];
              itemShopPoints.push(
                {
                  shopPointId: shopPoint.shopPointId,
                  itemQuantity: itemShopPoint.itemQuantity,
                  itemUnlimited: itemShopPoint.itemUnlimited
                }
              )
              if(itemShopPoint.itemUnlimited == 1){
                isAnyUnlimited = true;
              }
              totalStock += itemShopPoint.itemQuantity;
            }else{
              itemShopPoints.push(
                {
                  shopPointId: shopPoint.shopPointId,
                  itemQuantity: 0,
                  itemUnlimited: 0
                }
              )
            }
          });
          return {
            itemId: item.itemId,
            itemActive: item.itemActive,
            itemCode: item.itemCode,
            //itemVariantCode: item.itemCode.substring(item.itemCode.length - 2 ,item.itemCode.length),
            //itemBaseCode: item.itemCode.substring(0 ,item.itemCode.length - 2),
            itemPrice: item.itemPrice.toFixed(2),
            itemDiscountPrice: item.itemDiscountPrice,
            itemQuantity: item.quantity,
            itemBeneficiaries: item.itemBeneficiaries,
            itemHolder: item.itemHolderRequired,
            variantValues: item.variantValues,
            itemShopPoints: itemShopPoints,
            itemTotalStock: isAnyUnlimited? 'Ilimitado' : totalStock,
            itemRelationship: item.itemRelationship ? item.itemRelationship.split(',') : []
          };
        });

        console.log("sps",this.shopPoints);
        console.log("isps",this.product.shopPoints);

        for (let item of this.items) {
          let variantValueNames: any = [];
          if(item.variantValues){
            for (let variantValue of item.variantValues) {
              variantValueNames.push({variantValueName: variantValue})
            }
          }
          item.variantValues = variantValueNames;
        }

        console.log('Items:', this.items);
        console.log('Product:', this.product);
      }).then(() => {
        this.discountPercentage = this.calculateDiscountPercentage();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        setTimeout(() => {
          feather.replace();
        }, 100);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    for (const key of Object.keys(this.imageSizes)) {
      moveItemInArray(
        this.productImages[key],
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  // downloadImage(position: number) {
  //   fetch(this.productImages.main[position].imageURL)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       let blobUrl = window.URL.createObjectURL(blob);
  //       let a = document.createElement('a');
  //       a.download =
  //         this.product.product.productCode + '-' + (position + 1) + '.png';
  //       a.href = blobUrl;
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     });
  // }

  deletePhoto(i: number) {
    let image = this.productImages.small[i];
    for (const key of Object.keys(this.productImages)) {
      if (image.file == undefined) {
        this.productImagesToDelete.push(
          JSON.parse(JSON.stringify(this.productImages[key][i].imageURL))
        );
      }
      this.productImages[key].splice(i, 1);
    }
    //console.log(this.productImagesToDelete);
  }

  async uploadImage() {
    var inputFile = $(
      '<input type="file" id="fileInput" multiple="true" style="visibility: hidden; height: 0; width: 0; overflow: hidden" />'
    );
    let aux_this = this;
    inputFile.bind('change', (event: any) => {
      aux_this.readFile(event.target.files);
    });
    inputFile.trigger('click');
  }

  async readFile(files: any) {
    let aux_this = this;
    for (let i = 0; i < (<any>files).length; i++) {
      var promise = new Promise(async (resolve, reject) => {
        let timestamp = Date.now().toString() + '-' + (i + 1);
        var reader = new FileReader();
        reader.readAsDataURL((<any>files)[i]); // read file as data url
        reader.onload = async () => {
          // called once readAsDataURL is completed
          if (reader.result != null) {
            var blob = (<any>files)[i].slice(
              0,
              (<any>files)[i].size,
              'image/png'
            );
            let mainFile = new File([blob], timestamp + '-main', {
              type: 'image/png',
            });
            aux_this.productImages.main = aux_this.productImages.main || [];
            aux_this.productImages.main.push({
              imageURL: this.sanitizer.bypassSecurityTrustResourceUrl(
                <any>reader.result
              ),
              file: mainFile,
            });

            let promises = [];

            for (const key of Object.keys(aux_this.imageSizes)) {
              promises.push(
                new Promise((resolve2, reject2) => {
                  if (aux_this.imageSizes[key] != undefined) {
                    let img = new Image();
                    img.src = <string>reader.result;
                    var c = document.createElement('canvas');
                    var blob;

                    img.onload = async function () {
                      c.width = aux_this.imageSizes[key];
                      c.height = c.width;

                      let newWidth = img.width;
                      let newHeight = img.height;
                      if (img.width > img.height) {
                        if (img.width > c.width) {
                          newHeight *= c.width / newWidth;
                          c.height = newHeight;
                          newWidth = c.width;
                        }
                      } else {
                        if (img.height > c.height) {
                          newWidth *= c.height / newHeight;
                          c.width = newWidth;
                          newHeight = c.height;
                        }
                      }
                      var ctx = c.getContext('2d');
                      (<any>ctx).drawImage(img, 0, 0, newWidth, newHeight); // draw in image
                      c.toBlob(
                        async function (blob0) {
                          blob = blob0;
                          (<any>blob).name = timestamp + '-' + key;
                          (<any>blob).lastModified = new Date();
                          let file = <File>blob;
                          var reader2 = new FileReader();
                          reader2.readAsDataURL(file); // read file as data url
                          reader2.onload = () => {
                            aux_this.productImages[key] =
                              aux_this.productImages[key] || [];
                            aux_this.productImages[key].push({
                              imageURL:
                                aux_this.sanitizer.bypassSecurityTrustResourceUrl(
                                  <any>reader2.result
                                ),
                              file: file,
                            });
                            resolve2(0);
                          };
                        },
                        'image/png',
                        1
                      );
                    };
                  } else {
                    resolve2(0);
                  }
                })
              );
            }
            await Promise.all(promises);
            resolve(0);
          } else {
            reject();
          }
        };
      });
    }
  }

  throttleActions(listOfCallableActions: any[], limit: number) {
    // We'll need to store which is the next promise in the list.
    let i = 0;
    let resultArray = new Array(listOfCallableActions.length);

    // Now define what happens when any of the actions completes. Javascript is
    // (mostly) single-threaded, so only one completion handler will call at a
    // given time. Because we return doNextAction, the Promise chain continues as
    // long as there's an action left in the list.
    function doNextAction(): any {
      if (i < listOfCallableActions.length) {
        // Save the current value of i, so we can put the result in the right place
        let actionIndex = i++;
        let nextAction = listOfCallableActions[actionIndex];
        return Promise.resolve(nextAction())
          .then((result) => {
            // Save results to the correct array index.
            resultArray[actionIndex] = result;
            return;
          })
          .then(doNextAction);
      }
    }

    // Now start up the original <limit> number of promises.
    // i advances in calls to doNextAction.
    let listOfPromises = [];
    while (i < limit && i < listOfCallableActions.length) {
      listOfPromises.push(doNextAction());
    }
    return Promise.all(listOfPromises).then(() => resultArray);
  }

  async getTaxes() {
    if(this.product.product.productTaxId == 1){
      this.taxes = [{
        "taxId": 1,
        "taxCountryId": 1,
        "taxCode": "0",
        "taxName": "Regimen Simplificado",
        "taxValue": 0,
        "taxParent": null,
        "taxActive": "1"
    }];
    }else{
      await this.apiService
        .getTaxes(this.themingService.shopId)
        .then((data: any) => {
          console.log("tax data: ",data)
          let prodTax = data.find((tax: any) => tax.taxId == this.product.product.productTaxId);
          console.log("prodtax: ",prodTax)
          console.log("prodtaxvalue: ",prodTax.taxValue)
          if(prodTax.taxValue == 0){
            this.taxActive = prodTax;
          }else{
            this.taxActive = data.find((tax: any) => tax.taxId == prodTax.taxParent);
          }
          console.log("taxActive: ",this.taxActive)
          this.taxes = (data.filter((taxes: any) => taxes.taxParent == this.taxActive.taxId));
          if(this.taxes.length > 0){
            console.log("taxes no existen",this.taxes)
            this.taxes = this.taxes.sort((a, b) => a.taxValue - b.taxValue);
            this.taxSelected = this.taxes.find((taxValue: any) => taxValue.taxId == this.product.product.productTaxId);
            this.taxSelected.taxValue = parseFloat(this.taxSelected.taxValue);
          }else{
            console.log("taxes",this.taxes)
            this.taxes = this.taxActive;
            console.log("taxes",this.taxes)
            this.taxSelected = this.taxActive;
            this.taxSelected.taxValue = parseFloat(this.taxSelected.taxValue);
          }
        })
        .catch((error) => {
          console.log("Error Getting Taxes");
          console.error(error);
        });
    }
  }

  async getCategory(): Promise<void> {
    this.loading = true;
    await this.apiService.getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
        console.log(this.loading)
      });
  }


  async getSubCategoryByCategoryId(categoryId: number) {
    await this.apiService
    .getSubCategoryByCategoryId( categoryId,this.themingService.shopId, )
    .then((data: any) => {
      this.subCategories = data.subCategories;
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
    });
  }


  async getSubSubCategoryByCategoryId(subCategoryId: number) {
    await this.apiService
      .getSubSubCategoryByCategoryId(subCategoryId, this.themingService.shopId)
      .then((data: any) => {
        this.subSubCategories = data.subSubCategories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  async getIndustry() {
    await this.apiService
      .getIndustries()
      .then((data: any) => {
        this.industryTypes = data.industries;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getSuppliers() {
    await this.apiService
      .getSuppliers(this.themingService.shopId)
      .then((data: any) => {
        this.suppliers = data.suppliers;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getCollections() {
    await this.apiService
      .getCollections(this.themingService.shopId)
      .then((data: any) => {
        this.collections = data.collections;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  calculateDiscountPercentage(){
    let discountPercentage = 0;
    if(this.product.product.productDiscountPrice > 0){
      discountPercentage = 100 -((this.product.product.productDiscountPrice / this.product.product.productSellPrice) * 100);
    }
    //console.log(discountPercentage);
    return discountPercentage;
  }

  calculateFinalPrice(){
    console.log("Corro al iniciar")
    let taxPercentage = parseFloat(this.taxSelected.taxValue)/100;
    if(this.product.product.gainMargin > 0){
      let sellprice = 0;
      sellprice = this.product.product.productBaseCost + (this.product.product.productBaseCost * (this.product.product.gainMargin / 100));
      this.product.product.productSellPrice = sellprice + (sellprice * taxPercentage);
    }else{
      this.product.product.productSellPrice = this.product.product.productBaseCost + (this.product.product.productBaseCost * taxPercentage);
    }
    if(this.items){
      const items = this.items.map((item : any) => {
        item.itemPrice = this.product.product.productSellPrice.toFixed(2);
        return item;
      });
      this.items = items;
    }
  }

  descriptionChanged(event: any) {
    //console.log(event);
    //console.log(encodeURI(event.html));

    // this.product.product.productDescription = event.innerHTML;
  }

  changeName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.product.product.productName = element.value;
  }

  changeCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productCategoryId = parseInt(element.value);
    this.getSubCategoryByCategoryId(this.product.product.productCategoryId);
  }

  changeSubCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productSubCategoryId = parseInt(element.value);
    this.getSubSubCategoryByCategoryId(this.product.product.productSubCategoryId);
    this.resetSubSubcategories();
  }

  changeSubSubCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productSubSubCategoryId = parseInt(element.value);
  }

  resetSubcategories() {
    this.product.product.productSubCategoryId = 0;
    this.product.product.productSubSubCategoryId = 0;
    this.subCategories = [];
    this.subSubCategories = [];
  }

  resetSubSubcategories() {
    this.product.product.productSubSubCategoryId = 0;
    this.subSubCategories = [];
  }

  changeIndustry(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productIndustryTypeId = parseInt(element.value);
  }

  changeSupplier(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productSupplierId = parseInt(element.value);
  }

  changeCollection(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productCollectionId = parseInt(element.value);
  }

  changeBaseCost(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.product.product.productBaseCost = parseFloat(element.value);
    } else {
      this.product.product.productBaseCost = 0;
    }
    this.calculateFinalPrice();
  }

  changeTax(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.product.productTaxId = parseFloat(element.value);
    this.taxSelected = this.taxes.find((taxValue: any) => taxValue.taxId == parseFloat(element.value));
    console.log("TAX VALUE: ",parseFloat(element.value));
    this.calculateFinalPrice();
  }

  changeSellPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.product.product.productSellPrice = parseFloat(element.value);
      if(this.items){
        const items = this.items.map((item : any) => {
          item.itemPrice = parseFloat(element.value).toFixed(2);
          return item;
        });
        this.items = items;
      }
    } else {
      this.product.product.productSellPrice = 0;
      if(this.items){
        const items = this.items.map((item : any) => {
          item.itemPrice = parseFloat(element.value).toFixed(2);
          return item;
        });
        this.items = items;
      }
    }
  }

  changeDiscountPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    this.discountPrice = parseFloat(element.value);
  }

  changeGainMargin(event: Event) {
    const element = event.target as HTMLInputElement;

    if (parseFloat(element.value) > 0) {
      this.product.product.gainMargin = parseFloat(element.value);
      if(this.taxSelected){
        let sellprice = 0;
        sellprice = this.product.product.productBaseCost + (this.product.product.productBaseCost * (this.product.product.gainMargin / 100));
        this.product.product.productSellPrice =
        sellprice + (sellprice * (this.taxSelected.taxValue/100));
        this.product.product.productSellPrice
      }else{
        this.product.product.productSellPrice =
          this.product.product.productBaseCost +
          this.product.product.productBaseCost * (this.product.product.gainMargin / 100);
      }
    }else{
      this.product.product.productSellPrice = this.product.product.productBaseCost
    }
  }

  changeShopPoint(){
    console.log("active shop point: ",this.activeShopPoint);
    const listContainer = this.el.nativeElement.querySelector('#itemCombinationList');
    const quantityInputs = listContainer.querySelectorAll('.quantityInput');
    const quantityMainInputs = listContainer.querySelectorAll('.quantityMain');
    console.log("inputs cantidad: ",quantityInputs);

    quantityMainInputs.forEach((element:any) => {
      let sum = 0;

      const neighboringInputs = element.parentNode.querySelectorAll('input.quantityInput');

      neighboringInputs.forEach((input: any) => {
        if (!input.classList.contains('quantityMain')) {
          sum += parseInt(input.value);
        }
      });

      element.value = this.productType == 2 ? "Ilimitado" :sum.toString();
    })

    // quantityInputs.forEach((element:any) => {


    //   const dataShopPoint = element.getAttribute('data-shopPoint');
    //   if (dataShopPoint === this.activeShopPoint) {
    //     element.classList.remove('visually-hidden');
    //   } else {
    //     element.classList.add('visually-hidden');
    //   }
    // });
  }

  toggleItemRelationship(itemId: number, relation: string) {
    const itemIndex = this.items.findIndex((item: { itemId: number; }) => item.itemId === itemId);
    if (itemIndex > -1) {
      if (!this.items[itemIndex].itemRelationship) {
        this.items[itemIndex].itemRelationship = [];
      }
      const relationIndex = this.items[itemIndex].itemRelationship.indexOf(relation);
      if (relationIndex > -1) {
        this.items[itemIndex].itemRelationship.splice(relationIndex, 1);
      } else {
        this.items[itemIndex].itemRelationship.push(relation);
      }
    }
   // console.log(`Parentescos actualizados para ítem ${itemId}:`, this.items[itemIndex].itemRelationship);
  }

  changeProductType(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.productType = parseInt(element.value);
  }

  changeSesions(event: Event){
    const element = event.target as HTMLInputElement;
    if(element.value == ""){
      this.product.product.productSessions = 0;
    }else{
      this.product.product.productSessions = parseInt(element.value);
    }
  }

  revenueValue() {
    let revenue = parseFloat(this.product.product.productSellPrice) * (this.taxSelected.taxValue/100);
    return revenue.toFixed(2);
  }

  getNewItemImages(itemId: number) {
    const generalNewImages = this.generalImages//.filter((item:any) => item.items.length === 0)
    const singleNewImages = this.generalImages.filter((item:any) => item.items.includes(itemId))

    let images = generalNewImages
    let isGeneral = 1
    if(singleNewImages.length !== 0){
      images = singleNewImages
      isGeneral = 0
    }

    const newItems = this.items.filter((item:any) => item.itemId < 0).map((el:any) => el.itemId)
    if (newItems.includes(itemId)) {
      let imageURLs: any[] = []
      for (const [index, image] of images.entries()) {
        const images = this.getItemImages(image.url.replace('small','size'), isGeneral, (index+1));
        imageURLs = imageURLs.concat(images);
      }
      return imageURLs
    }
    return []
  }

  initializePdfFromProductData(data: any): void {
    if (data.product && data.product.productFile) {
        this.pdfSrc = data.product.productFile;
        this.pdfFileName = data.product.productFile.split('/').pop() || 'Archivo desde la base de datos';
    } else {
        this.pdfSrc = null;
        this.pdfFileName = 'Seleccionar archivo';
    }
    this.pdfFile = null;
}

  onFileSelectedPDF(event: Event): void {
    const input = event.target as HTMLInputElement;

     if (input.files && input.files.length > 0) {
         const file = input.files[0];

        if (file.type !== 'application/pdf') {
            alert('Por favor, selecciona un archivo PDF.');
            return;
        }

        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            $('#errorProductModal').modal('show');
            this.pdfFile = null;
            this.pdfFileName = null;
            this.pdfSrc = null;
            return;
        }

        this.pdfFile = file;
        this.pdfFileName = file.name;
        this.pdfSrc = URL.createObjectURL(file);
     }
  }
  async savePDF(): Promise<void> {
    try {
       if (this.pdfFile) {
          const pdf = this.pdfFile;
          const ext = pdf.name.split('.').pop();

          const filePath = `${this.shopTheme?.[0]?.shopId}/${environment.stage}/products-pdf/pdf${this.timestamp}.${ext}`;
          const data: any = await this.apiService.uploadFile(filePath, pdf, {});

          this.productFile = data.imageURL || '';
       } else {
          console.warn('No se seleccionó ningún archivo PDF.');
       }
   } catch (error) {
      console.error('Error al cargar el archivo PDF:', error);
   }
 }

 openPdfInNewTab(): void {
  if (!this.pdfSrc) {
    alert('No se encontró una fuente válida para el PDF.');
    return;
  }

  const newTab = window.open('', '_blank');

  if (!newTab) {
    alert('El navegador bloqueó la ventana emergente. Por favor, habilítalas en la configuración.');
    return;
  }

  let viewerUrl = '';
  const isHttpPdf = this.pdfSrc.startsWith('http') && this.pdfSrc.endsWith('.pdf');
  if (isHttpPdf) {
    viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(this.pdfSrc)}&embedded=true`;
  } else if (this.pdfFile) {
    viewerUrl = URL.createObjectURL(this.pdfFile);
  } else {
    newTab.close();
    return;
  }

  newTab.location.href = viewerUrl;

  if (viewerUrl.startsWith('blob:')) {
    setTimeout(() => URL.revokeObjectURL(viewerUrl), 1000);
  }
}

  async DeletePDF(pdfURL: string): Promise<void> {
     if (!pdfURL) {
       console.warn('No se encontró un archivo PDF anterior para eliminar.');
       return;
     }

     const s3Key = pdfURL.replace('https://mobydyg-files.s3.amazonaws.com/', '');

     try {
       await this.apiService.deleteFile(s3Key);
     } catch (error) {
       console.error('Error al eliminar el archivo PDF de S3:', error);
     }
   }

  async saveChanges() {
    if(this.validateValues()){
      $('#validationModal').modal({ backdrop: 'static', keyboard: false });
      $('#validationModal').modal('show');
      return;
    }
    $('#productModal').modal({ backdrop: 'static', keyboard: false });
    $('#productModal').modal('show');

    // console.log(this.product);
    // let imageURLs: any[] = [];
    let imageDeletePromises: any[] = [];


    // getItemImages

    //.log(imageURLs);

    let items = [];

    for (let item of this.items) {
      items.push({
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemActive: item.itemActive,
        itemPrice: item.itemPrice,
        itemDiscountPrice: item.itemDiscountPrice,
        itemBeneficiaries: item.itemBeneficiaries,
        itemHolder: item.itemHolder,
        itemQuantity: item.itemQuantity,
        itemShopPoints: item.itemShopPoints,
        itemVariantValues: item.variantValues,
        imageURLs: this.getNewItemImages(item.itemId),
        itemRelationship: item.itemRelationship
      });
      console.log("ITEMS: ",items);
    }

      this.loading = true;

      try {

          if (this.pdfFile) {
            if (this.product.product.productFile) {
              await this.DeletePDF(this.product.product.productFile);
            }
            await this.savePDF();
          } else {
            this.productFile = this.product.product.productFile;
          }

           const response: any = await this.apiService.updateProduct(
              this.product.product.productId,
              this.themingService.shopId,
              encodeURI(this.product.product.productDescription),
              this.product.product.productIsActive == true ? 1 : 0,
              items,
              this.product.product.productName,
              this.product.product.productCategoryId,
              this.product.product.productSubCategoryId,
              this.product.product.productSubSubCategoryId,
              this.product.product.productIndustryTypeId,
              this.product.product.productSupplierId == null ? 0 : this.product.product.productSupplierId,
              this.product.product.productCollectionId == null ? 0 : this.product.product.productCollectionId,
              this.product.product.productBaseCost,
              this.product.product.productTaxId,
              this.product.product.productSellPrice,
              this.discountPrice > 0 ? this.discountPrice: 0,
              this.variants,
              new Date().toLocaleString('en-US', {
                  timeZone: 'America/Costa_Rica',
              }),
              this.deleteItems,
              this.product.product.productType,
              this.product.product.productSessions,
              this.productFile
          );

          if (response.statusCode === 200) {
              $('#productModal').modal('hide');
              $('#successEditProductModal').modal('show');
              console.log('Update successful');
          } else {
              $('#errorEditProductModal').modal('show');
              console.error('Update failed');
          }
      } catch (error) {
          $('#errorEditProductModal').modal('show');
          console.error('Error occurred during update:', error);
      } finally {
          await Promise.all(imageDeletePromises);
          this.loadProduct();
          this.loading = false;
          $('#productModal').modal('hide');
      }
  }

  closeModal(){
    $('#successEditProductModal').modal('hide');
    $('#validationModal').modal('hide');
    $('#selectImagesModal').modal('hide');
    $('#productsModal').modal('hide');
    $('#errorEditProductModal').modal('hide');
    $('#errorProductModal').modal('hide');
  }

  findIndexToUpdate(newItem: any) {
    return newItem.id === this;
  }

  // changeItemcode(event: Event){
  //   const element = event.target as HTMLInputElement;
  //   let parentId = element.parentElement?.parentElement?.id;
  //   let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == parentId)

  //   //let itemEdited = this.items[arrayIndex];
  //   this.items[arrayIndex].itemCode = element.value;

  //   return this.items[arrayIndex].itemCode;
  // }

  changeItemcode(event: Event){
    const element = event.target as HTMLInputElement;
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == itemId)

    this.items[arrayIndex].itemCode = element.value;

    console.log(this.items);
    return this.items[arrayIndex].itemCode;
  }

  // changeitemQuantity(event: Event){
  //   const element = event.target as HTMLInputElement;
  //   let parentId = element.parentElement?.parentElement?.id;
  //   let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == parentId)

  //   const shopPointId = this.items[0].itemShopPoints.shopPointId;
  //   this.items[arrayIndex].itemQuantity = parseInt(element.value);
  //   this.items[arrayIndex].itemShopPoints = {shopPointId, itemQuantity: parseInt(element.value)};
  // }

  changeitemShopPointQuantity(event: Event){
    const element = event.target as HTMLInputElement;
    let shopPointId = element.getAttribute('data-shoppoint');
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((i: { itemId: any}) => i.itemId == itemId)
    const item = this.items[arrayIndex];
    if (item) {
        let arrayIndex = item.itemShopPoints.findIndex((sp: { shopPointId: any}) => sp.shopPointId == shopPointId)
        const shopPoint = item.itemShopPoints[arrayIndex];
        const unlimitedSelect = this.el.nativeElement.querySelector(`.no-variant-unlimited[data-shoppoint="${shopPointId}"]`);
        if (shopPoint) {
            shopPoint.itemQuantity = parseInt(element.value);
            shopPoint.itemUnlimited = parseInt(unlimitedSelect.value) || 0;
        }
    }
    console.log(this.items);
  }

  changeitemShopPointUnlimited(event: Event){
    const element = event.target as HTMLInputElement;
    let shopPointId = element.getAttribute('data-shoppoint');
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((i: { itemId: any}) => i.itemId == itemId)
    const item = this.items[arrayIndex];
    if (item) {
        let arrayIndex = item.itemShopPoints.findIndex((sp: { shopPointId: any}) => sp.shopPointId == shopPointId)
        const shopPoint = item.itemShopPoints[arrayIndex];
        const inputQuantity = this.el.nativeElement.querySelector(`.no-variant-quantity[data-shoppoint="${shopPointId}"]`);
        inputQuantity.value = 0;
        inputQuantity.disabled = !inputQuantity.disabled;
        if (shopPoint) {
            shopPoint.itemQuantity = parseInt(inputQuantity.value);
            shopPoint.itemUnlimited = parseInt(element.value);
        }
    }
  }

  changeitemPrice(event: Event){
    const element = event.target as HTMLInputElement;
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == itemId)
    console.log(this.items);
    this.items[arrayIndex].itemPrice = element.value;
    //return element.value;
  }

  changeitemDiscountPrice(event: Event){
    const element = event.target as HTMLInputElement;
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == itemId)
    console.log(this.items);
    this.items[arrayIndex].itemDiscountPrice = element.value;
  }

  changeitemBeneficiaries(event: Event){
    const element = event.target as HTMLInputElement;
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == itemId)
    console.log(this.items);
    this.items[arrayIndex].itemBeneficiaries = element.value;
  }

  changeItemHolder(event: Event){
    const element = event.target as HTMLInputElement;
    let itemId = element.getAttribute('data-itemId');
    let arrayIndex = this.items.findIndex((item: { itemId: any}) => item.itemId == itemId)
    console.log(this.items);
    this.items[arrayIndex].itemHolder = element.checked ? 1 : 0;
  }

  changeVariantTypeName(event: Event){
    const element = event.target as HTMLInputElement;
    let arrayIndex = element?.id;
    let index = parseInt(arrayIndex.replace("type", ""));

    this.variants[index].variantTypeName = element.value;
    return element.value;
  }

  changeVariantValue(event: Event){
    const element = event.target as HTMLInputElement;
    let arrayIndex = element?.name;
    let variantIndex = parseInt(arrayIndex.replace("type", ""));
    let elementIndex = element?.id;
    let variantValueIndex = parseInt(elementIndex.replace("id", ""));

    if (!element?.value) return;
    const nameExists = this.variants[variantIndex].variantValues.some((item: any) => item.variantValueName === element?.value)
    if(nameExists){
      element.classList.add('duplicated');
      this.duplicatedVariants = true;
      this.enabledSave = false;
      console.log(this.duplicatedVariants)
      // console.log("Nombre de variante repetido");
      // const editetValues = this.variants[variantIndex].variantValues.map((item: any) => {
        //   if(item.variantValueName === element?.value){
          //     item.variantValueName = ''
          //     this.updateItemVariants(element?.value, '');
          //   }
          //   return item
          // })
          // this.variants[variantIndex].variantValues = editetValues
          // console.log(editetValues)
      }else{
        element.classList.remove('duplicated');
        this.duplicatedVariants = false;
        this.enabledSave = true;
        let name = this.variants[variantIndex].variantValues[variantValueIndex].variantValueName;
        this.updateItemVariants(name, element.value);
        this.variants[variantIndex].variantValues[variantValueIndex].variantValueName = element.value;
      }
    const emptyValue = this.variants[variantIndex].variantValues.some((item: any) => item.variantValueName == "");
    if(!emptyValue){
      this.emptyVariantValue = false;
    }
    return element.value;
  }

  updateItemVariants(name: string, newName: string){
    for (let item of this.items) {
      for (let variantValue of item.variantValues) {
        if(variantValue.variantValueName == name){
          variantValue.variantValueName = newName;
        }
      }
    }
  }

  /**
   * The function checks if any of the items in an array have empty values and returns true if at least
   * one item has empty values.
   * @returns a boolean value. It returns `true` if any of the items in the `items` array have empty
   * values, and `false` otherwise.
   */
  validateValues(){
    for (const item of this.items) {
      if(this.hasEmptyValues(item)){
        return true;
      }
    }
    return false;
  }

  /**
   * This function checks if an object has empty values in its variantValues property.
   * @param {any} item - The `item` parameter is an object that contains information about a product
   * variant, including its variant values.
   * @returns The function `hasEmptyValues` returns a boolean value. It returns `false` if the `item`
   * parameter does not have a `variantValues` property, and it returns `true` if any of the objects in
   * the `variantValues` array have an empty `variantValueName` property.
   */
  hasEmptyValues(item: any) {
    if(!item.variantValues){
      return false;
    }
    return item.variantValues.some((obj: any) => obj.variantValueName === '')
  }

  /**
   * This function adds a new variant object to an array of variants.
   */
  addVariant(){
    this.variants.push({
      variantTypeName: '',
      variantValues: []
    })
  }

  /**
   * This function adds a new variant value to a product's variant and updates the corresponding items
   * with the new variant value.
   * @param {number} variantIndex - The index of the variant in the variants array to which a new
   * variant value will be added.
   */
  addVariantValue(variantIndex: number){
    const position = this.variants[variantIndex].variantValues.reduce((value:number, currentValue:any) => {
      return currentValue.variantValuePosition > value ? currentValue.variantValuePosition : value;
    }, 0)+1;

    const variantValueId = position*-1

    const updatedVariantValues = this.variants[variantIndex].variantValues.concat([{
      variantValueId,
      variantValueName: '',
      variantValuePosition: position
    }]);
    const updatedVariant = this.variants[variantIndex];
    updatedVariant.variantValues = updatedVariantValues;
    this.variants[variantIndex] = updatedVariant;

    const joinedVariants = this.joinVariants();

    for (const [index, value] of joinedVariants.entries()) {
      if(!this.items[index]){
        let itemShopPoints: any[] = [];
        let totalStock = 0;
        this.shopPoints.forEach((shopPoint: any) => {
          itemShopPoints.push(
            {
              shopPointId: shopPoint.shopPointId,
              itemQuantity: 0,
              itemUnlimited: 0
            }
          )
        });
        this.items.push({
          itemId: this.items.length * -1,
          itemActive: 1,
          itemCode: `${this.product.product.productCode}${this.items.length}`,
          itemPrice: this.items[this.items.length -1].itemPrice,
          itemQuantity: 10,
          itemBeneficiaries: 0,
          itemDiscountPrice: 0,
          itemShopPoints: itemShopPoints,
          itemTotalStock: totalStock,
          itemHolder: 0,
          itemRelationships: [],
      })
      }
      this.items[index].variantValues = value;
    }
    console.log("Items: ",this.items);
    this.emptyVariantValue = true;
  }

  /**
   * The function joins variant values from an array of variants into a new array of all possible
   * combinations.
   * @returns The `joinVariants()` function is returning an array of all possible combinations of
   * variant values from the `variants` array. Each combination is represented as an array of objects,
   * where each object contains the `variantValueName` property.
   */
  joinVariants(){
    const arrays:any = []

    for (const variant of this.variants) {
      let arr: any = []
      for (const value of variant.variantValues) {
        arr.push({
          variantValueName: value.variantValueName
        })
      }
      arrays.push(arr)
    }

    const result = arrays.reduce((acc: any, arr: any) => {
      return acc.flatMap((item: any) => arr.map((elem: any) => [...item, elem]));
    }, [[]]);

    return result;
  }

  /**
   * The function deletes items from an array based on a specific variant value name and stores the
   * deleted item IDs in a separate array.
   * @param {any} variantValueName - The parameter variantValueName is a variable that represents the
   * name of a variant value that needs to be deleted from the items array.
   */
  deleteItem(variantValueName: any){
    const items = this.items.filter((item: any) => !item.variantValues.some((obj: any) => obj.variantValueName === variantValueName));
    const itemsDeleted = this.items.filter((item: any) => item.variantValues
                                    .some((item: any) => item.variantValueName === variantValueName))
                                    .map((item: any) => item.itemId);
    this.deleteItems = this.deleteItems.concat(itemsDeleted);
    this.items = items;
  }

  /**
   * This function deletes a variant and all its associated variant values from an array of variants.
   * @param {number} variantIndex - The index of the variant to be deleted from the `variants` array.
   */
  deleteVariant(variantIndex: number) {
    const deletedVariant = this.variants[variantIndex];
    for(const [i,v] of deletedVariant.variantValues.entries()){
      this.deleteVariantValue(variantIndex, v.variantValueId)
    }
    this.variants = this.variants.filter(item => item !== deletedVariant)
    const emptyValue = this.variants[variantIndex].variantValues.some((item: any) => item.variantValueName == "");
    if(!emptyValue || this.variants.length == 0){
      this.emptyVariantValue = false;
    }
  }

  /**
   * This function deletes a variant item from a list of items based on its variant value name.
   * @param {any} variantValueName - The parameter `variantValueName` is a variable of type `any` that
   * represents the name of the variant value that needs to be deleted from the items list.
   */
  deleteVariantItem(variantValueName: any){
    if(this.items.length == 1){
      this.items[0].itemCode = this.product.product.productCode+0;
    }
    const items = this.items.map((item: any) => {
      const variantValues = item.variantValues.filter((item: any) => item.variantValueName !== variantValueName);
      item.variantValues = variantValues;
      return item
    });
    this.items = items;
  }

  /**
   * This function deletes a variant value from a variant and updates the variant accordingly.
   * @param {number} variantIndex - The index of the variant in the variants array that contains the
   * variant value to be deleted.
   * @param {number} variantValueId - The ID of the variant value that needs to be deleted.
   */
  deleteVariantValue(variantIndex: number, variantValueId: number){
    const variantValueName = this.variants[variantIndex].variantValues.find((item: any) => item.variantValueId === variantValueId).variantValueName;
    const updatedVariantValues = this.variants[variantIndex].variantValues.filter((item: any) => item.variantValueId !== variantValueId);
    const updatedVariant = this.variants[variantIndex];
    updatedVariant.variantValues = updatedVariantValues;
    if(updatedVariant.variantValues.length === 0){
      this.deleteVariant(variantIndex);
    }else{
      this.variants[variantIndex] = updatedVariant;
      this.deleteItem(variantValueName);
    }
    this.deleteVariantItem(variantValueName)
    const emptyValue = this.variants[variantIndex].variantValues.some((item: any) => item.variantValueName == "");
    if(!emptyValue){
      this.emptyVariantValue = false;
    }
  }


  /**
   * The function concatenates the names of variant values of an item and returns the resulting string,
   * or returns the item ID if there are no variant values.
   * @param {any} item - The parameter "item" is of type "any", which means it can be any data type
   * (string, number, object, etc.). It is used as input to the function "objectValueJoin".
   * @returns The function `objectValueJoin` takes an argument `item` and returns a string. If the
   * `item` does not have a property `variantValues`, then the function returns the `itemId` property
   * of the `item`. Otherwise, the function reduces the `variantValues` array of the `item` to a single
   * string by concatenating the `variantValueName` property of each element,
   */
  objectValueJoin(item: any): string{
    if(!item.variantValues || item.variantValues.length === 0){
      return item.itemId
    }
    const data = item.variantValues.map((item: any) => item.variantValueName).join('/')
    return data
  }

}
