<div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
</div>
<div *ngIf="!loading"  class="favicon-container">
    <div class="favicon-section">
        <div class="label">
        <div class="label-group">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 2048 2048"><path fill="#ea803f" d="M2048 128v1664H0V128zM128 256v256h1792V256zm1792 1408V640H128v1024z"/></svg>
            <strong>Favicon</strong>
        </div>
    
        <div class="label-edit">
            <a class="edit-link" *ngIf="!isEditingFavicon" (click)="switchEditingFavicon()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit">
                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
            </svg>
            </a>
            <button class="primary-btn" *ngIf="isEditingFavicon" (click)="onSaveFavicon()" [class.disabled]="loading || !faviconFile">
                {{'SAVE' | translate}}
            </button>
            <button class="remove-btn" *ngIf="isEditingFavicon" (click)="switchEditingFavicon()">
                {{'CANCEL' | translate}}
            </button>
        </div>
        </div>
        <div *ngIf="!isEditingFavicon" class="favicon-tab-preview">
            <img [src]="shopFavicon" alt="Favicon" />
            <span class="shop-name">{{ shopName }}</span>
        </div>
        <div *ngIf="isEditingFavicon" class="favicon-edit-mode">
            <div *ngIf="previewUrl">
                <img [src]="previewUrl" alt="Favicon Preview" width="125" height="125" />
            </div>
        <input type="file" id="faviconInput" (change)="onFileSelected($event)"/> 
        </div>
    </div>
</div>

<div class="modal favicon-confirm-modal" id="successFaviconModal" tabindex="-1">
    <div class="modal-dialog favicon-modal-dialog" role="document">
      <div class="modal-content favicon-modal-content">
        <div class="modal-body favicon-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'FAVICON_SAVED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal favicon-confirm-modal fade" id="errorFaviconsModal" tabindex="-1">
    <div class="modal-dialog favicon-modal-dialog" role="document">
      <div class="modal-content favicon-modal-content">
        <div class="modal-body favicon-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="modal favicon-confirm-modal fade" id="errorFaviconTypeModal" tabindex="-1">
    <div class="modal-dialog favicon-modal-dialog" role="document">
      <div class="modal-content favicon-modal-content">
        <div class="modal-body favicon-modal-body">
          <h3 class="text-center">{{ 'ERROR' | translate }}</h3>
          <p class="text-center">{{ 'FAVICON_FILE_TYPE_INVALID' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{ 'CLOSE' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
  