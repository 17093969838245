import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, transferArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {
  @Output() editComponentEvent = new EventEmitter<number>();
  @Input() pageId: string | null = null; 
  @Output() close = new EventEmitter<void>();
  modalDelete: boolean = false;
  inactive: any[] = [];
  active: any[] = [];
  showSuccessAlert: boolean = false;
  showErrorAlert: boolean = false;
  loading: boolean = false;
  component: any;
  componentToDeleteId: number | null = null;
  isCreatingComponent = false;
  isListingComponent = true;
  isEditingComponent = false;
  editComponentId!: number;
  pages: any[] = [];
  pageName: string | null = null;
  deletedInfoIds: string[] = [];
  confirmClone: boolean = false;
  selectedItem: any = null;
  selectedPageId: number | null = null;
  
  constructor(
    private apiService: ApiService,
    private themingService: ThemingService
  ) { }

  ngOnInit() {
    this.getComponents();
    if (this.pageId) {
    }
    this.getPages()
  }
  
  async getComponents() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getComponents(this.themingService.shopId);
      this.component = response.data.map((component: any) => ({
        ...component,
        componentInfo: typeof component.componentInfo === 'string'
        ? JSON.parse(component.componentInfo)
        : component.componentInfo
      }));
      const filteredComponents = this.pageId
      ? this.component.filter((component: any) => component.pageId === this.pageId)
      : this.component;
      this.inactive = filteredComponents.filter((component: any) => component.status === 0);
      this.active = filteredComponents.filter((component: any) => component.status === 1);
      this.active.sort((a, b) => a.position - b.position);
      
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  async getPages() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getPages(this.themingService.shopId);
      this.pages = response.data
      if (this.pageId) {
        const numericPageId = Number(this.pageId); // Convertir a número antes de comparar
        const matchedPage = this.pages.find(page => page.pageId === numericPageId);
        this.pageName = matchedPage ? matchedPage.pageName : 'Página no encontrada';
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  editComponent(componentId: number): void {
    this.isEditingComponent = true;
    this.isCreatingComponent = false;
    this.isListingComponent = false;
    this.editComponentId = componentId;
  }
  
  toggleComponentVisibility(creating: boolean) {
    this.isCreatingComponent = creating;
    this.isListingComponent = !creating;
    if (!creating) {
      this.getComponents();
    }
  }
  
  cancelCreation() {
    this.isCreatingComponent = false;
    this.isListingComponent = true;
    this.getComponents();
  }
  
  cancelEdition(): void {
    this.isEditingComponent = false;
    this.isListingComponent = true;
    this.getComponents();
  }
  
  drop(event: CdkDragDrop<any[]>, listType: 'inactive' | 'active') {
    const componentId = +(event.item.element.nativeElement.getAttribute('data-componentId') ?? '');
    
    if (event.previousContainer !== event.container) {
      const item = event.previousContainer.data[event.previousIndex];
      
      if (listType === 'active') {
        item.status = 1;
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.updatePositions();
      } else if (listType === 'inactive') {
        item.status = 0;
        item.position = 0;
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.updatePositions();
      }
      
      this.updateStatusAndPositions();
    } else {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      
      if (listType === 'active') {
        this.updatePositions();
        this.updateStatusAndPositions();
      }
    }
  }
  
  updatePositions() {
    this.active.forEach((component, index) => {
      component.position = index + 1;
    });
  }
  
  updateStatusAndPositions() {
    const allComponents = [...this.inactive, ...this.active];
    const componentDataString = allComponents.map(component =>
      `${component.componentId},${component.position},${component.status}`
    ).join(';');
    
    this.apiService.updateComponentStatusAndPosition(
      componentDataString,
      this.themingService.shopId
    )
    .then((response: any) => {
      if (response.statusCode === 200) {
        this.showSuccessAlert = true;
      } else {
        this.showErrorAlert = true;
      }
      setTimeout(() => {
        this.showSuccessAlert = false;
        this.showErrorAlert = false;
      }, 1000);
    })
    .catch((error) => {
      console.error(error);
      this.showErrorAlert = true;
      setTimeout(() => {
          this.showErrorAlert = false;
        }, 1000);
      });
    }
    
  confirmDelete(componentId: number) {
    this.componentToDeleteId = componentId;
    this.modalDelete = true;
  }
    
  async deleteComponent(response: boolean) {
    if (response && this.componentToDeleteId !== null) {
      this.loading = true;
      try {
        const component: any = this.component.find((comp: any) => comp.componentId === this.componentToDeleteId);
        if (!component) {
          return;
        }
        const urlsToDelete: string[] = [];
        if (component.componentInfo && Array.isArray(component.componentInfo)) {
          component.componentInfo.forEach((info: any) => {
            if (info.infoImageUrl) urlsToDelete.push(info.infoImageUrl);
            if (info.infoVideoUrl) urlsToDelete.push(info.infoVideoUrl);
          });
        }
        await this.deletePreviousFiles(urlsToDelete);
        await this.apiService.deleteComponent(this.themingService.shopId, this.componentToDeleteId);
        this.getComponents();
        $('#deleteComponentModal').modal('show');
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.modalDelete = false;
      }
    } else {
      this.modalDelete = false;
    }
  }
    
  async deletePreviousFiles(urlsToDelete: string[]): Promise<void> {
    if (urlsToDelete.length === 0) {
      return;
    }
    const deleteS3Promises = [];
    for (const url of urlsToDelete) {
      if (url) {
        const cleanedUrl = url.replace('https://mobydyg-files.s3.amazonaws.com/', '');
        deleteS3Promises.push(this.apiService.deleteFile(cleanedUrl));
      }
    }
    try {
      await Promise.all(deleteS3Promises);
    } catch (error) {
      console.error(error);
    }
  }
    
  toggleCloneDropdown(item: any) {
    if (!item.cloneEnabled) {
      item.selectedPageId = null;
    }
  }

  async cloneComponent(originalComponent: any, newPageId: number, cloneMedia: boolean) {
    if (!newPageId) return;

    try {
      this.loading = true;
      const clonedComponent = { ...originalComponent };

      delete clonedComponent.componentId; 
      clonedComponent.pageId = newPageId;
      clonedComponent.status = 0;

      if (!cloneMedia) {
        clonedComponent.componentInfo.forEach((info: any) => {
          info.infoImageUrl = '';
          info.infoVideoUrl = '';
        });
      }

      await this.apiService.createComponent(
        0,
        this.themingService.shopId,
        this.sanitizeString(clonedComponent.componentName),
        this.sanitizeString(clonedComponent.title),
        this.sanitizeString(clonedComponent.subtitle),
        clonedComponent.position,
        clonedComponent.status,
        this.sanitizeString(clonedComponent.description),
        clonedComponent.componentTypeId,
        clonedComponent.itemQuantity,
        clonedComponent.itemListId,
        clonedComponent.itemSort,
        clonedComponent.componentInfo.map((info: { infoImageUrl: any; }) => this.sanitizeString(info.infoImageUrl || '')).join('|'),
        clonedComponent.componentInfo.map((info: { infoDescription: any; }) => this.sanitizeString(info.infoDescription || '')).join('|'),
        clonedComponent.componentInfo.map((info: { redirectUrl: any; }) => this.sanitizeString(info.redirectUrl || '')).join('|'),
        clonedComponent.componentInfo.map((info: { infoTitle: any; }) => this.sanitizeString(info.infoTitle || '')).join('|'),
        clonedComponent.componentInfo.map((info: { urlName: any; }) => this.sanitizeString(info.urlName || '')).join('|'),
        clonedComponent.collectionId,
        clonedComponent.categoryId,
        clonedComponent.subCategoryId,
        clonedComponent.subSubCategoryId,
        clonedComponent.productComponentType || 0,
        clonedComponent.componentInfo.map((info: { infoVideoUrl: any; }) => info.infoVideoUrl || '').join('|'),
        clonedComponent.componentInfo.map((info: { videoUrlType: any; }) => info.videoUrlType || 0).join('|'),
        clonedComponent.pageId
      );

      console.log("Componente clonado con éxito en la página", newPageId);
      this.loading = false;
      $('#clonedComponentModal').modal('show');
      this.getComponents();
    } catch (error) {
      console.error("Error al clonar el componente:", error);
      this.loading = false;
      $('#errorClonedComponentModal').modal('show');
    }
  }

  async moveComponent(component: any, newPageId: number) {
    if (!newPageId) return;

    try {
      this.loading = true;
      component.pageId = newPageId;
      component.status = 0;

      await this.apiService.updateComponent(
        component.componentId,
        this.themingService.shopId,
        this.sanitizeString(component.title),
        this.sanitizeString(component.subtitle),
        this.sanitizeString(component.description),
        component.itemQuantity,
        component.itemListId,
        component.itemSort,
        component.componentInfo.map((info: { infoImageUrl: any; }) => this.sanitizeString(info.infoImageUrl || '')).join('|'),
        component.componentInfo.map((info: { infoDescription: any; }) => this.sanitizeString(info.infoDescription || '')).join('|'),
        component.componentInfo.map((info: { redirectUrl: any; }) => this.sanitizeString(info.redirectUrl || '')).join('|'),
        component.componentInfo.map((info: { infoId: { toString: () => any; }; }) => info.infoId?.toString() || '').join('|'),
        this.deletedInfoIds.join('|'),
        component.componentInfo.map((info: { infoTitle: any; }) => this.sanitizeString(info.infoTitle || '')).join('|'),
        component.componentInfo.map((info: { urlName: any; }) => this.sanitizeString(info.urlName || '')).join('|'),
        component.collectionId,
        component.categoryId,
        component.subCategoryId,
        component.subSubCategoryId,
        component.productComponentType || 0,
        component.componentTypeId,
        this.sanitizeString(component.componentName),
        component.infoVideoUrl,
        component.videoUrlType,
        component.pageId,
        component.status
      );

      console.log("Componente movido con éxito a la página", newPageId);
      $('#movedComponentModal').modal('show');
      this.getComponents();
      this.loading = false;
    } catch (error) {
      console.error("Error al mover el componente:", error);
      this.loading = false;
      $('#errorMovedComponentModal').modal('show');
    }
  }

    
  sanitizeString(str: string): string {
    if (!str) return '';
    return str
    .replace(/\\/g, '\\\\')   
    .replace(/"/g, '\\"')     
    .replace(/\n/g, '\\n')    
    .replace(/\r/g, '\\r');   
  }
    
  toggleAction(item: any, action: string) {
    if (item.actionType === action) {
      item.actionType = null;
    } else {
      item.actionType = action;
    }
  }
    
  
    
  onPageSelectionChange(event: Event, item: any) {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement && selectElement.value) {
      const selectedPageId = parseInt(selectElement.value, 10);
  
      if (item.actionType === 'clone') {
        this.selectedItem = item;
        this.selectedPageId = selectedPageId;
        this.confirmClone = true;
      } else if (item.actionType === 'move') {
        this.moveComponent(item, selectedPageId);
      }
    }
  }
    
  handleCloneResponse(userConfirmed: boolean, item: any, newPageId: number) {
    this.confirmClone = false;
    if (userConfirmed) {
      this.cloneComponent(item, newPageId, true);
    } else {
      this.cloneComponent(item, newPageId, false);
    }
  }
    
  closeModal() {
    $('#deleteComponentModal').modal('hide');
    $('#clonedComponentModal').modal('hide');
    $('#errorClonedComponentModal').modal('hide');
    $('#movedComponentModal').modal('hide');
    $('#errorMovedComponentModal').modal('hide');
  }

  onClose() {
    this.close.emit();
  }
     
  }