import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as feather from 'feather-icons';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { environment } from 'src/environments/environment';
declare let $: any; 
@Component({
  selector: 'app-theming-settings',
  templateUrl: './theming-settings.component.html',
  styleUrls: ['./theming-settings.component.scss'],
})
export class ThemingSettingsComponent implements OnInit {

  private originalShopMainColor!: string;
  private originalShopSecondaryColor!: string;
  private originalShopHeaderColor!: string;
  private originalShopMainColorDark!: string;
  private fontFamily2!: string;
  public shopTheme: ShopTheming[] = [];
  constructor(
    public apiService: ApiService,
    public themingService: ThemingService,
    private router: Router
  ) {
    this.shopTheme = this.themingService.shopTheming;
    this.shopMainColor = this.shopTheme[0].mainColor.toLocaleUpperCase();
    this.shopSecondaryColor = this.shopTheme[0].mainColorLight.toLocaleUpperCase();
    this.shopHeaderColor = this.shopTheme[0].grayTitleActive.toLocaleUpperCase();
    this.shopMainColorDark = this.shopTheme[0].mainColorDark.toLocaleUpperCase();
    this.selectedFont = this.shopTheme[0].fontFamily2;
  }

  loading = false;
  isEditingColors = false;
  isEditingImages = false;
  isEditingBanner = false;
  
  mainLogo = File;
  secondaryLogo = File;
  mobileLogo = File;
  
  mainLogoFile: any;
  secondaryLogoFile: any;
  mobileLogoFile: any;
  
  mainLogoUrl = "";
  secondaryLogoUrl = "";
  mobileLogoUrl = "";
  
  bannersUrl: Array<any>= [];
  bannersMobileUrl: Array<any>= [];
  uploadBanners: Array<any>= [];
  areNewBanners = false;
  banners = null;
  
  shopMainColor: string;
  shopSecondaryColor: string;
  shopHeaderColor: string;
  shopMainColorDark: string;

  fonts: any[] = [];
  selectedFont: string = '';
  originalFont: string = '';
  isEditingFonts: boolean = false;
  
  ngOnInit(): void {
    this.getBanners()
    this.mainLogoUrl = this.shopTheme[0].shopLogoLarge;
    this.secondaryLogoUrl = this.shopTheme[0].shopLogoWhite;
    this.mobileLogoUrl = this.shopTheme[0].shopLogoSmall;
    this.getFonts()
    this.fontFamily2
    console.log('fontfamily',this.fontFamily2)
  }

  async getFonts() {
    try {
      const response: any = await this.apiService.getFonts();
      this.fonts = response.data;
      this.loading = false;
      if (!this.selectedFont && this.fonts.length > 0) {
        this.selectedFont = this.shopTheme[0].fontFamily2 || this.fonts[0].font_family;
      }
    } catch (error) {
      console.log(error);
    }
  }

  switchEditingFonts() {
    if (!this.isEditingFonts) {
      this.originalFont = this.selectedFont; // Guardar valor original
    }
    this.isEditingFonts = !this.isEditingFonts;
  }

  cancelEditingFonts() {
    this.selectedFont = this.originalFont; // Restaurar valor original
    this.isEditingFonts = false;
  }

  onFontChange(font: string) {
    this.selectedFont = font;
  }

  getSelectedFontName(): string {
    const selected = this.fonts.find(font => font.fontFamily === this.selectedFont);
    return selected ? selected.fontName : '';
  }

  async saveFont() {
    this.loading = true;
    try {
      await this.apiService.updateThemingFont(
        this.shopTheme[0].shopId,
        this.selectedFont
      );
      // Actualizar el tema local con fontFamily2
      this.shopTheme[0].fontFamily2 = this.selectedFont;
      this.themingService.shopTheming = this.shopTheme;

      $('#themingSuccess').modal('show');
      this.isEditingFonts = false;
    } catch (error) {
      console.error(error);
      $('#themingError').modal('show');
    } finally {
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    feather.replace();
  }

  // Store original colors before editing
  switchEditingColors() {
     if (!this.isEditingColors) {
      this.originalShopMainColor = this.shopMainColor; //Main Color
      this.originalShopSecondaryColor = this.shopSecondaryColor; // Menu Nav-Bar Color
      this.originalShopHeaderColor = this.shopHeaderColor; // Header Color
      this.originalShopMainColorDark = this.shopMainColorDark; // Highlight Color
    }
    this.isEditingColors = !this.isEditingColors;
  }

  // Restore original colors if editing is canceled
  cancelEditingColors() {
    this.shopMainColor = this.originalShopMainColor;
    this.shopSecondaryColor = this.originalShopSecondaryColor;
    this.shopHeaderColor = this.originalShopHeaderColor;
    this.shopMainColorDark = this.originalShopMainColorDark;
    this.isEditingColors = false;
  }

  updateThemingColors(){
    this.loading = true;
    this.apiService
    .updateThemingColors(
      this.shopTheme[0].shopId,
      this.shopMainColor,
      this.shopSecondaryColor,
      this.shopHeaderColor,
      this.shopMainColorDark,
    )
    .then((data: any) => {
      console.log(data);
      this.themingService.getThemingService();
      $('#themingSuccess').modal('show');
      this.isEditingColors = !this.isEditingColors;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;
      console.error(error);
    });
    
  }

  onMainLogoSelected(event: any) {
    this.mainLogo = event.target.files[0];
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.mainLogoFile = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  onSecondaryLogoSelected(event: any) {
    this.secondaryLogo = event.target.files[0];
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.secondaryLogoFile = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  onMobileLogoSelected(event: any) {
    this.mobileLogo = event.target.files[0];
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.mobileLogoFile = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  async saveThemingLogo(){
    if(this.mainLogo instanceof File || this.secondaryLogo instanceof File || this.mobileLogo instanceof File) {
      $('#loadingModal').modal('show');
      console.log("SON FILES")
      if(this.mainLogo instanceof File){
        let ext = this.mainLogo.name.split('.')[1];
        await this.apiService.uploadFile(this.shopTheme[0].shopId+'/'+environment.stage+'/images/logos/mainlogo.'+ext,this.mainLogo,{})
        .then((data: any) => {
          this.mainLogoUrl = data.imageURL;
        })
      }
      if(this.secondaryLogo instanceof File){
        let ext = this.secondaryLogo.name.split('.')[1];
        await this.apiService.uploadFile(this.shopTheme[0].shopId+'/'+environment.stage+'/images/logos/secondarylogo.'+ext,this.secondaryLogo,{})
        .then((data: any) => {
          this.secondaryLogoUrl = data.imageURL;
        })
      }
      if(this.mobileLogo instanceof File){
        let ext = this.mobileLogo.name.split('.')[1];
        await this.apiService.uploadFile(this.shopTheme[0].shopId+'/'+environment.stage+'/images/logos/mobilelogo.'+ext,this.mobileLogo,{})
        .then((data: any) => {
          this.mobileLogoUrl = data.imageURL;
        })
      }
      await this.apiService
      .updateThemingLogo(
        this.shopTheme[0].shopId,
        this.mainLogoUrl,
        this.secondaryLogoUrl,
        this.mobileLogoUrl
      )
      .then((resp: any ) =>{
        console.log(resp);
        $('#loadingModal').modal('hide');
        $('#themingSuccess').modal('show');

        this.themingService.getThemingService();
        this.isEditingImages = !this.isEditingImages;
      })
    }else{
      $('#themingEmpty').modal('show');
    }
  }

  getBanners(){
    this.areNewBanners = false;
    this.bannersUrl = []
    this.bannersMobileUrl = []
    this.apiService
    .getBanners(this.themingService.shopId)
    .then((data: any) => {
      this.banners = data.sort((a:any, b:any) => a.bannerMobile - b.bannerMobile);;

      this.bannersUrl = data.filter((banner:any) => banner.bannerMobile === 0).map((banner:any) => 
      {
        return {
          id: banner.bannerId,
          bannerURL: banner.bannerURL
        }
      });
      this.bannersMobileUrl = data.filter((banner:any) => banner.bannerMobile === 1).map((banner:any) => 
      {
        return {
          id: banner.bannerId,
          bannerURL: banner.bannerURL
        }
      });
      
      while (this.bannersUrl.length < 3) {
        this.bannersUrl.push({});
      }
      while (this.bannersMobileUrl.length < 3) {
        this.bannersMobileUrl.push({});
      }
    })
  }

  onBannerSelected(event: Event, index: number, array: any) {
    let bannerElement = event.target as HTMLInputElement;
    if(bannerElement.files && bannerElement.files.length > 0){
      const bannerFile = bannerElement.files[0];
      if (bannerFile) {
        array[index].file = bannerFile;
        const reader = new FileReader();
        reader.onload = e => array[index].file.preview = reader.result;
        reader.readAsDataURL(bannerFile);
      }
    }
  }

  async uploadBannerImages(){
    this.uploadBanners = []
    const uploadPromises: any = [];
    this.bannersUrl.forEach((banner, index) => {
      if(banner.bannerURL && !banner.file){
        this.uploadBanners.push({bannerURL: banner.bannerURL, bannerName: "slider"+index, bannerMobile: 0, bannerId: banner.id});
      }
      if(banner.file){
        if(banner.file instanceof File){
          let ext = banner.file.name.split('.')[1];
          let name = "slide-"+index+"-web.";
          uploadPromises.push(this.apiService.uploadFile(this.shopTheme[0].shopId+'/'+environment.stage+'/images/banners/'+name+ext,banner.file,{})
          .then((data: any) => {
            this.uploadBanners.push({bannerURL: data.imageURL, bannerName: "slider"+index, bannerMobile: 0});
          }));
        }
      }
    });
    this.bannersMobileUrl.forEach((banner, index) => {
      if(banner.bannerURL && !banner.file){
        this.uploadBanners.push({bannerURL: banner.bannerURL, bannerName: "slider"+index, bannerMobile: 1, bannerId: banner.id});
      }
      if(banner.file){
        if(banner.file instanceof File){
          let ext = banner.file.name.split('.')[1];
          let name = "slide-"+index+"-mobile.";
          uploadPromises.push(this.apiService.uploadFile(this.shopTheme[0].shopId+'/'+environment.stage+'/images/banners/'+name+ext,banner.file,{})
          .then((data: any) => {
            this.uploadBanners.push({bannerURL: data.imageURL, bannerName: "slider"+index, bannerMobile: 1});
          }))
        }
      }
    });
    await Promise.all(uploadPromises);
  }

  async saveThemingBanners(){
    $('#loadingModal').modal('show');
    await this.uploadBannerImages();
    
    this.uploadBanners.forEach((banner:any) => {
      console.log(banner);
      if(!banner.bannerId){
        this.areNewBanners = true;
      }
    })
    if(this.areNewBanners){
      try{
          await this.apiService
          .updateThemingBanners(
            this.shopTheme[0].shopId,
            this.uploadBanners
          )   
          .then((resp: any ) =>{
            if(resp.status != 200){
              throw new Error("Error");
            }
            this.getBanners();
            $('#themingSuccess').modal('show');
            this.switchEditingBanner();
            $('#loadingModal').modal('hide');
          })
        }catch(err){
          $('#themingError').modal('hide');
        }
    }else{
      this.getBanners();
      this.switchEditingBanner();
      $('#themingEmpty').modal('show');
      $('#loadingModal').modal('hide');
    }
  }

  deleteBannerImage(index: number, array: any){
    array[index] = {};
    this.areNewBanners = true;
  }

  switchEditingBanner() {
    this.isEditingBanner = !this.isEditingBanner;
  }

  switchEditingImages() {
    this.isEditingImages = !this.isEditingImages;
  }

  closeModal(){
    $('#themingSuccess').modal('hide');
    $('#themingError').modal('hide');
    $('#themingEmpty').modal('hide');
  }

}