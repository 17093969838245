import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LambdaService {
  [x: string]: any;
  private apiKey = environment.apiKey;
  baseURL = environment.apiGatewayUrl;


  private baseConfig = `{"credentials":"same-origin","headers":{"Accept":"application/json","Content-Type":"application/json","Authorization":"${this.apiKey}"},"method":""}`;


  constructor(private injector: Injector) {}

  //---------------------BASE METHODS--------------------
  async simpleGet(url: string) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'GET';
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async simplePost(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'POST';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async simplePut(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'PUT';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Authorized (authentication required methods)

  async authorizedPut(url: string, body: any) {
    let config = JSON.parse(this.baseConfig);
    config.method = 'PUT';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async authorizedDelete(url: string, body: any) {
    // Change this method !!!!!!!!!!
    let config = JSON.parse(this.baseConfig);
    config.method = 'DELETE';
    config['body'] = body;
    return new Promise((resolve, reject) => {
      fetch(this.baseURL + url, config)
        .then((response) => {
          response
            .json()
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

   //-------------------SPECIFIC REQUEST METHODS------------------

   async getClientSessions(
    shopId: number,
    ) {
    let url = `/timekit/clientSessions?shopId=${shopId}`;
    return this.simpleGet(url);
  }

  createVendor(
    shopId: number,
    firstName: string,
    lastName: string,
    email: string
  ) {
    let url = `vendors/create?shopId=${shopId}`;
    let body = {
      firstName,
      lastName,
      email
    };
    return this.simplePost(url, JSON.stringify(body));
  }

  deleteVendor(shopId: number, email: number) {
    let url = `/vendors/delete?shopId=${shopId}`;
    let body = {
      email
    };
    return this.simplePost(url, JSON.stringify(body));
  }
}

