<div class="container">
  <div class="container-client">
    <div class="header">
      <div class="title-with-tooltip">
        <h1 class="title">{{ 'RESOURCES' | translate }}</h1>
        <div class="tooltip-box">
          <div class="hover-text">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
            </svg>
            <span class="tooltip-text">
              Es obligatorio crear un recurso para poder crear los proyectos. Solo se puede tener un correo por recurso, no se pueden repetir. A ese correo llegarán las notificaciones de los sesiones.
            </span>
          </div>
        </div>
      </div>

      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingResources()">
        {{ 'CREATE' | translate }}
      </button>
    </div>

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading && resources.length > 0" class="table table-bordered table-hover">
        <thead >
            <th scope="col" class="table-start">{{'RESOURCE_NAME' | translate}}</th>
            <th scope="col" class="table-end">{{'RESOURCE_ID' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources">
            <td>{{ resource.resourceName || 'Sin nombre' }}</td>
            <td>{{ resource.resourceId }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="resources.length === 0" class="no-orders">
        <span>{{'NO_RESOURCES' | translate}}</span>
      </div>
    </div>
  </div>
</div>
