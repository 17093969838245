import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Region } from '../models/region.interface';

@Injectable({
  providedIn: 'root',
})
export class RegionBackendService {
  public crId: string = '65e835df-66db-40cf-8e98-257e85493f89';
  constructor(private readonly _http: HttpClient) {}

  private buildUri(endpoint: string): string {
    return `https://api.crbytes.io/${endpoint}`;
  } // buildUri

  public getSubregions(regionId: string): Observable<Region[]> {
    const endpoint = this.buildUri(`regions/subRegions/${regionId}`);
    return this._http.get<Region[]>(endpoint);
  }// getAll

  public getRegionHierarchy(regionId: string): Observable<any> {
    const endpoint = this.buildUri(`regions/regionHierarchy/${regionId}`);
    return this._http.get<any>(endpoint);
  }
}
