import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

declare var $: any;

@Component({
  selector: 'app-timekit-create-project',
  templateUrl: './timekit-create-project.component.html',
  styleUrls: ['./timekit-create-project.component.scss']
})
export class TimekitCreateProjectComponent implements OnInit {

  data: any;
  projectForm: FormGroup;
  resources: any[] = [];
  loading: boolean = false;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  daysOfWeekInSpanish: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  hours = Array.from({ length: 24 }, (_, i) => (i + 1).toString());
  availability_constraints: any[] = [];
  public shopTheme: ShopTheming[] = [];
  resourceName!: string;
  showNoResourcesMessage: boolean = false;
  timekitResourceId: number = 0;
  months = Array.from({ length: 6 }, (_, i) => i + 1);
  minutes = [0, 10, 15, 30];
  selectedOption: string = '';
  categories: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];

  selectedCategoryId!: number
  selectedSubCategoryId!: number
  selectedSubSubCategoryId!: number

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService
  ) {
    this.projectForm = this.fb.group({
      name: ['', Validators.required],
      slug: ['', Validators.required],
      allow_conference: ['1', Validators.required],
      resource: [, Validators.required],
      availability: this.fb.group({
        mode: ['roundrobin_random', Validators.required],
        length: ['hours', Validators.required],
        from: ['hours', Validators.required],
        to: [' month', Validators.required],
        buffer: [' minutes', Validators.required],
        ignore_all_day_events: [false, Validators.required]
      }),
      availability_constraints: this.fb.array([]),
      block_hours: this.fb.array([]),
      booking: this.fb.group({
        graph: ['instant', Validators.required],
        what: ['', Validators.required],
        where: ['', Validators.required],
        description: ['', Validators.required]
      }),
      customer_fields: this.fb.array([])
    });
    this.addAvailabilityConstraint();
  }

  addCustomerField(): void {
    const customerFieldsArray = this.projectForm.get('customer_fields') as FormArray;
    customerFieldsArray.push(this.fb.group({
      title: ['', Validators.required],
      prefilled: [''],
      required: [false],
      readonly: [false]
    }));
  }

  ngOnInit(): void {
    this.getTimekitResources()
    this.loadCategories();
    this.loadSubCategories();
    this.loadSubSubCategories();
  }

  loadCategories() {
    this.apiService.getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
      })
      .catch(error => console.error(error));
  }

  loadSubCategories() {
    this.apiService.getSubCategories(this.themingService.shopId)
      .then((data: any) => {
        this.subCategories = data.subCategories;
      })
      .catch(error => console.error(error));
  }

  loadSubSubCategories() {
    this.apiService.getSubSubCategories(this.themingService.shopId)
      .then((data: any) => {
        this.subSubCategories = data.subSubCategories;
      })
      .catch(error => console.error(error));
  }

  onOptionChange(option: string) {
    this.selectedOption = option;
    this.resetSelections();
  }

  resetSelections() {
    this.selectedCategoryId = 0;
    this.selectedSubCategoryId = 0;
    this.selectedSubSubCategoryId = 0;
  }

  onCategoryChange(event: any) {
    this.selectedCategoryId = +event.target.value;
  }

  onSubCategoryChange(event: any) {
    this.selectedSubCategoryId = +event.target.value;
  }

  onSubSubCategoryChange(event: any) {
    this.selectedSubSubCategoryId = +event.target.value;
  }

  async getTimekitResources() {
    this.loading = true;
    this.showNoResourcesMessage = false;

    try {
      const response = await this.apiService.getTimekitResources(this.themingService.shopId) as { data: Array<{ timekitResourceId: number, resourceId: string, resourceName: string | null }> };


      this.resources = response.data || [];

      if (this.resources.length === 0) {
        this.showNoResourcesMessage = true;
      } else {
        const resourceIds = this.resources.map(resource => resource.resourceId);
        const resourceNames = this.resources.map(resource => resource.resourceName);
        const timekitResourceIds = this.resources.map(resource => resource.timekitResourceId);

        this.projectForm.get('resources')?.setValue(resourceIds);
        this.projectForm.get('resourceNames')?.setValue(resourceNames);
        this.projectForm.get('timekitResources')?.setValue(timekitResourceIds);
      }
    } catch (error) {
      this.resources = [];
    } finally {
      this.loading = false;
    }
  }

  changeResource(event: Event) {
    const element = event.target as HTMLSelectElement;
    const selectedResourceId = element.value;

    this.projectForm.get('resource')?.setValue(selectedResourceId);

    const selectedResource = this.resources.find(resource => resource.resourceId === selectedResourceId);

    if (selectedResource) {
      this.timekitResourceId = selectedResource.timekitResourceId;
    }
  }

  createProject(): void {

    if (this.projectForm.valid) {
      this.loading = true;

      const {
        name,
        slug,
        allow_conference,
        resources,
        availability,
        availability_constraints,
        booking,
        customer_fields
      } = this.projectForm.value;

      const shopId = this.themingService.shopId || 0;

      const customerFieldsArray = this.projectForm.value.customer_fields;


      const customerFieldsObject = customerFieldsArray.reduce((acc: any, field: any) => {
        acc[field.title.toLowerCase()] = field;
        return acc;
      }, {});


      if (!customerFieldsObject["name"]) {
        customerFieldsObject["name"] = {
          title: "name",
          prefilled: "",
          readonly: false
        };
      }

      if (!customerFieldsObject["email"]) {
        customerFieldsObject["email"] = {
          title: "email",
          prefilled: "",
          readonly: false
        };
      }

      const availabilityConstraints = this.projectForm.value.availability_constraints
        .filter((ac: any) => ac.allow_day_and_time?.day && ac.allow_day_and_time?.start && ac.allow_day_and_time?.end)
        .map((ac: any) => ({ allow_day_and_time: ac.allow_day_and_time }));

      const blockHoursArray = this.projectForm.value.block_hours || [];
      const validBlockHours = blockHoursArray
        .filter((bh: { start: string, end: string }) => bh.start && bh.end)
        .map((bh: { start: string, end: string }) => ({ block_hours: bh }));

      if (validBlockHours.length > 0) {
        availabilityConstraints.push(...validBlockHours);
      }
      const payload = {
        stage: 'dev',
        action: 'projects',
        shopId: shopId,
        body: {
          name,
          slug,
          allow_conference,
          resources: [this.projectForm.get('resource')?.value],
          availability: {
            mode: availability.mode || "roundrobin_random",
            from: availability.from || "2 hours",
            to: availability.to || "4 weeks",
            length: availability.length || "2 hours",
            buffer: availability.buffer || "10 minutes",
            ignore_all_day_events: availability.ignore_all_day_events || true
          },
          availability_constraints: availabilityConstraints,
          booking: {
            graph: booking.graph || "instant",
            what: booking.what || "Bookings",
            where: booking.where || "TBA",
            description: booking.description || "Default description"
          },
          customer_fields: customerFieldsObject
        }
      };

      const resourceId = resources && resources.length > 0 ? resources[0] : 'Resource ID no encontrado';

      this.apiService.createProject(payload)
        .then((data: any) => {
          this.loading = false;
          let parsedBody;
          try {
            parsedBody = JSON.parse(data.body);
          } catch (e) {
            console.error("Error al parsear 'body' de la respuesta:", e);
            return;
          }

          if (data.statusCode === 200 && parsedBody) {
            const projectName = parsedBody.project?.data?.name || 'Nombre no encontrado';
            const projectId = parsedBody.project?.data?.id || 'ID no encontrado';

            this.createTimekitProjects(projectName, projectId, resourceId);

            $('#successProjectModal').modal('show');
            $('#successProjectModal').on('hidden.bs.modal', () => {
              this.projectForm.reset();
            });
          } else {
            console.error("Respuesta inesperada:", parsedBody);
            this.showErrorModal();
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage =
            error?.response?.message || 'Ocurrió un error al crear el proyecto';

          this.showErrorModal();
          console.error('Error en creación de proyecto:', errorMessage);
        });
    } else {
      this.showRequiredFieldsModal();
    }
  }

  validateFormData(): void {
    if (
      !this.selectedOption ||
      (this.selectedOption === 'categories' && !this.selectedCategoryId) ||
      (this.selectedOption === 'subCategories' && !this.selectedSubCategoryId) ||
      (this.selectedOption === 'subSubcategories' && !this.selectedSubSubCategoryId)
    ) {
      console.error("Error: Selección de categorías inválida.");
      $('#requiredProjectModal').modal('show');
      return;
    }
    this.createProject();
  }

  showErrorModal(): void {
    $('#errorResourceModal').modal('show');
    $('#errorResourceModal').on('hidden.bs.modal', () => {
    });
    $('#errorProjectModal').modal('show');
    $('#errorProjectModal').on('hidden.bs.modal', () => {
    });
  }

  showRequiredFieldsModal(): void {
    $('#requiredProjectModal').modal('show');
  }

  closeModal() {
    $('#requiredProjectModal').modal('hide');
    $('#errorProjectModal').modal('hide');
  }

  closeSuccessModal() {
    $('#successProjectModal').modal('hide');
    setTimeout(() => {
      this.setListingProjects();
    }, 500);
  }

  createTimekitProjects(projectName: string, projectId: string, timekitResourceId: number): void {
    if (!timekitResourceId) return;

    this.apiService
      .createTimekitProjects(
        this.themingService.shopId,
        this.timekitResourceId,
        projectName,
        projectId,
        this.selectedCategoryId,
        this.selectedSubCategoryId,
        this.selectedSubSubCategoryId,
      )
      .then((data: any) => {
        if (!(data.status === 200 && data.msg === 'success')) {
          this.showErrorModal();
        }
      })
      .catch(() => {
        this.loading = false;
        this.showErrorModal();
      });
  }

  get availabilityConstraints() {
    return this.projectForm.get('availability_constraints') as FormArray;
  }

  public addAvailabilityConstraint(): void {
    const constraintsArray = this.projectForm.get('availability_constraints') as FormArray;
    constraintsArray.push(
      this.fb.group({
        allow_day_and_time: this.fb.group({
          day: ['', Validators.required],
          start: ['07:00', Validators.required],
          end: ['18:00', Validators.required]
        })
      })
    );
  }

  removeAvailabilityConstraint(index: number): void {
    (this.projectForm.get('availability_constraints') as FormArray).removeAt(index);
  }

  public addBlockHours(): void {
    const blockHoursArray = this.projectForm.get('block_hours') as FormArray;
    blockHoursArray.push(
      this.fb.group({
        start: ['', Validators.required],
        end: ['', Validators.required]
      })
    );
  }

  get blockHoursControls() {
    return (this.projectForm.get('block_hours') as FormArray).controls;
  }

  public removeBlockHours(index: number): void {
    (this.projectForm.get('block_hours') as FormArray).removeAt(index);
  }

  setListingProjects() {
    this.settingsService.setListingProjects();
  }

  getDayInEnglish(index: number): string {
    return this.daysOfWeek[index].toLowerCase();
  }

  get customerFields(): FormArray {
    return this.projectForm.get('customer_fields') as FormArray;
  }

  removeCustomerField(index: number): void {
    this.customerFields.removeAt(index);
  }
}
