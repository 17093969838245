<!-- edit-menu.component.html -->
<div class="container">
    <div class="container-inventory">
      <div class="header">
        <h5 class="title">{{ 'EDIT_MENU' | translate }}</h5>
      </div>
      <form #myForm="ngForm">
          <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        <div class="form-card"  *ngIf="!loading">
            <label class="form-label is-required" for="menu_name">{{'NAME' | translate}}</label>
            <div *ngIf="menu">
              <input type="text" class="form-control" id="menuName" placeholder="Nombre del menú" (keyup)="ChangeMenuName($event)"
              [(ngModel)]="menu.menuName" #menuName="ngModel" name="menuName" required/>
            </div>
          <div class="input-container">
            <div>
              <a class="create-link" (click)="addMenuOption()">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
                  <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                    d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16" />
                </svg>
              </a>
            </div>
            <div *ngFor="let menuOption of menuOptions; let i = index" class="form-info">
                <div class="option-name">
                  <div class="form-floating col-md-5">
                    <input type="text" class="form-control" id="option" placeholder="{{ 'OPTION_NAME' | translate }}"
                      [(ngModel)]="menuOption.optionName" name="optionName{{i}}" required>
                    <label for="option">{{ 'OPTION_NAME' | translate }}</label>
                  </div>
                </div>
                <div class="radios-container">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="categories"
                        (change)="onOptionChange('categories', i)" [checked]="menuOption.optionType === 'categories'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'CATEGORY' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="inlineRadioOptions{{i}}" name="inlineRadioOptions{{i}}"
                        value="subCategories" (change)="onOptionChange('subCategories', i)" [checked]="menuOption.optionType === 'subCategories'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'SUBCATEGORY' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="inlineRadioOptions{{i}}" name="inlineRadioOptions{{i}}"
                        value="subSubcategories" (change)="onOptionChange('subSubcategories', i)" [checked]="menuOption.optionType === 'subSubcategories'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'SUB_SUBCATEGORY' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="collections"
                        (change)="onOptionChange('collections', i)" [checked]="menuOption.optionType === 'collections'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'COLLECTIONS' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="pages"
                      (change)="onOptionChange('pages', i)" [checked]="menuOption.optionType === 'pages'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'PAGES' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="custom"
                        (change)="onOptionChange('custom', i)" [checked]="menuOption.optionType === 'custom'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'EXTERNAL_LINK' | translate}}</label>
                    </div>
  <!--                   <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions{{i}}" value="components"
                        (change)="onOptionChange('components', i)" [checked]="menuOption.optionType === 'components'">
                      <label class="form-check-label" for="inlineRadioOptions{{i}}">{{'COMPONENT_LINK' | translate}}</label>
                    </div> -->
                  </div>
                  <div class="select-container">
                    <div class="remove-button">
                      <a class="create-link" (click)="removeMenuOption(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                          <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
                        </svg>
                      </a>
                    </div>
                  <div *ngIf="menuOption.optionType === 'categories'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onCategoryChange($event, i)">
                      <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
                      <option *ngFor="let category of categories" [value]="category.categoryId" [selected]="category.categoryId === menuOption.categoryId" >
                        {{ category.categoryName }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="menuOption.optionType === 'subCategories'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onSubCategoryChange($event, i)">
                      <option selected disabled>{{'SELECT_SUBCATEGORY' | translate}}</option>
                      <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId" [selected]="subCategory.subCategoryId === menuOption.subCategoryId" >
                        {{ subCategory.subCategoryName }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="menuOption.optionType === 'subSubcategories'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onSubSubCategoryChange($event, i)">
                      <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
                      <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId" [selected]="subSubCategory.subSubCategoryId === menuOption.subSubCategoryId" >
                        {{ subSubCategory.subSubCategoryName }}
                      </option>
                    </select>
                  </div>
                  <div *ngIf="menuOption.optionType === 'collections'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onCollectionChange($event, i)">
                      <option selected disabled>{{'SELECT_COLLECTION' | translate}}</option>
                      <option *ngFor="let collection of collections" [value]="collection.collectionId" [selected]="collection.collectionId === menuOption.collectionId" >
                        {{ collection.collectionName }}
                      </option>
                    </select>
                  </div>
 <!--                  <div *ngIf="menuOption.optionType === 'components'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onComponentChange($event, i)">
                      <option selected disabled>{{'SELECT_COMPONENT_TYPE' | translate}}</option>
                      <option *ngFor="let component of components" [value]="component.componentId" [selected]="component.componentId === menuOption.componentId" >
                        {{ component.componentName }}
                      </option>
                    </select>
                  </div> -->
                  <div *ngIf="menuOption.optionType === 'custom'" class="form-floating col-md-5">
                    <input type="text" class="form-control" id="optionUrl" placeholder="URL de la opción"
                      [(ngModel)]="menuOption.optionUrl" name="optionUrl{{i}}" (change)="onUrlChange($event, i)" >
                    <label for="optionUrl">{{'OPTION_URL' | translate}}</label>
                  </div>
                  <div *ngIf="menuOption.optionType === 'pages'" class="form-floating col-md-5">
                    <select class="form-select" aria-label="Floating label select example" (change)="onPageChange($event, i)">
                      <option selected disabled>{{'SELECT_PAGE' | translate}}</option>
                      <option *ngFor="let page of pages" [value]="page.pageId" [selected]="page.pageId === menuOption.pageId" >
                      {{ page.pageName }}
                      </option>
                    </select>
                    </div>
                  </div>
            </div>
          </div>
          <div class="form-buttons">
            <button type="submit" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              {{'SAVE_CHANGES' | translate}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal menu-confirm-modal fade" id="successMenuModal" tabindex="-1">
    <div class="modal-dialog menu-modal-dialog" role="document">
      <div class="modal-content menu-modal-content">
        <div class="modal-body menu-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'MENU_UPDATED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal menu-confirm-modal fade" id="errorMenuModal" tabindex="-1">
    <div class="modal-dialog menu-modal-dialog" role="document">
      <div class="modal-content menu-modal-content">
        <div class="modal-body menu-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal menu-confirm-modal fade" id="requiredEditMenuModal" tabindex="-1">
    <div class="modal-dialog menu-modal-dialog" role="document">
      <div class="modal-content menu-modal-content">
        <div class="modal-body menu-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ALL_FIELDS_REQUIRED' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
