<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-component" *ngIf="!loading">
    <div class="header">
      <h3 class="title">{{ 'CREATE_COMPONENT' | translate }}</h3>
    </div>

    <div>
      <label class="form-label" for="component_name">{{'COMPONENT_NAME' | translate}}</label>
      <input type="text" class="form-control" id="componentName" [(ngModel)]="componentName" placeholder="{{'COMPONENT_NAME' | translate}}" maxlength="40" required/>
    </div>

    
    <label class="form-label" for="componentParentId">{{'COMPONENT_TYPE' | translate}}</label>
    <select class="form-select" id="componentParentId" (change)="onChangeParentId($event)">
      <option selected disabled>{{ 'SELECT_COMPONENT_TYPE' | translate }}</option>
      <option *ngFor="let parent of uniqueParentIds" [value]="parent.id">
        {{ parent.name }}
      </option>
    </select>

    <div *ngIf="selectedParentId== 3"  class="input-container">
      <div class="form-info">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="collections" (change)="onOptionChange('collections')">
          <label class="form-check-label" for="inlineRadio2">{{'COLLECTIONS' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="categories" (change)="onOptionChange('categories')">
          <label class="form-check-label" for="inlineRadio1">{{'CATEGORIES' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="subCategories" (change)="onOptionChange('subCategories')">
          <label class="form-check-label" for="inlineRadio2">{{'SUBCATEGORY' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="subSubcategories" (change)="onOptionChange('subSubcategories')">
          <label class="form-check-label" for="inlineRadio2">{{'SUB_SUBCATEGORIES' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="custom" disabled>
          <label class="form-check-label" for="inlineRadio3">Personalizado</label>
        </div>
      </div>

      <div class="form-info">
        <div *ngIf="selectedOption === 'collections'" class="form-floating" >
          <select class="form-select" id="collections" aria-label="Floating label select example" (change)="onCollectionChange($event)">
            <option selected disabled>{{'SELECT_COLLECTION' | translate}}</option>
            <option *ngFor="let collection of collections" [value]="collection.collectionId">
              {{ collection.collectionName }}
            </option>
          </select>
          <label for="collections">{{'COLLECTIONS' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'categories'" class="form-floating">
          <select class="form-select" id="category" aria-label="Floating label select example" (change)="onCategoryChange($event)">
            <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
            <option *ngFor="let category of categories" [value]="category.categoryId">
              {{ category.categoryName }}
            </option>
          </select>
          <label for="category">{{'CATEGORIES' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'subCategories'" class="form-floating">
          <select class="form-select" id="subCategory" aria-label="Floating label select example" (change)="onSubCategoryChange($event)">
            <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
              {{ subCategory.subCategoryName }}
            </option>
          </select>
          <label for="subCategory">{{'SUBCATEGORY' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'subSubcategories'" class="form-floating">
          <select class="form-select" id="subSubCategory" aria-label="Floating label select example" (change)="onSubSubCategoryChange($event)">
            <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
              {{ subSubCategory.subSubCategoryName }}
            </option>
          </select>
          <label for="subSubCategory">{{'SUB_SUBCATEGORIES' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="form-duo" *ngIf="selectedParentId">
      <label class="form-label" for="componentTypeDesignId">{{ 'LAYOUT' | translate }}</label>
      <select class="form-select" id="componentTypeDesignId" [disabled]="!designEnabled" (change)="changeComponentTypeId($event)">
        <option selected disabled>{{ 'SELECT_LAYOUT' | translate }}</option>
        <ng-container *ngIf="groupedDesigns[selectedParentId]">
          <option *ngFor="let design of groupedDesigns[selectedParentId]" [value]="design.typeId">
            {{ design.name }}
          </option>
        </ng-container>
      </select>

      <div *ngIf="selectedDesignDescription">
        <label class="subtitle-label" for="component_name">{{'DESCRIPTION' | translate}}</label>
        <p>{{ selectedDesignDescription }}</p>
      </div>

      <div *ngIf="selectedDesignImageUrl" class="form-duo">
        <label class="subtitle-label" for="component_img">{{ 'PREVIEW' | translate }}</label>
        <img [src]="sanitizeURL(selectedDesignImageUrl)" alt="Design Image" class="design-image"/>
      </div>

      <div class="component-image" *ngIf="componentForm.get('component.componentImage')?.value">
        <img [src]="sanitizeURL(componentForm.get('component.componentImage')?.value)" alt="Component Image">
      </div>
    </div>

    <div class="col-md-6" *ngIf="getFieldVisibilityConfig().includes('itemQuantity')">
      <label class="form-label" for="componentAmount">{{ 'ITEMS_QUANTITY' | translate }}</label>
      <select class="form-select" id="componentAmount" (change)="onItemQuantityChange($event)">
        <option selected disabled>{{ 'SELECT_ITEMS_QUANTITY' | translate }}</option>
        <ng-container *ngIf="groupedDesigns[selectedParentId]">
        <option>4</option>
        <option>8</option>
        <option>12</option>
        <option>16</option>
        </ng-container>
      </select>
    </div>

    <form [formGroup]="componentForm" (ngSubmit)="validateFormData()">
    <div formGroupName="component">
      <div *ngIf="getFieldVisibilityConfig().includes('title')">
        <label class="form-label" for="component_title">{{ 'TITLE' | translate }}</label>
        <quill-editor
          class="title-editor"
          id="componentTitle"
          [modules]="modules"
          formControlName="title"
          (onEditorChanged)="titleChanged($event)"
          [styles]="{
            borderRadius: '0 0 10px 10px',
            padding: '5px',
            marginBottom: '2vh'
          }">
        </quill-editor>
      </div>

      <div *ngIf="getFieldVisibilityConfig().includes('subtitle')">
        <label class="form-label" for="component_subtitle">{{'SUBTITLE' | translate}}</label>
        <quill-editor
          class="subtitle-editor"
          id="componentSubtitle"
          [modules]="modules"
          formControlName="subtitle"
          (onEditorChanged)="subtitleChanged($event)"
          [styles]="{
            borderRadius: '0 0 10px 10px',
            padding: '5px',
            marginBottom: '2vh'
          }">
        </quill-editor>
      </div>

      <div *ngIf="getFieldVisibilityConfig().includes('description')">
        <label class="form-label" for="component_description">{{'DESCRIPTION' | translate}}</label>
        <quill-editor
          class="description-editor"
          id="description-edit"
          [modules]="modules"
          formControlName="description" 
          (onEditorChanged)="descriptionChanged($event)"
          [styles]="{
            borderRadius: '0 0 10px 10px',
            padding: '5px',
            marginBottom: '2vh'
          }">
        </quill-editor>
        <div>
          <small>{{'REMAINING_CHARACTERS' | translate}}: {{ remainingCharacters }} / {{ maxLength }}</small>
        </div>
      </div>
    </div>

      <div *ngIf="selectedDesign && selectedParentId !=3">
        <div class="subtitle-label">{{ 'ADD_ITEMS' | translate }}:</div>
        <div class="info-fields-container">
          <button class="add-img" (click)="addNewField()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
              <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16"/>
            </svg>
          </button>
        </div>
        <div cdkDropList cdkDropListOrientation="horizontal" class="image-list">
          <div class="input-container" formArrayName="infoFields">
            <div class="image-box" *ngFor="let field of infoFields.controls; let i = index" [formGroupName]="i">

              <div class="form-duo col-md-8 ">
                <div class="col-md-6 form-input form-floating" *ngIf="getFieldVisibilityConfig().includes('infoVideoUrl')">
                  <select id="videoTypeSelect-{{i}}" class="form-select" aria-label="Floating label select example" (change)="onVideoSourceChange(i, $event)">
                    <option value="1" [selected]="field.get('videoUrlType')?.value == 1">{{ 'UPLOAD_FILE' | translate }}</option>
                    <option value="2" [selected]="field.get('videoUrlType')?.value == 2">{{ 'INSERT_VIDEO_URL' | translate }}</option>
                  </select>
                    <label for="videoTypeSelect-{{i}}">{{ 'SELECT_VIDEO_SOURCE' | translate }}</label>
                </div>

                <div class="form-floating form-input" *ngIf="field.get('videoUrlType')?.value == 2 && getFieldVisibilityConfig().includes('infoVideoUrl')">
                  <input type="text" class="form-control"
                          [value]="field.get('videoManualURL')?.value"
                          (input)="handleVideoManualURLChange(i, $event)"
                          placeholder="https://example.com/video.mp4">
                        <label>{{ 'PASTE_VIDEO_LINK' | translate }}</label>
                </div>
                <div *ngIf="getFieldVisibilityConfig().includes('infoTitle')">
                  <label class="form-label form-input" for="infoTitle-{{i}}">{{ 'TITLE' | translate }}</label>
                    <quill-editor
                      class="info-title-editor form-input"
                      id="infoTitle-{{i}}" 
                      [modules]="modules"
                      formControlName="infoTitle"
                      (onEditorChanged)="infoTitleChanged($event, i)"
                      [styles]="{
                        borderRadius: '0 0 10px 10px',
                        padding: '5px',
                        marginBottom: '2vh'
                      }">
                    </quill-editor>
                </div>
                <div class="mb-4" *ngIf="getFieldVisibilityConfig().includes('infoDescription')">
                  <label class="form-label form-input" for="infoDescription">{{'DESCRIPTION' | translate}}</label>
                  <quill-editor
                    class="info-description-editor form-input"
                    id="info-description-edit"
                    [modules]="modules"
                    formControlName="infoDescription"
                    (onEditorChanged)="infoDescriptionChanged($event)"
                    [styles]="{
                      borderRadius: '0 0 10px 10px',
                      padding: '5px',
                      marginBottom: '0.5vh'
                    }">
                  </quill-editor>
                  <div>
                    <small>
                      {{'REMAINING_CHARACTERS' | translate}}: {{ remainingInfoCharacters }} / {{ infoMaxLength }}
                    </small>
                  </div>
                </div>
                <div class="form-floating form-input" *ngIf="getFieldVisibilityConfig().includes('urlName')">
                  <input type="text" class="form-control" formControlName="urlName" placeholder="Enlace / botón" maxlength="50">
                  <label for="urlName">{{ 'LINK_NAME' | translate }}</label>
                </div>
                <div class="form-floating form-input" *ngIf="getFieldVisibilityConfig().includes('redirectUrl')">
                  <input type="text" class="form-control" formControlName="redirectUrl" placeholder="Enlace de redirección">
                  <label for="redirectUrl">{{ 'REDIRECTION_LINK' | translate }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="media-container"> 
                  <!-- Image -->
                  <div *ngIf="field.get('imageURL')?.value">
                    <img [src]="sanitizeURL(field.get('imageURL')?.value)" alt="Imagen cargada" class="preview-image"/>
                    <a class="remove-btn" (click)="removeMedia(i, 'imageURL')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
                      </svg>
                    </a>
                  </div>
              
                  <div *ngIf="!field.get('videoURL')?.value && !field.get('videoManualURL')?.value && getFieldVisibilityConfig().includes('infoImageUrl')">
                    <a class="add-button" (click)="triggerFileInput(i, 'image')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="#5E35B1" d="M3 4V1h2v3h3v2H5v3H3V6H0V4m6 6V7h3V4h7l1.8 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10m10 9c4.45 0 6.69-5.38 3.54-8.54C13.39 7.31 8 9.55 8 14c0 2.76 2.24 5 5 5m-3.2-5c0 2.85 3.45 4.28 5.46 2.26c2.02-2.01.59-5.46-2.26-5.46A3.21 3.21 0 0 0 9.8 14"/></svg>
                    </a>
                    <input id="btn-upload-image-{{i}}" type="file" class="d-none" (change)="handleFileSelection(i, $event, 'image')" accept="image/*">
                  </div>
                </div> 

                <!-- video -->
                <div class="media-container">
                  <div *ngIf="field.get('videoUrlType')?.value == 1 && getFieldVisibilityConfig().includes('infoVideoUrl')">
                    <a class="add-button" (click)="triggerFileInput(i, 'video')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 20 20">
                        <path fill="#5E35B1" d="M2 6a3 3 0 0 1 3-3h5a3 3 0 0 1 3 3v6c0 1.31-.84 2.424-2.01 2.833q.01-.165.01-.333a5.5 5.5 0 0 0-9-4.243zm14.037 7.776L14 12.369V5.63l2.037-1.406a1.25 1.25 0 0 1 1.96 1.028v7.495a1.25 1.25 0 0 1-1.96 1.029M10 14.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0m-4-2a.5.5 0 0 0-1 0V14H3.5a.5.5 0 0 0 0 1H5v1.5a.5.5 0 0 0 1 0V15h1.5a.5.5 0 0 0 0-1H6z"/>
                      </svg>
                    </a>
                    <input id="btn-upload-video-{{i}}" type="file" class="d-none" (change)="handleFileSelection(i, $event, 'video')" accept="video/*">
                  </div>
                  <div *ngIf="field.get('videoURL')?.value">
                    <video #videoPlayer controls width="200" (loadedmetadata)="captureThumbnail(videoPlayer, i)" preload="metadata">
                      <source [src]="sanitizeURL(field.get('videoURL')?.value)" type="video/mp4">
                      <p>{{ 'VIDEO_NOT_SUPPORTED' | translate }}</p>
                    </video>
                    <a class="remove-btn" (click)="removeMedia(i, 'videoURL')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
                      </svg>
                    </a>
                  </div>
                  <div *ngIf="field.get('videoUrlType')?.value == 2 && field.get('videoManualURL')?.value">
                    <iframe *ngIf="isYouTubeVideo(field.get('videoManualURL')?.value)" 
                            width="200" height="150" 
                            [src]="sanitizeVideoURL(field.get('videoManualURL')?.value)" 
                            frameborder="0" allowfullscreen>
                    </iframe>
                    <video *ngIf="!isYouTubeVideo(field.get('videoManualURL')?.value)" 
                          controls width="200">
                      <source [src]="sanitizeURL(field.get('videoManualURL')?.value)" type="video/mp4">
                      <p>{{ 'VIDEO_NOT_SUPPORTED' | translate }}</p>
                    </video>
                  </div>
                </div>
                <div>
                  <button type="button" class="btn btn-danger delete-button" (click)="removeField(i)">
                    x
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="submit" class="btn btn-outline-secondary submit-btn">
          {{'CREATE' | translate}}
        </button>
      </div>
    </form>
  </div>

  <div class="modal component-confirm-modal fade" id="successcomponentModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'COMPONENT_CREATED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal component-confirm-modal fade" id="errorcomponentModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal component-confirm-modal fade" id="errorCreateFileSizeModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_FILE_SIZE' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal component-confirm-modal fade" id="errorMaxMinItemModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{ errorMessage }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
