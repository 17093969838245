import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-sub-subcategories',
  templateUrl: './sub-subcategories.component.html',
  styleUrls: ['./sub-subcategories.component.scss']
})
export class SubSubcategoriesComponent implements OnInit {
  @Input() categoryId!:string;
  @Input() categoryName!:string;
  @Input()id!: string;

  subSubCategories: any[] = [];
  loading: boolean = false;
  searchString: string = '';
  modalDelete = false;
  subSubCategoryIdToDelete: any;
  showCreateSubSubCategoryModal = false;
  showEditSubSubCategoryModal= false;
  selectedSubSubCategoryId!: string;
  selectedCategoryId: any;
  timekitResources: any[] = [];
  categories: any[] = [];

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    public router: Router,
    private settingsService: SettingsService
  ) {
  }

  ngOnInit(): void {
    this.getSubSubCategories()
    this.getCategories(this.themingService.shopId);
  }

  async getSubSubCategories() {
    this.loading = true;
    await this.apiService
      .getSubSubCategories(this.themingService.shopId)
      .then((data: any) => {
        this.subSubCategories = data.subSubCategories;
        
        // Asignar el nombre del recurso correspondiente
        this.subSubCategories.forEach(subSubCategory => {
          const resource = this.timekitResources.find(
            (r: { timekitResourceId: any; }) => r.timekitResourceId === subSubCategory.timekitResourceId
          );
          subSubCategory.resourceName =  resource
        });
  
        console.log("SubSubCategories actualizadas:", this.subSubCategories);
      })
      .catch(error => {
        console.error('Error obteniendo sub-subcategorías:', error);
      });
    this.loading = false;
  }
  

  getCategories(shopId: number) {
    return this.apiService
      .getCategories(shopId)
      .then((data: any) => {
        this.categories = data.categories;
        this.timekitResources = data.timekitResources || [];
        console.log("Recursos cargados:", this.timekitResources);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  searchSubSubCategories(){
  }

  async deleteSubSubCategory(response: number) {
    if (response && this.subSubCategoryIdToDelete !== null) {
      this.loading = true;
      try {
        const subSubCategory = this.subSubCategories.find(subSubCat => subSubCat.subSubCategoryId === this.subSubCategoryIdToDelete);
        if (subSubCategory && subSubCategory.subSubCategoryImageUrl) {
          await this.deleteImage(subSubCategory.subSubCategoryImageUrl);
        }
        await this.apiService.deleteSubSubCategory(this.themingService.shopId, this.subSubCategoryIdToDelete);
        this.getSubSubCategories();
        $('#deleteSubSubCategoryModal').modal('show');
      } catch (error) {
        console.error('Error deleting subSubCategory:', error);
      } finally {
        this.loading = false;
        this.modalDelete = false;
      }
    } else {
      this.modalDelete = false;
    }
  }

  async deleteImage(imageUrl: string) {
    try {
      await this.apiService.deleteFile(imageUrl);
      console.log('Image deleted successfully');
    } catch (error) {
      console.log('Error deleting image:', error);
    }
  }

  setEditingSubSubCategory(subSubCategoryId: string){
    this.settingsService.setEditingSubSubCategory(subSubCategoryId)
  }

  getCreatingSubSubCategory(){
    this.settingsService.getCreatingSubSubCategory()
  }

  setCreatingSubSubCategory(){
    this.settingsService.setCreatingSubSubCategory();
  }

  
  openCreateSubSubCategoryModal() {
    this.showCreateSubSubCategoryModal = true;
  }

  closeCreateSubSubCategoryModal() {
    this.showCreateSubSubCategoryModal = false;
  }

  handleSubSubCategoryCreated() {
    this.showCreateSubSubCategoryModal = false;
    this.getSubSubCategories()
  }

  openEditSubCategoryModal(subSubCategoryId: string) {
    this.selectedSubSubCategoryId = subSubCategoryId;
    this.selectedCategoryId = this.categoryId;
    this.showEditSubSubCategoryModal = true;
  }

  handleSubSubCategoryUpdated() {
    this.showEditSubSubCategoryModal = false;
    this.getSubSubCategories();
  }

  closeEditSubSubCategoryModal() {
    this.showEditSubSubCategoryModal = false;
  }

  closeModal(){
    $('#deleteSubSubCategoryModal').modal('hide');
  }
}

