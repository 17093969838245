<div class="container" *ngIf="isLoaded" [@inOutAnimation]>
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">{{ 'USER_DETAILS' | translate }}</h1>
      <app-back-arrow-settings [callback]="setListingUsers"></app-back-arrow-settings>
    </div>
      <div class="white-card">
        <div class="staff-container">
          <div class="member-container">
            <div>
              <p class="user-name">{{user.given_name + " " + user.family_name}}</p>
              <p class="user-email">{{user.email}}</p>
            </div>
            <div class="role-container">
             <!--  <p>{{user.user_role}}</p> -->
            </div>
          </div>
          <div class="alert-span-exeption">
            <span *ngIf="exceptionMessage != ''" [@inOutAnimation] class="error-message">{{exceptionMessage}}</span>
          </div>
          <div class="user-state">
            <p>{{ 'STORE_ACCESS' | translate }}:</p>
            <p class="state">{{ user.Enabled ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</p>
          </div>
          <div class="user-state">
            <p>{{ 'USER_STATUS' | translate }}:</p>
            <p class="state">{{userStatus}}</p>
          </div>
          <hr>
          <div class="permissions-container">
            <h2>{{ 'ACCESS_PERMISSIONS' | translate }}</h2>
            <div class="option-container">
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.home" class="switch">{{'START' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.sales" class="switch">{{'ORDERS' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.pos" class="switch" (change)="onPosToggleChange()">POS</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.cashRegisters" class="switch">{{'CASH_REGISTER' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.products" class="switch">{{'PRODUCTS' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.giftCards" class="switch">{{'GIFT_CARDS' | translate}}</mat-slide-toggle>
              </div>
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.coupons" class="switch">{{'COUPONS' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.clients" class="switch">{{'CLIENTS' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.exportClients" class="switch"> {{'DOWNLOAD_FILE' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.calendar" class="switch">Agenda</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.config" class="switch">{{'CONFIGURATION' | translate}}</mat-slide-toggle>
              </div>
            </div>
            <h4 *ngIf="user.permissions.config">{{ 'CONFIGURATION_PERMISSIONS' | translate }}</h4>
            <div class="option-container" *ngIf="user.permissions.config">
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.generalInfo" class="switch">{{"GENERAL_INFORMATION" | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.userRoles" class="switch">{{'USERS_AND_ROLES' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.categories" class="switch">{{'CATEGORIES' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.collections" class="switch">{{'COLLECTIONS' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.supliers" class="switch">{{'SUPPLIERS' | translate}}</mat-slide-toggle>
              </div>
              <div class="options-column">
                <mat-slide-toggle [(ngModel)]="user.permissions.shopPoints" class="switch">{{ 'POINTS_OF_SALE' | translate }}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.paymentMethods" class="switch">{{"PAYMENT_METHODS" | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.themingService" class="switch">{{ 'STORE_THEME' | translate }}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.pages" class="switch">{{'PAGES' | translate}}</mat-slide-toggle>
                <mat-slide-toggle [(ngModel)]="user.permissions.faqs" class="switch">{{'FREQUENT_QUESTIONS' | translate}}</mat-slide-toggle>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button type="button" class="btn primary-btn" (click)="updateUser()">{{'SAVE' | translate}} {{ 'PERMISSIONS' | translate }}</button>
            <button class="disable" *ngIf="user.Enabled == true" (click)="modalDisable = true">{{ 'DISABLE_ACCESS' | translate }}</button>
            <button class="disable" *ngIf="user.Enabled == false" (click)="modalEnable = true">{{ 'ENABLE_ACCESS' | translate }}</button>
            <button class="remove" (click)="modalDelete = true">{{ 'REMOVE_USER' | translate }} {{user.given_name.split(' ').slice(0, 1).join(' ')}}</button>
          </div>
        </div>
      </div>
  </div>
</div>

<app-confirmation-modal *ngIf="modalDisable"
    [title]="'CONFIRM_DISABLE_ACCESS' | translate"
    [message]="'DISABLE_ACCESS_WARNING' | translate"
    [cancelText]="'CANCEL' | translate"
    [confirmText]="'DEACTIVATE' | translate"
    (response)="disableUserAccess($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="modalEnable"
    [title]="'CONFIRM_ENABLE_ACCESS' | translate"
    [message]="'ENABLE_ACCESS_WARNING' | translate"
    [cancelText]="'CANCEL' | translate"
    [confirmText]="'ENABLE' | translate"
    (response)="enableUserAccess($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="modalDelete"
    [title]="'CONFIRM_DELETE_USER' | translate"
    [message]="'DELETE_USER_WARNING' | translate"
    [cancelText]="'CANCEL' | translate"
    [confirmText]="'DELETE' | translate"
    (response)="deleteUser($event)"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="deleteFeedbackModal"
    [title]="'USER_DELETED' | translate"
    [message]="'USER_DELETED_SUCCESS' | translate"
    [confirmText]="'BACK_TO_USERS' | translate"
    [twoFieldModal]="false"
    (response)="setListingUsers()"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="updatePermissions"
    [title]="'PERMISSIONS_UPDATED' | translate"
    [message]="'PERMISSIONS_UPDATED_SUCCESS' | translate"
    [confirmText]="'CLOSE' | translate"
    [twoFieldModal]="false"
    (response)="updatePermissions = false"
></app-confirmation-modal>

<app-confirmation-modal *ngIf="deleteVendor"
    [title]="'DELETE_VENDOR' | translate"
    [message]="'DELETE_VENDOR_MESSAGE' | translate"
    [cancelText]="'CANCEL' | translate"
    [confirmText]="'ACCEPT' | translate"
    (response)="deleteVendor = false"
></app-confirmation-modal>






