import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { alertsAnimation, AuthService } from 'src/app/auth.service';
import { SettingsService } from 'src/app/settings.service';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { LambdaService } from 'src/app/lambda.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: [ alertsAnimation ]
})
export class UserDetailsComponent implements OnInit {

  @Input()
  username!: string;

  user: any;
  isLoaded: boolean = false;
  exceptionMessage: String = '';

  modalDisable: boolean = false;
  modalEnable: boolean = false;
  modalDelete: boolean = false;
  deleteFeedbackModal: boolean = false;
  userStatus: string = '';
  updatePermissions: boolean = false;
  configAccess: boolean = false;
  private posTouched: boolean = false;
  deleteVendor: boolean = false;
  private originalPosState: boolean = false;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authService: AuthService,
      private settingsService: SettingsService,
      private apiService: ApiService,
      private themingService: ThemingService,
      private lambdaService: LambdaService
    ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // let username = params['Username'];
      this.authService.getUser(this.username).then(user=>{
        this.user = user;
        this.userStatusDisplay(this.user.UserStatus);
        this.originalPosState = this.user.permissions.pos;
      }).finally(()=>{
        this.isLoaded = true;
      });
    });
  }

  onPosToggleChange() {
    this.posTouched = true;

    if (this.originalPosState && !this.user.permissions.pos) {
      this.deleteVendor = true;
    }

    this.originalPosState = this.user.permissions.pos;
  }

  async updateUser() {
    try {
      await this.authService.updateUser(
        this.user.Username,
        this.user.given_name,
        this.user.family_name,
        this.user.phone_number,
        this.user.permissions
      );

      this.updatePermissions = true;
      const firstName = this.user.given_name;
      const lastName = this.user.family_name;
      const email = this.user.email;

      if (this.posTouched) {
        if (this.user.permissions.pos) {
          let response = await this.apiService.getVendors(this.themingService.shopId) as { data?: { email: string }[] };
          let vendors: { email: string }[] = response.data ?? [];

          const emailExists = vendors.some((vendor) => vendor.email === email);

          if (!emailExists) {
            await this.lambdaService.createVendor(this.themingService.shopId, firstName, lastName, email);
          } else {
            this.exceptionMessage = 'Este email ya está en uso por otro vendor';
          }
        } else {
          await this.lambdaService.deleteVendor(this.themingService.shopId, email);
        }

        this.posTouched = false;
      }
    } catch (error) {
      this.exceptionMessage = (error as Error).message;
    }
  }

  reloadUser(username: string = this.user.Username){
    this.authService.getUser(username).then(user=>{
      this.user = user;
      this.userStatusDisplay(this.user.UserStatus);
    })
  }

  disableUserAccess(response: boolean){
    if(response){
      this.authService.disableUserAccess(this.user.Username).then(() =>{
        this.reloadUser();
        this.modalDisable = false;
      }).catch(error=>{
        this.exceptionMessage = error.message;
      });
    } else {
      this.modalDisable = false;
    }
  }

  enableUserAccess(response: boolean){
    if(response){
      this.authService.enableUserAccess(this.user.Username).then(() =>{
        this.reloadUser();
        this.modalEnable = false;
      }).catch(error=>{
        this.exceptionMessage = error.message;
      });
    } else {
      this.modalEnable = false;
    }
  }

  async deleteUser(response: boolean) {
    if (response) {
        try {
          await this.authService.deleteUser(this.user.Username);
          const email = this.user.email;

          await this.lambdaService.deleteVendor(this.themingService.shopId, email);

        } catch (error) {
          this.exceptionMessage = (error as Error).message;
        } finally {
          this.modalDelete = false;
          this.deleteFeedbackModal = true;
        }
    } else {
        this.modalDelete = false;
    }
}

  goToUsers(response: boolean){
    if(response){
      this.router.navigate(['/users']);
    }
  }

  userStatusDisplay(status: string){
    switch(status){
      case 'CONFIRMED':
        this.userStatus = 'Confirmado';
        break;

      case 'UNCONFIRMED':
        this.userStatus = 'Sin confirmar';
        break;

      case 'ARCHIVED':
        this.userStatus = 'Archivado';
        break;

      case 'UNKNOWN':
        this.userStatus = 'Desconocido';
        break;

      case 'RESET_REQUIRED':
        this.userStatus = 'Reinicio requerido';
        break;

      case 'FORCE_CHANGE_PASSWORD':
        this.userStatus = 'Cambio de contraseña requerido';
        var date = new Date();
        date.setDate(date.getDate() - 7);
        //console.log(date);
        break;
    }
  }

  /**
   * The function calls the setListingUsers() function in the settingsService
   */
  setListingUsers(): void {
    this.settingsService.setListingUsers();
  }
}
