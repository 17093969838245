<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-component" *ngIf="!loading">
    <div class="header">
      <h3 class="title">{{'EDIT_COMPONENT' | translate}}</h3>
    </div>
    <form>
      <div class="form-info">
        <div class="form-trio col-md-6">
          <div class="form-floating">
            <input type="text" class="form-control" id="componentName" placeholder="{{'COMPONENT_NAME' | translate}}" [(ngModel)]="component.componentName" name="componentName" (ngModelChange)="changeComponentName($event)" maxlength="40"/>
            <label for="componentName">{{'COMPONENT_NAME' | translate}}</label>
          </div>
          <div *ngIf="getFieldVisibilityConfig().includes('title')">
            <label for="componentTitle">{{'TITLE' | translate}}</label>
            <quill-editor
              class="title-editor"
              id="componentTitle"
              name="title"
              [(ngModel)]="component.title"
              [modules]="modules"
              [styles]="{
                borderRadius: '0 0 10px 10px',
                padding: '5px',
                marginBottom: '2vh'
              }"
              (onEditorChanged)="changeComponentTitle($event)"
              #titleInput="ngModel"
              >
            </quill-editor>
          </div>
          
          <div *ngIf="getFieldVisibilityConfig().includes('subtitle')">
            <label for="componentSubtitle">{{'SUBTITLE' | translate}}</label>
            <quill-editor
              class="title-editor"
              id="componentSubtitle"
              name="componentSubtitle"
              [(ngModel)]="component.subtitle"
              [modules]="modules"
              [styles]="{
                borderRadius: '0 0 10px 10px',
                padding: '5px',
                marginBottom: '2vh'
              }"
              (onEditorChanged)="changeComponentSubtitle($event)"
              #subTitleInput="ngModel"
              >
            </quill-editor>
          </div>

          <div *ngIf="getFieldVisibilityConfig().includes('description')">
            <label class="form-label info-description" for="component_description">{{'DESCRIPTION' | translate}}</label>
            <quill-editor
              class="description-editor"
              id="description-edit"
              name="description"
              [(ngModel)]="component.description"
              [modules]="modules"
              [styles]="{
                borderRadius: '0 0 10px 10px',
                padding: '5px',
                marginBottom: '2vh'
              }"
              (onEditorChanged)="descriptionChanged($event)"
              #descriptionInput="ngModel"
            >
            </quill-editor>
            <div>
              <small>{{'REMAINING_CHARACTERS' | translate}} {{ remainingCharacters }} / {{ maxLength }}</small>
            </div>
          </div>
        </div>
        <div *ngIf="component.componentParentId !== 3"class="preview-image-container col-md-4">
          <label for="component_img">{{component.componentTypeName}}</label>
          <img  class="design-image" [src]="sanitizeImageURL(component.imageUrl)" alt="Design Image"/>
        </div>
      </div>
      <div *ngIf="component.componentParentId == 3" class="input-container">
        <div class="form-info">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="collections" [(ngModel)]="selectedOption" (change)="onOptionChange('collections')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'COLLECTIONS' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="categories" [(ngModel)]="selectedOption" (change)="onOptionChange('categories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio1">{{'CATEGORIES' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subCategories" [(ngModel)]="selectedOption" (change)="onOptionChange('subCategories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'SUBCATEGORY' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subSubcategories" [(ngModel)]="selectedOption" (change)="onOptionChange('subSubcategories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'SUB_SUBCATEGORIES' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="custom" disabled>
            <label class="form-check-label" for="inlineRadio3">Personalizado</label>
          </div>
        </div>

        <div class="form-info">
          <div *ngIf="selectedOption === 'collections'" class="form-floating">
            <select class="form-select" id="collections" aria-label="Floating label select example" [(ngModel)]="collectionId" (change)="onCollectionChange($event)" name="collectionId">
              <option selected disabled>{{'SELECT_COLLECTION' | translate}}</option>
              <option *ngFor="let collection of collections" [value]="collection.collectionId">
                {{ collection.collectionName }}
              </option>
            </select>
            <label for="collections">{{'COLLECTIONS' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'categories'" class="form-floating">
            <select class="form-select" id="category" aria-label="Floating label select example" [(ngModel)]="categoryId" (change)="onCategoryChange($event)" name="categoryId">
              <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
              <option *ngFor="let category of categories" [value]="category.categoryId">
                {{ category.categoryName }}
              </option>
            </select>
            <label for="category">{{'CATEGORY' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'subCategories'" class="form-floating">
            <select class="form-select" id="subCategory" aria-label="Floating label select example" [(ngModel)]="subCategoryId" (change)="onSubCategoryChange($event)" name="subCategoryId">
              <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
              <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
                {{ subCategory.subCategoryName }}
              </option>
            </select>
            <label for="subCategory">{{'SUBCATEGORY' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'subSubcategories'" class="form-floating">
            <select class="form-select" id="subSubCategory" aria-label="Floating label select example" [(ngModel)]="subSubCategoryId" (change)="onSubSubCategoryChange($event)" name="subSubCategoryId">
              <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
              <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
                {{ subSubCategory.subSubCategoryName }}
              </option>
            </select>
            <label for="subSubCategory">{{'SUB_SUBCATEGORY' | translate}}</label>
          </div>
        </div>
      </div>

      <div *ngIf="component.componentParentId === 3" class="component-types">
        <h5>{{ 'SELECT_LAYOUT' | translate }}</h5>
        <div class="component-type-selection">
          <div class="form-check form-check-inline" *ngFor="let type of componentTypes">
            <input class="form-check-input d-none" type="radio" [value]="type.componentTypeId" [(ngModel)]="component.componentTypeId" [id]="'componentType' + type.componentTypeId" name="componentTypeId" [checked]="component.componentTypeId === type.componentTypeId"/>
            <label class="form-check-label" [for]="'componentType' + type.componentTypeId">
              <p class="image-label">{{ type.componentTypeName }}</p>
              <img [src]="type.imageUrl" [alt]="type.componentTypeDescription"
                   class="component-type-image"
                   [class.selected]="component.componentTypeId === type.componentTypeId"
                   (click)="onComponentTypeSelected(type.componentTypeId)"/>
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="getFieldVisibilityConfig().includes('itemQuantity')">
        <label class="form-label" for="componentAmount">{{ 'ITEMS_QUANTITY' | translate }}</label>
        <select class="form-select" id="componentAmount" [(ngModel)]="component.itemQuantity" name="itemQuantity">
          <option selected disabled>{{ 'SELECT_ITEMS_QUANTITY' | translate }}</option>
          <option *ngFor="let quantity of [4, 8, 12, 16]" [value]="quantity">
            {{ quantity }}
          </option>
        </select>
      </div>

      <div *ngIf="component.componentParentId !== 3" >
        <div class="images-container">
          <div class="img-label">{{ 'ADD_ITEMS' | translate }}</div>
          <div>
            <button class="add-img" (click)="addNewField()">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
                <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                    d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16" />
              </svg>
            </button>
          </div>
          <div  class="image-list">
            <div class="input-container">
              <div *ngFor="let info of component.componentInfo; let i = index" class="image-box">
                <div class="form-duo col-md-8">
                  <!-- video type -->
                  <div class="col-md-6 form-input form-floating" *ngIf="getFieldVisibilityConfig().includes('infoVideoUrl')">
                    <select id="videoTypeSelect-{{i}}" class="form-select"
                            [(ngModel)]="info.videoUrlType"  name="videoTypeSelect-{{i}}"
                            (change)="onVideoSourceChange(i, $event)">
                      <option value="1">{{ 'UPLOAD_FILE' | translate }}</option>
                      <option value="2">{{ 'INSERT_VIDEO_URL' | translate }}</option>
                    </select>
                    <label for="videoTypeSelect-{{i}}">{{ 'SELECT_VIDEO_SOURCE' | translate }}</label>
                  </div>

                    <div class="form-floating form-input col-md-12 mt-2" *ngIf="info.videoUrlType == 2">
                      <input type="text" class="form-control"
                            id="video-url-input-{{i}}"
                            [value]="info.infoVideoUrl || ''"
                            (input)="handleVideoManualURLChange(i, $event)"
                            placeholder="https://example.com/video.mp4">
                      <label>{{ 'PASTE_VIDEO_LINK' | translate }}</label>
                    </div>
                  <div class=" form-trio">
                    <div *ngIf="getFieldVisibilityConfig().includes('infoTitle')">
                        <label class="form-label" for="infoTitle">{{'TITLE' | translate}}</label>
                        <div class="form-input">
                            <quill-editor
                              class="info-title"
                              id="info-title-edit-{{i}}"
                              name="infoTitle-{{i}}"
                              [(ngModel)]="info.infoTitle"
                              [modules]="modules"
                              [styles]="{
                                borderRadius: '0 0 10px 10px',
                                padding: '5px',
                                marginBottom: '2vh'
                              }"
                              (onEditorChanged)="changeComponentInfoTitle($event, i)"
                              #infoTitleInput="ngModel"
                            >
                            </quill-editor>
                          </div>
                    </div>
                    <div *ngIf="getFieldVisibilityConfig().includes('infoDescription')">
                      <label class="form-label" for="infoDescription">{{'DESCRIPTION' | translate}}</label>
                        <div class="form-input">
                            <quill-editor
                              class="info-description-editor"
                              id="info-description-edit-{{i}}"
                              name="infoDescription-{{i}}"
                              [(ngModel)]="info.infoDescription"
                              [modules]="modules"
                              [styles]="{
                                borderRadius: '0 0 10px 10px',
                                padding: '5px',
                                marginBottom: '2vh'
                              }"
                              (onEditorChanged)="changeComponentInfoDescription($event, i)"
                              #infoDescriptionInput="ngModel"
                            >
                            </quill-editor>
                            <div *ngIf="remainingInfoCharacters >= 0">
                              {{'REMAINING_CHARACTERS' | translate}} {{ remainingInfoCharacters }}
                            </div>
                          </div>
                      </div>
                    <div class="form-floating form-input"*ngIf="getFieldVisibilityConfig().includes('urlName')">
                      <input type="text" class="form-control" [(ngModel)]="info.urlName" (ngModelChange)="changeComponentUrlName($event, i)" placeholder="Nombre del enlace" name="imageUrlName-{{i}}" maxlength="50">
                      <label for="component_input">{{'NAME_LINK_BUTTON' | translate}}</label>
                    </div>
                    <div class="form-input form-floating" *ngIf="getFieldVisibilityConfig().includes('redirectUrl')">
                      <input type="text" class="form-control" [(ngModel)]="info.redirectUrl" (ngModelChange)="changeComponentRedirectUrl($event, i)" placeholder="{{'LINK_URL_OPTIONAL' | translate}}" name="imageRedirectUrl-{{i}}">
                      <label for="component_input">{{'LINK_URL_OPTIONAL' | translate}}</label>
                    </div>

                  </div> 
                </div>

              <!-- Images-->
              <div class="col-md-4">
                <button type="button" class="btn btn-danger delete-button" (click)="removeField(i)">x</button>
                <ng-container *ngIf="getFieldVisibilityConfig().includes('infoImageUrl') && !info.infoVideoUrl">
                  <div *ngIf="info.infoImageUrl" class="media-container col-md-10">
                    <img *ngIf="info.infoImageUrl" [src]="info.infoImageUrl" alt="Image Preview">
                    <a class="remove-btn" (click)="removeMedia(i, 'infoImageUrl')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
                      </svg>
                    </a>
                  </div>
                  <a class="add-button" (click)="triggerFileInput(i, 'image')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20"><path fill="#5E35B1" d="M8.123 2a1.5 1.5 0 0 0-1.34.826L6.193 4h-1.69a2.5 2.5 0 0 0-2.5 2.5v8a2.5 2.5 0 0 0 2.5 2.5h3.5q.012-.171.055-.347l.375-1.498c.116-.464.335-.896.639-1.263A4.002 4.002 0 0 1 9.999 6a4 4 0 0 1 3.888 3.056l.216-.215a2.87 2.87 0 0 1 3.9-.147V6.499a2.5 2.5 0 0 0-2.5-2.5h-1.689l-.585-1.17A1.5 1.5 0 0 0 11.887 2zM13 9.945a3 3 0 1 0-3.055 3.054zm1.81-.397l-4.83 4.83a2.2 2.2 0 0 0-.577 1.02l-.375 1.498a.89.89 0 0 0 1.079 1.078l1.498-.374a2.2 2.2 0 0 0 1.02-.578l4.83-4.83a1.87 1.87 0 0 0-2.645-2.644"/></svg>
                  </a>
                  <input type="file" id="btn-upload-image-{{i}}" class="d-none"
                    (change)="onFileSelected($event, i, 'image')" accept="image/*">
                </ng-container>

              <ng-container *ngIf="info.videoUrlType == 1">
                <a class="add-button" (click)="triggerFileInput(i, 'video')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 20 20">
                    <path fill="#5E35B1" d="M13 6.5A2.5 2.5 0 0 0 10.5 4h-6A2.5 2.5 0 0 0 2 6.5v3.757a5.5 5.5 0 0 1 8.798 5.725A2.5 2.5 0 0 0 13 13.5zm1 1.43v4.152l2.764 2.35A.75.75 0 0 0 18 13.86V6.193a.75.75 0 0 0-1.23-.575zM1 14.5a4.5 4.5 0 1 0 9 0a4.5 4.5 0 0 0-9 0m6.5-3a.5.5 0 0 1 .5.5v1.5a.5.5 0 0 1-.5.5H6a.5.5 0 0 1 0-1h.468a2 2 0 0 0-.933-.25a2 2 0 0 0-1.45.586a.5.5 0 0 1-.706-.707A3 3 0 0 1 7 12.152V12a.5.5 0 0 1 .5-.5m-.876 5.532A3 3 0 0 1 4 16.848V17a.5.5 0 0 1-1 0v-1.5a.5.5 0 0 1 .5-.5H5a.5.5 0 0 1 0 1h-.468a2 2 0 0 0 .933.25a2 2 0 0 0 1.45-.586a.5.5 0 0 1 .706.707a3 3 0 0 1-.997.66"/>
                  </svg>
                </a>
                <div *ngIf="info.infoVideoUrl" class="media-container col-md-10">
                  <video [src]="info.infoVideoUrl" controls width="100%"></video>

                  <a class="remove-btn" (click)="removeMedia(i, 'infoVideoUrl')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                      <path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/>
                    </svg>
                  </a>
                </div>

                <!-- Video -->
                <input type="file" id="btn-upload-video-{{i}}" class="d-none"
                      (change)="onFileSelected($event, i, 'video')" accept="video/*">
              </ng-container>
              <div *ngIf="info.videoUrlType == 2 && info.infoVideoUrl">
                <ng-container *ngIf="info.isYouTube; else externalVideo">
                  <iframe width="100%" height="200" [src]="sanitizeVideoURL(info.infoVideoUrl)" frameborder="0" allowfullscreen></iframe>
                </ng-container>
                <ng-template #externalVideo>
                  <video width="100%" height="auto" controls>
                    <source [src]="sanitizeVideoURL(info.infoVideoUrl)" type="video/mp4">
                    <p>{{ 'VIDEO_NOT_SUPPORTED' | translate }}</p>
                  </video>
                </ng-template>
              </div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
          {{'SAVE' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal component-confirm-modal fade" id="successEditComponentModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'COMPONENT_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal component-confirm-modal fade" id="errorEditComponentModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal component-confirm-modal fade" id="errorEditMaxMinItemModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{ errorMessage }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal component-confirm-modal fade" id="errorFileSizeModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_FILE_SIZE' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
