import { Component, Input } from '@angular/core';
import { ITimekitComponent  } from '../timekit-tabs/timekit-tabs.component';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { FormBuilder, FormGroup } from '@angular/forms';

interface Availability {
  day: string;
  startTime: string;
  endTime: string;
}

interface BlockHour {
  startTime: string;
  endTime: string;
}


interface Project {
  timekitProjectId: number;
  shopId: number;
  timekitResourceId: number;
  projectId: string;
  projectName: string;
}

declare var $: any;

@Component({
  selector: 'app-timekit-schedule',
  templateUrl: './timekit-schedule.component.html',
  styleUrls: ['./timekit-schedule.component.scss']
})
export class TimekitScheduleComponent implements ITimekitComponent {
  @Input() data: any;
  public projects: Project[] = [];
  public selectedProjectId: string = '';
  public form: FormGroup;
  blockHours: BlockHour[] = [];

  availabilities: Availability[] = [
    { day: '', startTime: '09:00', endTime: '17:00' }
  ];

  daysOfWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  dayTranslation: { [key: string]: string } = {
    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo'
  };

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({ resources: [''] });
  }

  ngOnInit() {
    this.getTimekitProjects();
  }

  async getTimekitProjects() {
    try {
      const response = await this.apiService.getTimekitProjects(this.themingService.shopId) as { data: Project[] };
      this.projects = response.data || [];
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      this.projects = [];
    }
  }

  getTranslatedDay(day: string): string {
    return this.dayTranslation[day] || day;
  }

  addAvailability() {
    this.availabilities.push({ day: '', startTime: '09:00', endTime: '17:00' });
  }

  removeAvailability(index: number) {
    this.availabilities.splice(index, 1);
  }

  addBlockHour() {
    this.blockHours.push({ startTime: '', endTime: '' });
  }

  removeBlockHour(index: number) {
    this.blockHours.splice(index, 1);
  }

  async updateAvailability() {
    try {
      const availabilityConstraints = [
        ...this.availabilities.map(a => ({
          allow_day_and_time: {
            day: a.day.toLowerCase(),
            start: a.startTime,
            end: a.endTime
          }
        })),
        ...this.blockHours.map(b => ({
          block_hours: {
            start: parseInt(b.startTime.split(':')[0], 10),
            end: parseInt(b.endTime.split(':')[0], 10)
          }
        }))
      ];

      const response = await this.apiService.updateAvailability(
        this.themingService.shopId,
        this.selectedProjectId,
        availabilityConstraints
      );

      ($('#successUpdateModal') as any).modal('show');
      return response;
    } catch (error) {
      ($('#errorUpdateModal') as any).modal('show');
      throw error;
    }
  }

  onProjectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProjectId = selectElement.value;
  }

  async onSubmit() {
    try {
      const response = await this.updateAvailability();
    } catch (error) {
      console.error('Error al actualizar disponibilidad:', error);
    }
  }

  showErrorModal(): void {
    $('#successUpdateModal').modal('show');
    $('#successUpdateModal').on('hidden.bs.modal', () => {
    });
    $('#errorUpdateModal').modal('show');
    $('#errorUpdateModal').on('hidden.bs.modal', () => {
    });
  }

  closeModal() {
    $('#successUpdateModal').modal('hide');
    $('#errorUpdateModal').modal('hide');
  }
}
