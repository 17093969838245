<div class="container">
  <div class="container-frequent-questions">
    <div class="header">
      <h1 class="title">{{ "FREQUENT_QUESTIONS" | translate }}</h1>
    </div>

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
    
      <table *ngIf="!loading" mat-table [dataSource]="frequentQuestions" class="mat-elevation-z8 full-width custom-table">
        <ng-container matColumnDef="faqId">
          <th mat-header-cell *matHeaderCellDef class="custom-header-start custom-id-column">ID</th>
          <td mat-cell *matCellDef="let faq" class="custom-id-column">{{ faq.faqId }}</td>
        </ng-container>
    
        <ng-container matColumnDef="questionAnswer">
          <th mat-header-cell *matHeaderCellDef class="custom-header">{{ "QUESTION" | translate }} & {{ "ANSWER" | translate }}</th>
          <td mat-cell *matCellDef="let faq">
            <div class="question-answer-container col-md-12">
              <div class="question-container">
                <p class="form-label" *ngIf="faq.faqId !== editingFaqId">{{ "QUESTION" | translate }}:</p>
                <div *ngIf="faq.faqId === editingFaqId; else questionView">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ "QUESTION" | translate }}</mat-label>
                    <input matInput [(ngModel)]="updateFaqs.question" name="editQuestion" #editQuestion="ngModel" required />
                    <mat-error *ngIf="editQuestion.invalid && editQuestion.touched">
                      {{ "REQUIRED_QUESTION" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <ng-template #questionView>
                  <p class="custom-text-wrap">{{ faq.question }}</p>
                </ng-template>
              </div>
        
              <div class="answer-container">
                <p class="form-label" *ngIf="faq.faqId !== editingFaqId">{{ "ANSWER" | translate }}:</p>
                <div *ngIf="faq.faqId === editingFaqId; else answerView">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ "ANSWER" | translate }}</mat-label>
                    <input matInput [(ngModel)]="updateFaqs.answer" name="editAnswer" #editAnswer="ngModel" required />
                    <mat-error *ngIf="editAnswer.invalid && editAnswer.touched">
                      {{ "REQUIRED_ANSWER" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <ng-template #answerView>
                  <p class="custom-text-wrap">{{ faq.answer }}</p>
                </ng-template>
              </div>
            </div>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="custom-header-end col-md-2">{{ "ACTIONS" | translate }}</th>
          <td mat-cell *matCellDef="let faq">
            <button class="primary-btn" *ngIf="faq.faqId !== editingFaqId" (click)="startEditing(faq)">{{ "EDIT" | translate }}</button>
            <button class="primary-btn" *ngIf="faq.faqId === editingFaqId" (click)="updateFrequentQuestions()">{{ "SAVE" | translate }}</button>
            <button class="remove-btn" *ngIf="faq.faqId !== editingFaqId" (click)="openDeleteModal(faq.faqId)">{{ "DELETE" | translate }}</button>
            <button class="remove-btn" *ngIf="faq.faqId === editingFaqId" (click)="cancelEditing()">{{ "CANCEL" | translate }}</button>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="custom-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      <div class="add-question-button">
        <button class="primary-btn" (click)="toggleAddMode()">{{ "CREATE" | translate }}</button>
      </div>
    
      <div *ngIf="isAddingNewQuestion" class="add-form">
        <div class="form-group full-width">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ "QUESTION" | translate }}</mat-label>
            <input matInput [(ngModel)]="faqs.question" name="newQuestion" #newQuestion="ngModel" required />
            <mat-error *ngIf="newQuestion.invalid && newQuestion.touched">
              {{ "REQUIRED_QUESTION" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
    
        <div class="form-group full-width">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ "ANSWER" | translate }}</mat-label>
            <input matInput [(ngModel)]="faqs.answer" name="newAnswer" #newAnswer="ngModel" required />
            <mat-error *ngIf="newAnswer.invalid && newAnswer.touched">
              {{ "REQUIRED_ANSWER" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
    
        <button class="primary-btn" (click)="createFrequentQuestions()">{{ "SAVE" | translate }}</button>
        <button class="remove-btn" (click)="toggleAddMode()">{{ "CANCEL" | translate }}</button>
      </div>
    </div>
  </div>
</div>


<app-confirmation-modal *ngIf="modalDelete"
  [title]="'CONFIRM_DELETE_FAQ' | translate"
  [message]="'DELETE_FAQ' | translate"
  [confirmText]="'DELETE' | translate"
  [cancelText]="'CANCEL' | translate"
  (response)="confirmDelete($event)">
</app-confirmation-modal>


<div class="modal delete-faq-confirm-modal" id="deleteFaqModal" tabindex="-1">
  <div class="modal-dialog faq-modal-dialog" role="document">
    <div class="modal-content faq-modal-content">
      <div class="modal-body faq-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'FAQ_DELETED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal faq-confirm-modal" id="createSuccessFaqModal" tabindex="-1">
  <div class="modal-dialog faq-modal-dialog" role="document">
    <div class="modal-content faq-modal-content">
      <div class="modal-body faq-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'FAQ_CREATED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal faq-confirm-modal" id="createErrorFaqModal" tabindex="-1">
  <div class="modal-dialog faq-modal-dialog" role="document">
    <div class="modal-content faq-modal-content">
      <div class="modal-body faq-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ 'ERROR_CREATING_FAQ' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal faq-confirm-modal" id="updateSuccessFaqModal" tabindex="-1">
  <div class="modal-dialog faq-modal-dialog" role="document">
    <div class="modal-content faq-modal-content">
      <div class="modal-body faq-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'FAQ_UPDATED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal faq-confirm-modal" id="updateErrorFaqModal" tabindex="-1">
  <div class="modal-dialog faq-modal-dialog" role="document">
    <div class="modal-content faq-modal-content">
      <div class="modal-body faq-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ 'ERROR_FAQ_UPDATE' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>