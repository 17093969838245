import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  listingUsers: boolean = true;
  isEditingOwner: boolean = false;
  isCreatingUser: boolean = false;
  editingUser: string = '';

  listingCategories: boolean = true;
  isCreatingCategory: boolean = false;
  editingCategory: string = '';

  listingSubCategories: boolean = true;
  isCreatingSubCategory: boolean = false;
  editingSubCategory: string = '';

  listingSubSubCategories: boolean = true;
  isCreatingSubSubCategory: boolean = false;
  editingSubSubCategory: string = '';

  isCreatingCollection: boolean = false;
  listingCollections: boolean = true;
  editingCollection: string = '';

  listingSuppliers: boolean = true;
  isCreatingSupplier: boolean = false;
  editingSupplier: string = '';

  listingShopPoints: boolean = true;
  isCreatingShopPoint: boolean = false;
  editingShopPoint: string = '';

  listingPaymentMethods: boolean = true;
  isCreatingPaymentMethod: boolean = false;
  editingPaymentMethod: string = '';

  listingGiftCardTypes: boolean = true;
  isCreatingGiftCardType: boolean = false;
  editingGiftCardType: string = '';

  listingGiftCardItems: boolean = true;
  isCreatingGiftCardItem: boolean = false;
  editingGiftCardItem: string = '';

  listingResources: boolean = true;
  isCreatingResources: boolean = false;

  listingProjects: boolean = true;
  isCreatingProjects: boolean = false;

  listingFAQs: boolean = true;

  constructor() { }

  /**
   * This function sets the listingUsers boolean to true, and sets the other booleans to false.
   */
  setListingUsers(): void {
    this.listingUsers = true;
    this.isEditingOwner = false;
    this.isCreatingUser = false;
    this.editingUser = '';
  }

  /**
   * This function returns a boolean value that is true if the listingUsers variable is true, and false
   * if the listingUsers variable is false.
   * @returns The value of the variable listingUsers.
   */
  getListingUsers(): boolean {
    return this.listingUsers;
  }

  /**
   * "This function sets the value of the isEditingOwner variable to true, and sets the values of the
   * other variables to false."
   * </code>
   */
  setEditingOwner(): void{
    this.isEditingOwner = true;
    this.listingUsers = false;
    this.isCreatingUser = false;
    this.editingUser = '';
  }

  /**
   * This function returns a boolean value that indicates whether the owner is being edited.
   * @returns The isEditingOwner property.
   */
  getEditingOwner(): boolean {
    return this.isEditingOwner;
  }

  /**
   * This function sets the value of the isCreatingUser variable to true, and sets the values of the
   * other variables to false.
   */
  setCreatingUser(): void{
    this.isCreatingUser = true;
    this.isEditingOwner = false;
    this.listingUsers = false;
    this.editingUser = '';
  }

  /**
   * "This function returns a boolean value that is true if the user is creating a new user, and false
   * if the user is not creating a new user."
   * </code>
   * @returns A boolean value.
   */
  getCreatingUser(): boolean {
    return this.isCreatingUser;
  }

  /**
   * This function sets the editingUser variable to the username passed in, and sets the other
   * variables to false.
   * @param {string} username - string - the username of the user to be edited
   */
  setEditingUser(username: string): void{
    this.editingUser = username;
    this.listingUsers = false;
    this.isEditingOwner = false;
    this.isCreatingUser = false;
  }

  /**
   * If the editingUser property is not an empty string, then return true, otherwise return false.
   * @returns The return value is a boolean.
   */
  isEditingUser(): boolean {
    return this.editingUser !== '';
  }

  /**
   * It returns the value of the editingUser property.
   * @returns The value of the editingUser property.
   */
  getEditingUser(): string {
    return this.editingUser;
  }

  /**
   * This function sets the listingCategories boolean to true, and sets the other booleans to false.
   */
  setListingCategories(): void {
    this.listingCategories = true;
    this.isCreatingCategory = false;
    this.editingCategory = '';
  }

  setListingSubCategories(): void {
    this.listingSubCategories = true;
    this.isCreatingSubCategory = false;
    this.editingSubCategory = '';
  }

  setListingSubSubCategories(): void {
    this.listingSubSubCategories = true;
    this.isCreatingSubSubCategory = false;
    this.editingSubSubCategory = '';
  }

    /**
   * This function returns a boolean value that is true if the listingCategories variable is true, and false
   * if the listingCategories variable is false.
   * @returns The value of the variable listingCategories.
   */
    getListingCategories(): boolean {
      return this.listingCategories;
    }

    getListingSubCategories(): boolean {
      return this.listingSubCategories;
    }

    getListingSubSubCategories(): boolean {
      return this.listingSubSubCategories;
    }

    /**
   * This function sets the value of the isCreatingCategory variable to true, and sets the values of the
   * other variables to false.
   */
    setCreatingCategory(): void{
      this.isCreatingCategory = true;
      this.listingCategories = false;
      this.editingCategory = '';
    }

    setCreatingSubCategory(): void{
      this.isCreatingSubCategory = true;
      this.listingSubCategories = false;
      this.editingSubCategory = '';
    }

    setCreatingSubSubCategory(): void{
      this.isCreatingSubSubCategory = true;
      this.listingSubSubCategories = false;
      this.editingSubSubCategory = '';
    }

    /**
     * "This function returns a boolean value that is true if the user is creating a new category, and false
     * if the user is not creating a new category."
     * </code>
     * @returns A boolean value.
     */
    getCreatingCategory(): boolean {
      return this.isCreatingCategory;
    }

    getCreatingSubCategory(): boolean {
      return this.isCreatingSubCategory;
    }

    getCreatingSubSubCategory(): boolean {
      return this.isCreatingSubSubCategory;
    }

    /**
   * This function sets the editingCategory variable to the categoryId passed in, and sets the other
   * variables to false.
   * @param {string} categoryId - string - the Id of the category to be edited
   */
  setEditingCategory(categoryId: string): void{
    this.editingCategory = categoryId;
    this.listingCategories = false;
    this.isCreatingCategory = false;
  }

  setEditingSubCategory(subCategoryId: string): void{
    this.editingSubCategory = subCategoryId;
    this.listingSubCategories = false;
    this.isCreatingSubCategory = false;
  }

  setEditingSubSubCategory(subSubCategoryId: string): void{
    this.editingSubSubCategory = subSubCategoryId;
    this.listingSubSubCategories = false;
    this.isCreatingSubSubCategory = false;
  }
  /**
   * If the editingCategory property is not an empty string, then return true, otherwise return false.
   * @returns The return value is a boolean.
   */
  isEditingCategory(): boolean {
    return this.editingCategory !== '';
  }

  isEditingSubCategory(): boolean {
    return this.editingSubCategory !== '';
  }

  isEditingSubSubCategory(): boolean {
    return this.editingSubSubCategory !== '';
  }
  /**
   * It returns the value of the editingCategory property.
   * @returns The value of the editingCategory property.
   */
  getEditingCategory(): string {
    return this.editingCategory;
  }

  getEditingSubCategory(): string {
    return this.editingSubCategory;
  }

  getEditingSubSubCategory(): string {
    return this.editingSubSubCategory;
  }

  /* COLLECTIONS */
  setListingCollections(): void {
    this.listingCollections = true;
    this.isCreatingCollection = false;
    this.editingCollection = '';
  }

  getListingCollections(): boolean {
    return this.listingCollections;
  }

  setCreatingCollection(): void{
    this.isCreatingCollection = true;
    this.listingCollections = false;
    this.editingCollection = '';
  }

  getCreatingCollection(): boolean {
    return this.isCreatingCollection;
  }

  setEditingCollection(collectionId: string): void{
    this.editingCollection = collectionId;
    this.listingCollections = false;
    this.isCreatingCollection = false;
  }

  isEditingCollection(): boolean {
    return this.editingCollection !== '';
  }

  getEditingCollection(): string {
    return this.editingCollection;
  }

  /* SUPPLIERS  */
  setListingSuppliers(): void {
    this.listingSuppliers = true;
    this.isCreatingSupplier = false;
    this.editingSupplier = '';
  }

  getListingSuppliers(): boolean {
    return this.listingSuppliers;
  }

  setCreatingSupplier(): void{
    this.isCreatingSupplier = true;
    this.listingSuppliers = false;
    this.editingSupplier = '';
  }

  getCreatingSupplier(): boolean {
    return this.isCreatingSupplier;
  }

  setEditingSupplier(supplierId: string): void{
    this.editingSupplier = supplierId;
    this.listingSuppliers = false;
    this.isCreatingSupplier = false;
  }

  isEditingSupplier(): boolean {
    return this.editingSupplier !== '';
  }

  getEditingSupplier(): string {
    return this.editingSupplier;
  }

  // SHOPPOINTS

  getCreatingShopPoint(): boolean {
    return this.isCreatingShopPoint;
  }

  getEditingShopPoint(): string {
    return this.editingShopPoint;
  }

  getListingShopPoints(): boolean {
    return this.listingShopPoints;
  }

  setEditingShopPoint(shopPointId: string){
    this.editingShopPoint = shopPointId;
    this.listingShopPoints = false;
    this.isCreatingShopPoint = false;
  }

  setCreatingShopPoint(){
    this.isCreatingShopPoint = true;
    this.listingShopPoints = false;
    this.editingShopPoint = '';
  }

  setListingShopPoints(): void {
    this.listingShopPoints = true;
    this.isCreatingShopPoint = false;
    this.editingShopPoint = '';
  }

  isEditingShopPoint(): boolean {
    return this.editingShopPoint !== '';
  }

  // Payment Methods

  getEditingPaymentMethod(): string {
    return this.editingPaymentMethod;
  }

  getListingPaymentMethods(): boolean {
    return this.listingPaymentMethods;
  }

  getListingFAQs(): boolean {
    return this.listingFAQs;
  }

  getCreatingPaymentMethod(): boolean {
    return this.isCreatingPaymentMethod;
  }

  setEditingPaymentMethod(paymentMethodId: string){
    this.isCreatingPaymentMethod = false;
    this.editingPaymentMethod = paymentMethodId;
    this.listingPaymentMethods = false;
  }

  setListingPaymentMethods(): void {
    this.isCreatingPaymentMethod = false;
    this.listingPaymentMethods = true;
    this.editingPaymentMethod = '';
  }

  setCreatingPaymentMethod(): void {
    this.isCreatingPaymentMethod = true;
    this.listingPaymentMethods = false;
    this.editingPaymentMethod = '';
  }

  isEditingPaymentMethod(): boolean {
    return this.editingPaymentMethod !== '';
  }

  // GIFT CARDS
  setListingGiftCardTypes(): void {
    this.listingGiftCardTypes = true;
    this.isCreatingGiftCardType = false;
    this.editingGiftCardType = '';
  }

  getListingGiftCardTypes(): boolean {
    return this.listingGiftCardTypes;
  }
  setCreatingGiftCardType(): void{
    this.isCreatingGiftCardType = true;
    this.listingGiftCardTypes = false;
    this.editingGiftCardType = '';
  }

  getEditingGiftCardType(): string {
    return this.editingGiftCardType;
  }

  setEditingGiftCardType(giftCardTypeId: string): void{
    this.editingGiftCardType = giftCardTypeId;
    this.listingGiftCardTypes = false;
    this.isCreatingGiftCardType = false;
  }
  getGiftCardType(): boolean {
    return this.isCreatingGiftCardType;
  }

  setCreatingGiftCardItem(): void{
    this.isCreatingGiftCardItem = true;
    this.listingGiftCardItems = false;
    this.editingGiftCardItem = '';
  }

  setEditingGiftCardItem(giftCardTypeId: string): void{
    this.editingGiftCardItem = giftCardTypeId;
    this.listingGiftCardItems = false;
    this.isCreatingGiftCardItem = false;
  }

  // TIMEKIT

  getListingResources(): boolean {
    return this.listingResources;
  }

  getCreateResources(): boolean {
    return this.isCreatingResources;
  }

  getListingProjects(): boolean {
    return this.listingProjects;
  }

  getCreateProjects(): boolean {
    return this.isCreatingProjects;
  }

  setListingResources(): void {
    this.listingResources = true;
    this.isCreatingResources = false;
  }

  setCreatingResources(): void {
    this.isCreatingResources = true;
    this.listingResources = false;
  }

  setListingProjects(): void {
    this.listingProjects = true;
    this.isCreatingProjects = false;
  }

  setCreatingProjects(): void {
    this.isCreatingProjects = true;
    this.listingProjects = false;
  }

}
