<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-clients">
    <div class="header">
      <h1 class="title">{{'YOUR_CLIENTS' | translate}}</h1>
      <div class="buttons-container">
        <div class="dropdown" *ngIf="permissions?.exportClients">
          <a class="btn primary-btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            {{'DOWNLOAD_FILE' | translate}}
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li><a class="dropdown-item" (click)="downloadClientsFile('excel')">📊 {{'EXPORT_TO' | translate}} Excel</a></li>
            <li><a class="dropdown-item" (click)="downloadClientsFile('json')">📜 {{'EXPORT_TO' | translate}} JSON</a></li>
            <li><a class="dropdown-item" (click)="downloadClientsFile('csv')">📄 {{'EXPORT_TO' | translate}} CSV</a></li>
            <li><a class="dropdown-item" (click)="downloadClientsFile('txt')">📑 {{'EXPORT_TO' | translate}} TXT</a></li>
          </ul>
        </div>
        <button type="button" class="primary-btn" [routerLink]="['/clients/create']">
          {{'NEW' | translate}}
        </button>
      </div>
    </div>
    <div class="form-card">
      <div class="search-container">
        <input
          type="text"
          placeholder="{{'SEARCH' | translate}}"
          [(ngModel)]="searchString"
          (keyup)="serchClients()"
        />
        <div class="filters-container">
          <div class="">
            <select class="form-select" [(ngModel)]="order" (change)="filterSearch()">
              <option value="DESC">{{'MOST_RECENT' | translate}}</option>
              <option value="ASC">{{'OLDER' | translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">{{'NAME' | translate}}</th>
          <!-- <th scope="col">Etiquetas</th> -->
          <th scope="col">{{'EMAIL' | translate}}</th>
          <th scope="col">{{'PHONE' | translate}}</th>
          <th scope="col">{{'EDIT' | translate}}</th>
          <th scope="col" class="table-end">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let client of clients">
            <td class="client-name" *ngIf="client.username != ''">
              <a [routerLink]="['/client', client.clientId]" routerLinkActive="active">{{ client.firstName+' '+client.lastName }}</a>
            </td>
            <td class="client-name" *ngIf="!client.username">
              <a (click)="notVerified()">{{ client.firstName+' '+client.lastName }}</a>
            </td>
<!--             <td><mat-form-field class="example-chip-list" appearance="fill">
              <mat-label>Etiquetas</mat-label>
              <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip
                  *ngFor="let fruit of fruits"
                  (removed)="remove(fruit)">
                  {{fruit}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <input
                  placeholder="Nueva Etiqueta..."
                  #fruitInput
                  [formControl]="fruitCtrl"
                  [matAutocomplete]="auto"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)">
              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                  {{fruit}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            </td> -->
            <td>{{ client.email }}</td>
            <td>{{ client.phone }}</td>
            <td>
              <button type="button" class="primary-btn" [routerLink]="['/edit-clients', client.clientId]">
                {{'EDIT' | translate}}
              </button>
            </td>
            <td>
              <button class="btn btn-primary remove-btn" (click)="modalDelete = true; clientIdToDelete = client.clientId; clientUsernameToDelete = client.username">
                {{'DELETE' | translate}}
              </button>
              </td>
          </tr>
        </tbody>
      </table>
      <div class="nav-container" *ngIf="!loading">
        <nav>
          <ul class="pagination">
            <li [ngClass]="{ 'page-item': true, disabled: currentPage == 1 }">
              <a
                class="page-link page-text-link"
                (click)="movePage(false)"
                tabindex="-1"
                unselectable="on"
                >{{'PREVIOUS' | translate}}</a
              >
            </li>
            <li
              *ngIf="pages[0] >= (maxNumberOfPages - 1) / 2"
              class="page-item disabled"
            >
              <a
                class="page-link page-number-link"
                unselectable="on"
                style="opacity: 1"
                >...</a
              >
            </li>
            <li
              *ngFor="let page of pages"
              [ngClass]="{ 'page-item': true, active: currentPage == page }"
            >
              <a
                class="page-link page-number-link"
                (click)="goToPage(page)"
                unselectable="on"
                >{{ page }}</a
              >
            </li>
            <li
              *ngIf="
                currentPage + (maxNumberOfPages - 1) / 2 < totalPages &&
                totalPages > maxNumberOfPages
              "
              class="page-item disabled"
            >
              <a
                class="page-link page-number-link"
                unselectable="on"
                style="opacity: 1"
                >...</a
              >
            </li>
            <li
              [ngClass]="{
                'page-item': true,
                disabled: currentPage == totalPages
              }"
            >
              <a
                class="page-link page-text-link"
                (click)="movePage(true)"
                tabindex="-1"
                unselectable="on"
              >
                {{'NEXT' | translate}}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="errorClientsModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{ 'USER_NOT_VERIFIED' | translate }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger cancel-btn" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<app-confirmation-modal
*ngIf="modalDelete"
[title]="'¿Seguro que quiere eliminar este cliente?'"
[message]="'Al eliminar este usuario se eliminarán todos los datos que estén relacionados a él permanentemente'"
[confirmText]="'Eliminar'"
[cancelText]="'Cancelar'"
(response)="deleteClient($event)">
</app-confirmation-modal>

<div class="modal delete-client-confirm-modal"  id="deleteClientModal" tabindex="-1">
  <div class="modal-dialog client-modal-dialog" role="document">
    <div class="modal-content client-modal-content">
      <div class="modal-body client-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'CLIENT_DELETED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
