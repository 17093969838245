import { Component, ComponentFactoryResolver, Injector, AfterViewInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { TimekitRescheduleComponent } from '../timekit-reschedule/timekit-reschedule.component';
import { TimekitBookingsComponent } from '../timekit-bookings/timekit-bookings.component';
import { TimekitScheduleComponent } from '../timekit-schedule/timekit-schedule.component';
import { TimekitOptionsComponent } from '../timekit-options/timekit-options.component';
import { TimekitSessionsComponent } from '../timekit-sessions/timekit-sessions.component';
import { TranslateService } from '@ngx-translate/core';

export interface ITimekitComponent {
  data: any;
}

@Component({
  selector: 'app-timekit-tabs',
  templateUrl: './timekit-tabs.component.html',
  styleUrls: ['./timekit-tabs.component.scss'],
})
export class TimekitTabsComponent implements AfterViewInit {
  @ViewChild('tabContent', { read: ViewContainerRef }) container!: ViewContainerRef;

  activeTab: number = 0;

  tabs: { title: string; component: Type<ITimekitComponent>; data: any }[] = [
    { title: '', component: TimekitScheduleComponent, data: '' },
    { title: '', component: TimekitBookingsComponent, data: '' },
    { title: '', component: TimekitRescheduleComponent, data: '' },
    { title: '', component: TimekitOptionsComponent, data: '' },
    { title: '', component: TimekitSessionsComponent, data: '' }
  ];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.stream([
      'SCHEDULE',
      'APPOINTMENT',
      'APPOINTMENT_SCHEDULED',
      'CONFIGURATION',
      'SESSIONS'
    ]).subscribe(translations => {
      this.tabs = [
        { title: translations['SCHEDULE'], component: TimekitScheduleComponent, data: '' },
        { title: translations['APPOINTMENT'], component: TimekitBookingsComponent, data: '' },
        { title: translations['APPOINTMENT_SCHEDULED'], component: TimekitRescheduleComponent, data: '' },
        { title: translations['CONFIGURATION'], component: TimekitOptionsComponent, data: '' },
        { title: translations['SESSIONS'], component: TimekitSessionsComponent, data: '' }
      ];
    });
  }


  ngAfterViewInit(): void {
    this.loadComponent();
  }

  setActiveTab(index: number): void {
    this.activeTab = index;
    this.loadComponent();
  }

  private loadComponent(): void {
    const tab = this.tabs[this.activeTab];
    this.container.clear();

    const factory = this.componentFactoryResolver.resolveComponentFactory(tab.component);
    const componentRef = this.container.createComponent(factory, 0, this.injector);

    // Asigna datos al componente (si el componente los recibe a través de una propiedad)
    (componentRef.instance as ITimekitComponent).data = tab.data;
  }
}
