<div class="container">
  <div class="container-clients">
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">{{'NAME' | translate}}</th>
          <th scope="col">{{'EMAIL' | translate}}</th>
          <!-- <th scope="col">Sesiones Totales</th> -->
          <th scope="col">Sesiones Disponibles</th>
          <th scope="col" class="table-end">Sesiones Agendadas</th>
        </thead>
        <tbody>
          <tr *ngFor="let client of clients">
            <td class="client-name">
              <a>{{ client.firstName+' '+client.lastName }}</a>
            </td>
            <td>{{ client.email }}</td>
            <!-- <td>{{ client.totalSessions }}</td> -->
            <td>{{ client.remainingSessions }}</td>
            <td>{{ client.scheduledSessions }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
