import { Component, Input, OnInit } from '@angular/core';
import { ITimekitComponent } from '../timekit-tabs/timekit-tabs.component';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { Debounce } from 'angular-debounce-throttle';
import { LambdaService } from 'src/app/lambda.service';

@Component({
  selector: 'app-timekit-sessions',
  templateUrl: './timekit-sessions.component.html',
  styleUrls: ['./timekit-sessions.component.scss']
})
export class TimekitSessionsComponent implements ITimekitComponent {
  @Input() data: any;
  clients: any[] = [];
  totalAmount: number = 0;
  order: string = 'DESC';
  loading: boolean = false;

  constructor(
    private lambdaService : LambdaService,
    private themingService: ThemingService
  ) { }

  ngOnInit(): void {
    this.getClientSessions();
  }

  async getClientSessions() {
    this.loading = true;

    try {
      const rawData: any = await this.lambdaService.getClientSessions(this.themingService.shopId);

      if (Array.isArray(rawData)) {
        const clientsArray = rawData.find((entry: any) => Array.isArray(entry));
        this.clients = clientsArray || [];
      } else {
        this.clients = [];
      }

    } catch (error) {
      console.error('Error al obtener clientes:', error);
      this.clients = [];
    }

    this.loading = false;
  }
}
