import { Component, ElementRef, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-create-sub-subcategory',
  templateUrl: './create-sub-subcategory.component.html',
  styleUrls: ['./create-sub-subcategory.component.scss']
})
export class CreateSubSubcategoryComponent implements OnInit {
  @Output() subSubCategoryCreated = new EventEmitter<void>();
  @Input() categoryId!: string;

  subSubCategory = {
    subCategoryId: 0,
    subSubCategoryName: '',
    subSubCategoryPosition: 0,
    menuSubSubCategory: 0,
    homeSubSubCategory: 0,
    subSubCategoryDesignType: 1,
    subSubCategoryImageUrl: '',
    isForVerifiedClient: 0,
    timekitProjectId: 0,    
  }

  category: any = {timekitResourceId: 0};

  timekitProjects:  any[] = [];

  saveSubSubCategory = false;
  subSubCategories: any[] = [];
  subCategory: any;
  subCategories: any[] = [];
  imageUrl: SafeUrl | null = null;
  categories: any;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.saveSubSubCategory = false;
    this.getSubCategoryByCategoryId(parseInt(this.categoryId));
    this.getCategories();
  }

  getSubCategoryByCategoryId(categoryId: number) {
    this.apiService
      .getSubCategoryByCategoryId( categoryId,this.themingService.shopId, )
      .then((data: any) => {
        this.subCategories = data.subCategories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
  
      });
  }

  getCategories() {
    this.apiService
      .getCategories(
        this.themingService.shopId
      ).then((data: any) => {
        this.categories = data.categories;
        this.timekitProjects = data.timekitProjects;
      }).catch((error) => {
        console.error(error);
      });
  }

  selectedProject: { projectId: number, resourceId: number } = { projectId: 0, resourceId: 0 };

  onProjectChange(selectedValue: any) {
    this.subSubCategory.timekitProjectId = selectedValue.projectId;
    this.category.timekitResourceId = selectedValue.resourceId;
  
    console.log('Proyecto seleccionado:', this.subSubCategory.timekitProjectId);
    console.log('Recurso asociado:', this.category.timekitResourceId);
  }
  

  triggerFileInput() {
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.click();
  }

  private resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, width, height);

    return canvas;
  }

  private convertCanvasToFile(canvas: HTMLCanvasElement, fileName: string): File {
    const dataURL = canvas.toDataURL('image/jpeg');
    const base64Data = dataURL.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    return new File([blob], fileName);
  }

  async onImageChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(image.src);

        image.onload = async () => {
          const resizedImage = this.resizeImage(image, 1400, 250);
          const resizedFile = this.convertCanvasToFile(resizedImage, file.name);

          try {
            const imageData = await this.uploadImage(resizedFile);
            this.subSubCategory.subSubCategoryImageUrl = imageData.imageURL; 
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    this.imageUrl = null;
    this.subSubCategory.subSubCategoryImageUrl = ''; 
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.value = ''; 
  }

  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        const timestamp = Date.now();
        const ext = image.name.split('.').pop();
        const key = `${this.themingService.shopId}/${environment.stage}/images/subsubcategory/banner${timestamp}.${ext}`;
        return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
          .then((data: any) => {
            return {
              imageURL: data.imageURL,
              blob: res.blob,
            };
          });
      });
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  changeSubSubCategoryName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.subSubCategory.subSubCategoryName = element.value;
  }

  changeSubCategoryId(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.subSubCategory.subCategoryId = parseInt(element.value);
  }

  validateFormData() {
    if (this.subSubCategory.subCategoryId === 0 || !this.subSubCategory.subCategoryId || !this.subSubCategory.subSubCategoryName) {
      $('#requiredSubSubCategoryModal').modal('show');
      return;
    } else {
      this.createSubSubCategory();
    }
  }
  
  createSubSubCategory() {
    this.subSubCategory.isForVerifiedClient = this.subSubCategory.isForVerifiedClient ? 1 : 0;
    $('#subSubCategoryLoadingModal').modal({ backdrop: 'static', keyboard: false });
    $('#subSubCategoryLoadingModal').modal('show');
    this.saveSubSubCategory = false;
    this.apiService.createSubSubCategory(
      this.themingService.shopId,
      this.subSubCategory.subSubCategoryName,
      this.subSubCategory.subSubCategoryPosition,
      this.subSubCategory.menuSubSubCategory,
      this.subSubCategory.homeSubSubCategory,
      this.subSubCategory.subCategoryId,
      this.subSubCategory.subSubCategoryDesignType,
      this.subSubCategory.subSubCategoryImageUrl,
      this.subSubCategory.isForVerifiedClient,
    ).then((data: any) => {
      if (data.status === 200) {
        setTimeout(() => {
          this.subSubCategoryCreated.emit();
        }, 2000); 
        $('#subSubCategoryLoadingModal').modal('hide');
        $('#successEditSubSubModal').modal('show');
        this.saveSubSubCategory = true;
        this.resetForm();
      } else {
        $('#errorEditSubSubModal').modal('show');
        $('#subSubCategoryLoadingModal').modal('hide');
      }
    }).catch((error) => {
      console.error(error);
    });
  }

  @ViewChild('subSubCategoryName')
  subSubCategoryName!: ElementRef;
  @ViewChild('subSubCategoryPositive')
  subSubCategoryPositive!: ElementRef;
  @ViewChild('subSubcategoryParentSubCategory')
  subSubcategoryParentSubCategory!: ElementRef;

  resetForm() {
    this.subSubCategoryName.nativeElement.value = '';
    this.subSubCategoryPositive.nativeElement.value = '0';
    this.subSubcategoryParentSubCategory.nativeElement.value = '0';
    this.subSubCategory.subSubCategoryName = '';
  }

  closeModal() {
    $('#successEditSubSubModal').modal('hide');
    $('#errorEditSubSubModal').modal('hide');    
    $('#requiredSubSubCategoryModal').modal('hide');
  }

  setListingSubSubCategories() {
    this.settingsService.setListingSubSubCategories();
  }
  preventNegativeNumbers(event: KeyboardEvent) {
    if (event.key === '-' || event.key === '+') {
      event.preventDefault(); 
    }
  }
}

