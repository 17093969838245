import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { AppComponent } from 'src/app/app.component';
declare var $: any;

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss']
})
export class EditMenuComponent implements OnInit {
  @Input() menuId!: number;
  @Output() close = new EventEmitter<void>();

  menu: any = {
    menuName: '',
    menuStatus: 0
  };
  menuOptions: any[] = [];
  categories: any[] = [];
  collections: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];
  components: any[] = [];
  pages: any[] = [];
  loading: boolean = false;
  deletedMenuOptionIds: number[] = []; 

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private themingService: ThemingService,
    public appComponent: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this.route.paramMap.subscribe((params) => {
      let menuId = Number(params.get('menuId'));
      if (menuId !== this.menuId) {
        this.appComponent.goToTop();
        this.menuId = menuId;
      }
    });
  }

  ngOnInit(): void {
      this.cdr.detectChanges();
      this.getCategories(),
      this.getCollections(),
      this.getComponents(),
      this.getSubCategories(),
      this.getSubSubCategories(),
      this.getPages(),
      this.getMenuById(this.menuId);
  }

  async getMenuById(id: number): Promise<void> {
    if (id === 0) return; 
    this.loading = true;
    try {
      const data: any = await this.apiService.getMenuById(id, this.themingService.shopId);
      if (data.menu && data.menu.length > 0) {
        this.menu = data.menu[0];
      }
      this.menuOptions = data.menu_options.map((option: any) => ({
        optionType: this.getOptionType(option),
        optionName: option.menuOptionName,
        optionId: option.menuOptionId,
        categoryId: option.categoryId,
        subCategoryId: option.subCategoryId,
        subSubCategoryId: option.subSubCategoryId,
        collectionId: option.collectionId,
        optionUrl: option.menuOptionUrl,
        position: option.menuOptionPosition,
        componentId: option.componentId,
        pageId: option.pageId
      }));   
      this.menuOptions.sort((a, b) => a.position - b.position);
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  async getPages() {
    try {
      const response: any = await this.apiService.getPages(this.themingService.shopId);
      this.pages = response.data
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async getComponents() {
    try {
      const response: any = await this.apiService.getComponents(this.themingService.shopId);
      this.components = response.data;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async getCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getCategories(this.themingService.shopId);
      this.categories = data.categories;
    } catch (error) {
      console.error(error);
    }
  }

  async getCollections(): Promise<void> {
    try {
      const data: any = await this.apiService.getCollections(this.themingService.shopId);
      this.collections = data.collections;
    } catch (error) {
      console.error(error);
    }
  }

  async getSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubCategories(this.themingService.shopId);
      this.subCategories = data.subCategories;
    } catch (error) {
      console.error(error);
    }
  }

  async getSubSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubSubCategories(this.themingService.shopId);
      this.subSubCategories = data.subSubCategories;
    } catch (error) {
      console.error(error);
    }
  }

  getOptionType(option: any): string {
    if (option.categoryId) return 'categories';
    if (option.subCategoryId) return 'subCategories';
    if (option.subSubCategoryId) return 'subSubcategories';
    if (option.collectionId) return 'collections';
    if (option.componentId) return 'components';
    if (option.pageId) return 'pages';
    return 'custom';
  }

  addMenuOption() {
    this.menuOptions.push({
      optionType: '',
      optionName: '',
      optionId: 0,
      categoryId: 0,
      subCategoryId: 0,
      subSubCategoryId: 0,
      collectionId: 0,
      optionUrl: '',
      position: this.menuOptions.length + 1,
      componentId: 0,
      pageId: 0
    });
  }

  removeMenuOption(index: number) {
    const optionId = this.menuOptions[index].optionId;
    if (optionId !== 0) { 
      this.deletedMenuOptionIds.push(optionId);
    }
    this.menuOptions.splice(index, 1);
    this.menuOptions.forEach((option, i) => {
      option.position = i + 1;
    });
  }

  onOptionChange(option: string, index: number) {
    this.menuOptions[index].categoryId = 0;
    this.menuOptions[index].subCategoryId = 0;
    this.menuOptions[index].subSubCategoryId = 0;
    this.menuOptions[index].collectionId = 0;
    this.menuOptions[index].optionUrl = '';
    this.menuOptions[index].componentId = 0;
    this.menuOptions[index].pageId = 0;

    switch (option) {
      case 'categories':
        this.menuOptions[index].optionType = 'categories';
        break;
      case 'subCategories':
        this.menuOptions[index].optionType = 'subCategories';
        break;
      case 'subSubcategories':
        this.menuOptions[index].optionType = 'subSubcategories';
        break;
      case 'collections':
        this.menuOptions[index].optionType = 'collections';
        break;
      case 'custom':
        this.menuOptions[index].optionType = 'custom';
        break;
      case 'components':
        this.menuOptions[index].optionType = 'components';
        break;
      case 'pages':
        this.menuOptions[index].optionType = 'pages';
        break;
    }
  }

  ChangeMenuName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.menu.menuName = element.value;
  }

  onCategoryChange(event: any, index: number) {
    const selectedCategory = this.categories.find(category => category.categoryId === +event.target.value);
    if (selectedCategory) {
      this.menuOptions[index].categoryId = selectedCategory.categoryId;
    }
  }

  onSubCategoryChange(event: any, index: number) {
    const selectedSubCategory = this.subCategories.find(subCategory => subCategory.subCategoryId === +event.target.value);
    if (selectedSubCategory) {
      this.menuOptions[index].subCategoryId = selectedSubCategory.subCategoryId;
    }
  }

  onSubSubCategoryChange(event: any, index: number) {
    const selectedSubSubCategory = this.subSubCategories.find(subSubCategory => subSubCategory.subSubCategoryId === +event.target.value);
    if (selectedSubSubCategory) {
      this.menuOptions[index].subSubCategoryId = selectedSubSubCategory.subSubCategoryId;
    }
  }

  onCollectionChange(event: any, index: number) {
    const selectedCollection = this.collections.find(collection => collection.collectionId === +event.target.value);
    if (selectedCollection) {
      this.menuOptions[index].collectionId = selectedCollection.collectionId;
    }
  }

  onComponentChange(event: any, index: number) {
    const selectedComponent = this.components.find(component => component.componentId === +event.target.value);
    if (selectedComponent) {
      this.menuOptions[index].componentId = selectedComponent.componentId;
      if (selectedComponent.categoryId) {
        this.menuOptions[index].categoryId = selectedComponent.categoryId;
      }
      if (selectedComponent.subCategoryId) {
        this.menuOptions[index].subCategoryId = selectedComponent.subCategoryId;
      }
      if (selectedComponent.subSubCategoryId) {
        this.menuOptions[index].subSubCategoryId = selectedComponent.subSubCategoryId;
      }
      if (selectedComponent.collectionId) {
        this.menuOptions[index].collectionId = selectedComponent.collectionId;
      }
    }
  }

  onPageChange(event: any, index: number) {
    const selectedPage = this.pages.find(page => page.pageId === +event.target.value);
    if (selectedPage) {
      this.menuOptions[index].pageId = selectedPage.pageId;
    }
  }

  onUrlChange(event: any, index: number) {
    this.menuOptions[index].optionUrl = event.target.value;
  }

  validateFormData() {
    if (!this.menu.menuName || this.menu.menuName.trim() === '') {
      $('#requiredEditMenuModal').modal('show');
      return;
    }
  
    for (const option of this.menuOptions) {
      if (!option.optionName || option.optionName.trim() === '') {
        $('#requiredEditMenuModal').modal('show');
        return;
      }
      switch (option.optionType) {
        case 'categories':
          if (option.categoryId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
        case 'subCategories':
          if (option.subCategoryId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
        case 'subSubcategories':
          if (option.subSubCategoryId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
        case 'collections':
          if (option.collectionId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
        case 'custom':
          if (!option.optionUrl || option.optionUrl.trim() === '') {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
          case 'components':
          if (option.componentId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
        case 'pages':
          if (option.pageId === 0) {
            $('#requiredEditMenuModal').modal('show');
            return;
          }
          break;
      }
    }
  
    this.updateMenu();
  }

  updateMenu() {
    this.loading = true;
    const menuOptionsToSend = this.menuOptions.map(option => ({
      menuId: this.menu.menuId,
      menuOptionId: option.optionId,
      menuOptionPosition: option.position,
      menuOptionName: option.optionName,
      menuOptionUrl: option.optionUrl,
      categoryId: option.categoryId,
      subCategoryId: option.subCategoryId,
      subSubCategoryId: option.subSubCategoryId,
      collectionId: option.collectionId,
      menuOptionType: this.getNumericOptionType(option.optionType),
      componentId: option.componentId || 0,
      pageId: option.pageId || 0
    }));

    this.apiService.updateMenu(
      this.themingService.shopId,
      this.menu.menuId,
      this.menu.menuName,
      this.menu.menuStatus,
      menuOptionsToSend,
      this.deletedMenuOptionIds
    ).then((data: any) => {
      if (data.status === 200) {
        this.loading = false;
        $('#successMenuModal').modal('show');
        this.getMenuById(this.menu.menuId)
      } else {
        $('#errorMenuModal').modal('show');
      }
    }).catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }

  getNumericOptionType(optionType: string): number {
    switch (optionType) {
      case 'categories':
        return 1;
      case 'subCategories':
        return 2;
      case 'subSubcategories':
        return 3;
      case 'collections':
        return 4;
      case 'custom':
        return 5;
      case 'components':
        return 6;
      case 'pages':
        return 7;
      default:
        return 0; 
    }
  }

  closeModal(): void {
    $('#successMenuModal').modal('hide');
    $('#errorMenuModal').modal('hide');
    $('#requiredEditMenuModal').modal('hide');
  }

  closeEdit(): void {
    this.close.emit();
  }
}
