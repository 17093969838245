<div class="container">
  <div class="container-pages" *ngIf="!showComponentsView">
    <div class="header">
      <h1 class="title">{{"PAGES" | translate}}</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="toggleCreatePage()">
        {{'NEW' | translate}}
      </button>
    </div>
    <div class="form-card" *ngIf="isCreatingPage">
      <div class="header">
        <h3 class="create-title">{{'CREATE_PAGE' | translate}}</h3>
      </div>
      <div class="create-form">
        <div class="form-group form-floating">
          <input type="text" class="form-control" id="newPageName" [(ngModel)]="newPageName" placeholder="Enter page name">
          <label for="newPageName">{{'NAME' | translate}}</label>
        </div>
        <div class="page-url-input col-md-12">
          <span class="input-group-text col-md-3">{{ shopDomain }}/</span>
        <div class="form-group form-floating col-md-9">
          <input type="text" class="form-control" id="newPageUrl" [(ngModel)]="newPageUrl" placeholder="Enter page URL">
          <label for="newPageUrl">{{'PAGE_URL' | translate}}</label>
        </div>
      </div>
      </div>
      <div class="create-buttons" >
        <button class="primary-btn " (click)="createPage()" [disabled]="loading">
          {{'CREATE' | translate}}
        </button>
        <button class="remove-btn" (click)="toggleCreatePage()" [disabled]="loading">
          {{'CANCEL' | translate}}
        </button>
      </div>
    </div>

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Id</th>
          <th scope="col">{{'NAME' | translate}}</th>
          <th scope="col">{{'PAGE_URL' | translate}}</th>
          <th scope="col" class="table-end">{{'ACTIONS' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let page of pages">
            <td>{{ page.pageId }}</td>
            <td class="col-md-3">
              <input *ngIf="page.editing" type="text" class="form-control form-control-sm" [(ngModel)]="page.pageName" (ngModelChange)="updatePageName(page, $event)" placeholder="Enter name">
              <span *ngIf="!page.editing" class="view-text">{{ page.pageName }}</span>
            </td>
             <td class="col-md-6">
              <span *ngIf="!page.editing">{{ shopDomain }}/</span>
              <span *ngIf="!page.editing" class="view-text">{{ page.pageUrl }}</span>
              <div *ngIf="page.editing" class="page-url-input col-md-12">
              <span class="input-group-text">{{ shopDomain }}/</span>
              <input type="text" class="form-control form-control-sm" [(ngModel)]="page.pageUrl" placeholder="Enter URL">
              </div>
            </td>
            <td class="col-md-3">
              <div *ngIf="!page.editing" class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{'ACTIONS' | translate}}
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" (click)="showComponents(page.pageId)">{{'GO_TO_COMPONENTS' | translate}}</a></li>
                  <li *ngIf="page.pageUrl !== 'home'"><a class="dropdown-item" (click)="toggleEdit(page, $event)">{{'EDIT' | translate}}</a></li>
                  <li *ngIf="page.pageUrl !== 'home'"><a class="dropdown-item text-danger" (click)="confirmDelete(page.pageId, $event)">{{'DELETE' | translate}}</a></li>
                </ul>
              </div>
              <div *ngIf="page.editing" class="edit-actions">
                <button class="primary-btn btn-sm me-2" (click)="toggleEdit(page, $event)">{{'SAVE' | translate}}</button>
                <button class="remove-btn btn-sm" (click)="cancelEdit(page, $event)">{{'CANCEL' | translate}}</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="form-card" *ngIf="showComponentsView">
    <app-components [pageId]="selectedPageId" (close)="onComponentsClose()"></app-components>
  </div>

  <app-confirmation-modal *ngIf="modalDelete" [title]="'CONFIRM_DELETE_PAGE' | translate" [message]="'DELETE_PAGE_WARNING' | translate" [confirmText]="'DELETE' | translate" [cancelText]="'CANCEL' | translate" (response)="deletePages($event)"></app-confirmation-modal>
  <div class="modal page-confirm-modal" id="deletePageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'PAGE_DELETED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal page-confirm-modal" id="createSuccessPageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'PAGE_CREATED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal page-confirm-modal" id="createErrorPageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{"ERROR" | translate}}!</h3>
          <p class="text-center">{{ 'ERROR_CREATING_PAGE' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal page-confirm-modal" id="updateSuccessPageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'PAGE_UPDATED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal page-confirm-modal" id="updateErrorPageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{"ERROR" | translate}}!</h3>
          <p class="text-center">{{ 'ERROR_PAGE_UPDATE' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal page-confirm-modal" id="deleteErrorPageModal" tabindex="-1">
    <div class="modal-dialog page-modal-dialog" role="document">
      <div class="modal-content page-modal-content">
        <div class="modal-body page-modal-body">
          <h3 class="text-center">{{"ERROR" | translate}}!</h3>
          <p class="text-center">{{ 'ERROR_DELETING_PAGE' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal page-confirm-modal" id="validationCreateErrorPageModal" tabindex="-1">
  <div class="modal-dialog page-modal-dialog" role="document">
    <div class="modal-content page-modal-content">
      <div class="modal-body page-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ validationErrorMessage | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal page-confirm-modal" id="validationSaveErrorPageModal" tabindex="-1">
  <div class="modal-dialog page-modal-dialog" role="document">
    <div class="modal-content page-modal-content">
      <div class="modal-body page-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ validationErrorMessage | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal page-confirm-modal" id="componentDeleteErrorPageModal" tabindex="-1">
  <div class="modal-dialog page-modal-dialog" role="document">
    <div class="modal-content page-modal-content">
      <div class="modal-body page-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ 'COMPONENTS_EXIST_ERROR' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal page-confirm-modal" id="specialCharactersErrorModal" tabindex="-1">
  <div class="modal-dialog page-modal-dialog" role="document">
    <div class="modal-content page-modal-content">
      <div class="modal-body page-modal-body">
        <h3 class="text-center">{{"ERROR" | translate}}!</h3>
        <p class="text-center">{{ 'SPECIAL_CHARACTERS_NOT_ALLOWED' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>