<main class="container main-container">
  <div class="select-project">
    <div class="col-md-6">
      <label for="projects" class="form-label">{{'SELECT_PROJECT' | translate}}</label>
      <select class="form-select" (change)="onProjectChange($event)">
        <option selected disabled hidden>{{'SELECT-OPTION' | translate}}</option>
        <option *ngFor="let project of projects" [value]="project.projectId">
          {{ project.projectName }}
        </option>
      </select>
    </div>
  </div>

  <header class="header">
    <div class="title-with-tooltip">
      <h1 class="title">Agenda</h1>
      <div class="tooltip-box">
        <div class="hover-text">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
          </svg>
          <span class="tooltip-text">
            {{ 'TOOLTIP_SCHEDULE_DESC' | translate }}
          </span>
        </div>
      </div>
    </div>

    <button (click)="onSubmit()" class="custom-button btn-outline-primary primary-btn">
      {{ 'UPDATE' | translate }}
    </button>
  </header>


  <section class="content-container">
    <div class="title-container">
      <h4 class="title-table">{{'SCHEDULE' | translate}}</h4>
    </div>
    <div class="content-days" *ngFor="let availability of availabilities; let i = index">
      <div class="row mb-3">
        <div class="col-md-3">
          <label class="form-label">{{'DAY' | translate}}</label>
          <select class="form-select" [(ngModel)]="availability.day" name="day-{{i}}">
            <option *ngFor="let day of daysOfWeek" [value]="day">
              {{ getTranslatedDay(day) }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label class="form-label">{{'START_PROJECT' | translate}}</label>
          <input type="time" class="form-control" [(ngModel)]="availability.startTime" name="start-{{i}}">
        </div>
        <div class="col-md-3">
          <label class="form-label">{{'END' | translate}}</label>
          <input type="time" class="form-control" [(ngModel)]="availability.endTime" name="end-{{i}}">
        </div>
        <div class="col-md-3 delete-btn-container">
          <button type="button" class="btn btn-danger btn-sm" (click)="removeAvailability(i)">
            {{'DELETE' | translate}}
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="btn primary-btn mb-3" (click)="addAvailability()">
      {{'ADD_RESTRICTION' | translate}}
    </button>
    <h5 class="subtitle">{{ 'TIME_LOCKING' | translate }}</h5>

    <div *ngFor="let block of blockHours; let j = index" class="block-hour-container">
      <div class="d-flex align-items-center flex-wrap gap-3">
        <div>
          <label class="form-label">{{ 'START_TIME' | translate }}</label>
          <input type="time" class="form-control" [(ngModel)]="block.startTime" name="block-start-{{j}}">
        </div>
        <div>
          <label class="form-label">{{ 'START_END' | translate }}</label>
          <input type="time" class="form-control" [(ngModel)]="block.endTime" name="block-end-{{j}}">
        </div>
        <div class="d-flex flex-column justify-content-end">
          <label class="form-label invisible">Acción</label>
          <button type="button" class="btn btn-danger btn-sm" (click)="removeBlockHour(j)">{{'DELETE' | translate}}</button>
        </div>
      </div>
    </div>

    <button type="button" class="btn primary-btn mb-3" (click)="addBlockHour()">
      Agregar bloqueo
    </button>
  </section>
</main>


<div class="modal client-confirm-modal" id="successUpdateModal" tabindex="-1">
  <div class="modal-dialog client-modal-dialog" role="document">
    <div class="modal-content client-modal-content">
      <div class="modal-body client-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{'UPDATE_TIME_PROJECTS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="errorUpdateModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_UPDATE_TIME_PROJECTS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
