import { Component, OnInit } from '@angular/core';
import { ThemingService } from 'src/app/theming.service';
import { AuthService } from 'src/app/auth.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.scss']
})
export class PosComponent implements OnInit {
  shopId: string = '';
  userEmail: string | null = null;
  userPassword: string | null = null;
  currency: string | null = null;
  private emailSubscription!: Subscription;
  private passwordSubscription!: Subscription;
  private posBaseUrl: string = environment.posBaseUrl;

  constructor(public themingService: ThemingService, private authService: AuthService) {}

  async ngOnInit() {
  this.emailSubscription = this.authService.userEmail$.subscribe((email) => {
    this.userEmail = email;
  });

  this.passwordSubscription = this.authService.password$.subscribe((password) => {
    this.userPassword =
    password;
  });

  this.userEmail = this.authService.getCurrentUserEmail();
  this.userPassword = this.authService.getCurrentPassword();

  if (this.themingService && this.themingService.shopId) {
    this.shopId = this.themingService.shopId.toString();
  }

  this.themingService.getThemingService().then(() => {
    if (this.themingService.shopTheming.length > 0) {
      this.currency = this.themingService.shopTheming[0].currencyCode;
    }
  });
}

  redirectToPOS() {
    if (!this.shopId) {
      console.error('No se pudo obtener el shopId');
      return;
    }

    const usuario = this.userEmail;
    const pass = this.userPassword;
    const fecha = new Date().getTime();
    const currency = this.currency;
    const origin = window.location.href;
    const token = btoa(`${usuario}:${pass}:${fecha}:${this.shopId}:${this.currency}`);
    const redirectUrl = `${this.posBaseUrl}/auth?t=${token}&origin=${encodeURIComponent(origin)}`;
    window.location.href = redirectUrl;
  }
}
