import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  loading: boolean = false;
  modalDelete = false;
  pagesIdToDelete: number | null = null;
  pages: any[] = [];
  components: any[] = [];
  showComponentsView: boolean = false;
  selectedPageId: string | null = null;
  isCreatingPage: boolean = false;
  newPageName: string = '';
  newPageUrl: string = '';
  validationErrorMessage: string = '';
  shopDomain: string = '';
  private urlPattern = /^[a-zA-Z0-9-\/]*$/; 
  protectedRoutes: string[] = [
    '', 'home', 'search-results', 'category', 'sub-category', 'sub-sub-category',
    'collections', 'products', 'gift-card', 'gift-cards', 'shopping-cart',
    'order', 'create-beneficiaries', 'faqs', 'profile', 'terms-and-conditions',
    'privacy-policies', 'online-policies', 'payment-status', 'verify-user', 'page404'
  ];

  constructor(
    private apiService: ApiService,
    private themingService: ThemingService
  ) { }

  ngOnInit(): void {
    this.getPages();
    this.getComponents();
  }

  async getPages() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getPages(this.themingService.shopId);
      this.pages = response.data.map((page: any) => ({ ...page, editing: false }));
      this.loading = false;
      const shopDomain = this.themingService.shopTheming.length > 0 
        ? this.themingService.shopTheming[0].shopDomain 
        : 'No domain found';
      this.shopDomain = shopDomain;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  async getComponents() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getComponents(this.themingService.shopId);
      this.components = response.data;
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  closeComponents() {
    this.showComponentsView = false;
    this.selectedPageId = null;
  }

  updatePageName(page: any, event: Event) {
    const target = event.target as HTMLInputElement;
    if (target) {
      page.pageName = target.value.trim();
    }
  }

  updatePageUrl(page: any, event: Event) {
    const target = event.target as HTMLInputElement;
    if (target) {
      page.pageUrl = target.value.trim();
    }
  }

  showComponents(pageId: string) {
    this.selectedPageId = pageId;
    this.showComponentsView = true;
  }

  toggleEdit(page: any, event: Event) {
    event.preventDefault();
    if (page.editing) {
      this.savePage(page);
    } else {
      page.originalName = page.pageName;
      page.originalUrl = page.pageUrl;
      page.editing = true;
    }
  }

  cancelEdit(page: any, event: Event) {
    event.preventDefault();
    page.pageName = page.originalName || page.pageName;
    page.pageUrl = page.originalUrl || page.pageUrl;
    page.editing = false;
    delete page.originalName;
    delete page.originalUrl;
  }

  removeSpaces(value: string): string {
    return value.replace(/\s+/g, '-');
  }

  async createPage() {
    if (!this.newPageName || !this.newPageUrl) {
      $('#createErrorPageModal').modal('show');
      return;
    }

    const cleanedUrl = this.removeSpaces(this.newPageUrl);

    if (!this.urlPattern.test(cleanedUrl)) {
      $('#specialCharactersErrorModal').modal('show');
      return;
    }

    if (this.protectedRoutes.includes(cleanedUrl.toLowerCase())) {
      this.validationErrorMessage = 'URL_PROTECTED_ERROR';
      $('#validationCreateErrorPageModal').modal('show');
      return;
    }
    if (this.pages.some(page => page.pageUrl.toLowerCase() === cleanedUrl.toLowerCase())) {
      this.validationErrorMessage = 'URL_DUPLICATE_ERROR';
      $('#validationCreateErrorPageModal').modal('show');
      return;
    }

    this.loading = true;
    try {
      const response: any = await this.apiService.createPages(
        this.themingService.shopId,
        this.newPageName,
        cleanedUrl
      );
      if (response.status === 200) {
        this.toggleCreatePage();
        await this.getPages();
        $('#createSuccessPageModal').modal('show');
      } else {
        $('#createErrorPageModal').modal('show');
      }
      this.loading = false;
    } catch (error) {
      console.error("Error creating page:", error);
      $('#createErrorPageModal').modal('show');
      this.loading = false;
    }
  }

  async savePage(page: any) {
    const cleanedUrl = this.removeSpaces(page.pageUrl);
    if (!this.urlPattern.test(cleanedUrl)) {
      $('#specialCharactersErrorModal').modal('show');
      return;
    }
    if (this.protectedRoutes.includes(cleanedUrl.toLowerCase())) {
      this.validationErrorMessage = 'URL_PROTECTED_ERROR';
      $('#validationSaveErrorPageModal').modal('show');
      return;
    }
    if (this.pages.some(p => p.pageId !== page.pageId && p.pageUrl.toLowerCase() === cleanedUrl.toLowerCase())) {
      this.validationErrorMessage = 'URL_DUPLICATE_ERROR';
      $('#validationSaveErrorPageModal').modal('show');
      return;
    }

    this.loading = true;
    try {
      const response: any = await this.apiService.updatePages(
        this.themingService.shopId,
        page.pageId,
        page.pageName,
        cleanedUrl
      );
      if (response.status === 200) {
        $('#updateSuccessPageModal').modal('show');
        page.editing = false;
        page.pageUrl = cleanedUrl;
        delete page.originalName;
        delete page.originalUrl;
        this.getPages();
      } else {
        $('#updateErrorPageModal').modal('show');
      }
      this.loading = false;
    } catch (error) {
      console.error("Error updating page:", error);
      $('#updateErrorPageModal').modal('show');
      this.loading = false;
    }
  }

  confirmDelete(pageId: number, event: Event) {
    event.preventDefault();
    this.pagesIdToDelete = pageId;
    const associatedComponents = this.components.filter(component => component.pageId === this.pagesIdToDelete);
    if (associatedComponents.length > 0) {
      $('#componentDeleteErrorPageModal').modal('show');
    } else {
      this.modalDelete = true;
    }
  }

  async deletePages(response: boolean) {
    if (response && this.pagesIdToDelete !== null) {
      this.loading = true;
      try {
        const result: any = await this.apiService.deletePages(this.themingService.shopId, this.pagesIdToDelete);
        if (result && result.status === 200) {
          this.pages = this.pages.filter(page => page.pageId !== this.pagesIdToDelete);
          $('#deletePageModal').modal('show');
          await this.getPages();
        } else {
          $('#deleteErrorPageModal').modal('show');
        }
        this.modalDelete = false;
        this.pagesIdToDelete = null;
        this.loading = false;
      } catch (error) {
        console.error("Error deleting page:", error);
        $('#deleteErrorPageModal').modal('show');
        this.loading = false;
        this.modalDelete = false;
      }
    } else {
      this.modalDelete = false;
    }
  }

  toggleCreatePage() {
    this.isCreatingPage = !this.isCreatingPage;
    this.newPageName = '';
    this.newPageUrl = '';
  }

  async onComponentsClose() {
    this.showComponentsView = false;
    this.selectedPageId = null;
    await this.getPages();
    await this.getComponents();
  }

  closeModal() {
    $('#deletePageModal').modal('hide');
    $('#createSuccessPageModal').modal('hide');
    $('#createErrorPageModal').modal('hide');
    $('#updateSuccessPageModal').modal('hide');
    $('#updateErrorPageModal').modal('hide');
    $('#deleteErrorPageModal').modal('hide');
    $('#validationCreateErrorPageModal').modal('hide');
    $('#validationSaveErrorPageModal').modal('hide');
    $('#componentDeleteErrorPageModal').modal('hide');
    $('#specialCharactersErrorModal').modal('hide');
  }
}