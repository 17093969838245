import { Injectable } from '@angular/core';
import { ThemingService } from './theming.service';
import { saveAs } from 'file-saver';
import { RegionBackendService } from './region/services/regions.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import * as XLSX from 'xlsx';

interface Beneficiary {
  name: string;
  lastname: string;
  relation: string;
  bornDate: string;
  phone?: string;
  gender?: string;
  nationalId?: string;
  email?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ExportClientsService {
  shopName: string = 'Desconocido';

  constructor(
    private themingService: ThemingService,
    private regionService: RegionBackendService
  ) {
    this.extractShopName();
  }

  private extractShopName(): void {
    const themingData: any = (this.themingService as any)?.shopTheming;

    if (Array.isArray(themingData) && themingData.length > 0) {
      this.shopName = themingData[0]?.shopName || 'Desconocido';
    } else {
      console.error('ThemingService no tiene shopTheming válido.');
    }
  }

  ngOnInit(): void {}

  exportToFile(
    data: any[],
    fileName: string,
    format: 'excel' | 'json' | 'csv' | 'txt'
  ): void {
    if (!data || data.length === 0) {
      console.error('No hay datos para exportar.');
      return;
    }

    const regionRequests = data
      .filter((client) => client.regionId)
      .map((client) =>
        this.regionService.getRegionHierarchy(client.regionId).pipe(
          map((regionHierarchy) => ({
            client,
            region: this.extractRegionData(regionHierarchy),
          }))
        )
      );

    if (regionRequests.length === 0) {
      console.error(
        'Ningún cliente tiene regionId válido. Se exportarán sin ubicación.'
      );
      return this.finalizeExport(data, fileName, format, []);
    }

    forkJoin(regionRequests).subscribe(
      (results) => {
        this.finalizeExport(data, fileName, format, results);
      },
      (error) => {
        console.error('Error al cargar las regiones:', error);
        this.finalizeExport(data, fileName, format, []);
      }
    );
  }

  private finalizeExport(
    data: any[],
    fileName: string,
    format: string,
    regionData: any[]
  ): void {
    let formattedData: any[] = [];

    if (format === 'json' || format === 'txt') {
      formattedData = this.formatForJson(data, regionData);
    } else {
      formattedData = this.formatForExcel(data, regionData);
    }

    switch (format) {
      case 'excel':
        this.exportToExcel(formattedData, fileName);
        break;
      case 'json':
        this.exportToJson(formattedData, fileName);
        break;
      case 'csv':
        this.exportToCsv(formattedData, fileName);
        break;
      case 'txt':
        this.exportToTxt(formattedData, fileName);
        break;
      default:
        console.error('Formato no soportado.');
    }
  }

  private formatForJson(data: any[], regionData: any[]): any[] {
    return data.map((client) => {
      let beneficiaries: Beneficiary[] = [];

      if (typeof client.beneficiaries === 'string') {
        try {
          beneficiaries = JSON.parse(client.beneficiaries);
        } catch (error) {
          console.error('Error al parsear beneficiaries:', error);
        }
      } else if (Array.isArray(client.beneficiaries)) {
        beneficiaries = client.beneficiaries;
      }

      const regionInfo = regionData.find(
        (r) => r.client.clientId === client.clientId
      )?.region || {
        province: '',
        canton: '',
        district: '',
      };

      return {
        clientId: client.clientId,
        shopId: client.shopId,
        firstName: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.phone,
        nationalId: client.nationalId,
        address: client.address || '',
        bornDate: client.bornDate || '',
        companyName: this.shopName,
        gender: client.gender || '',
        regionId: client.regionId || '',
        beneficiaries: beneficiaries.map((b) => ({
          name: b.name || '',
          lastname: b.lastname || '',
          relation: b.relation || '',
          bornDate: b.bornDate || '',
          phone: b.phone || '',
          gender: b.gender || '',
          nationalId: b.nationalId || '',
          email: b.email || '',
        })),
      };
    });
  }

  private formatForExcel(data: any[], regionData: any[]): any[] {
    const formattedData: any[] = [];

    data.forEach((client) => {
      let beneficiaries: Beneficiary[] = [];

      if (typeof client.beneficiaries === 'string') {
        try {
          beneficiaries = JSON.parse(client.beneficiaries);
        } catch (error) {
          console.error('Error al parsear beneficiaries:', error);
        }
      } else if (Array.isArray(client.beneficiaries)) {
        beneficiaries = client.beneficiaries;
      }

      const regionInfo = regionData.find(
        (r) => r.client.clientId === client.clientId
      )?.region || {
        province: '',
        canton: '',
        district: '',
      };

      formattedData.push({
        Compañía: this.shopName,
        Numero_Identificación: client.nationalId,
        Parentesco: 'TITULAR',
        Nombre: client.firstName,
        Apellido: client.lastName,
        'Fecha de nacimiento': client.bornDate,
        'Phone Number': client.phone || '',
        Provincia: regionInfo.province,
        Cantón: regionInfo.canton,
        Distrito: regionInfo.district,
        Dirección: client.address || '',
        Género: client.gender || '',
        Email: client.email || '',
      });

      beneficiaries.forEach((beneficiary: Beneficiary) => {
        formattedData.push({
          Compañía: this.shopName,
          Numero_Identificación: beneficiary.nationalId || '',
          Parentesco: beneficiary.relation,
          Nombre: beneficiary.name || '',
          Apellido: beneficiary.lastname || '',
          'Fecha de nacimiento': beneficiary.bornDate,
          'Phone Number': beneficiary.phone || '',
          Provincia: regionInfo.province,
          Cantón: regionInfo.canton,
          Distrito: regionInfo.district,
          Género: beneficiary.gender || '',
          Email: beneficiary.email,
        });
      });
    });

    return formattedData;
  }

  private extractRegionData(regionHierarchy: any): {
    province: string;
    canton: string;
    district: string;
  } {
    let province = '',
      canton = '',
      district = '';

    if (regionHierarchy) {
      district = regionHierarchy.name || '';

      if (regionHierarchy.parent) {
        canton = regionHierarchy.parent.name || '';

        if (regionHierarchy.parent.parent) {
          province = regionHierarchy.parent.parent.name || '';
        }
      }
    }

    return { province, canton, district };
  }

  private exportToExcel(data: any[], fileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(data);
    worksheet['!cols'] = [
      { wch: 15 },
      { wch: 25 },
      { wch: 12 },
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 55 },
      { wch: 20 },
      { wch: 30 },
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const file = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(file, `${fileName}.xlsx`);
  }

  private exportToJson(data: any[], fileName: string): void {
    const jsonString = JSON.stringify(data, null, 2);
    const file = new Blob([jsonString], { type: 'application/json' });
    saveAs(file, `${fileName}.json`);
  }

  private exportToCsv(data: any[], fileName: string): void {
    const headers = [
      'Compañía',
      'Numero_Identificación',
      'Parentesco',
      'Nombre',
      'Apellido',
      'Fecha de nacimiento',
      'Phone Number',
      'Provincia',
      'Cantón',
      'Distrito',
      'Dirección',
      'Género',
      'Email',
    ];

    const csvRows = data.map((row) =>
      headers
        .map((header) => {
          let value = row[header] || '';
          value = value.toString().trim();

          if (
            value.includes(',') ||
            value.includes('\n') ||
            value.includes('"')
          ) {
            value = `"${value.replace(/"/g, '""')}"`;
          }
          return value;
        })
        .join(',')
    );

    const csvString = [headers.join(','), ...csvRows].join('\n');

    const file = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(file, `${fileName}.csv`);
  }

  private exportToTxt(data: any[], fileName: string): void {
    const separator =
      '------------------------------------------------------------';

    const txtRows = data.map((client) => {
      let txtString = `
${separator}
Compañía: ${client.companyName || 'No disponible'}
Numero Identificación: ${client.nationalId || 'No disponible'}
Nombre: ${client.firstName || 'No disponible'} ${
        client.lastName || 'No disponible'
      }
Fecha de Nacimiento: ${client.bornDate || 'No disponible'}
Teléfono: ${client.phone || 'No disponible'}
Provincia: ${client.regionId || 'No disponible'}
Dirección: ${client.address || 'No disponible'}
Género: ${client.gender || 'No disponible'}
Email: ${client.email || 'No disponible'}
Beneficiarios:
  `;

      let beneficiaries: any[] = [];

      if (client.beneficiaries) {
        if (typeof client.beneficiaries === 'string') {
          try {
            beneficiaries = JSON.parse(client.beneficiaries);
          } catch (error) {
            console.error('Error al parsear beneficiaries:', error);
          }
        } else if (Array.isArray(client.beneficiaries)) {
          beneficiaries = client.beneficiaries;
        }
      }

      if (beneficiaries.length > 0) {
        beneficiaries.forEach((beneficiary) => {
          txtString += `
  - Nombre: ${beneficiary.name || 'No disponible'} ${
            beneficiary.lastname || 'No disponible'
          }
    Relación: ${beneficiary.relation || 'No disponible'}
    Numero Identificación: ${beneficiary.nationalId || 'No disponible'}
    Fecha de Nacimiento: ${beneficiary.bornDate || 'No disponible'}
    Teléfono: ${beneficiary.phone || 'No disponible'}
    Género: ${beneficiary.gender || 'No disponible'}
    Email: ${beneficiary.email || 'No disponible'}
    `;
        });
      } else {
        txtString += `\n    (Sin beneficiarios)`;
      }

      return txtString;
    });

    const txtString = txtRows.join('\n\n');

    const file = new Blob([txtString], { type: 'text/plain;charset=utf-8;' });
    saveAs(file, `${fileName}.txt`);
  }
}
