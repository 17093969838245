import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-favicon',
  templateUrl: './favicon.component.html',
  styleUrls: ['./favicon.component.scss']
})
export class FaviconComponent implements OnInit {
  previewUrl: SafeUrl | null = null;
  faviconFile: File | null = null;
  faviconKey: string = '';
  loading = false;
  shopFavicon!: string;
  shopName!: string;
  isEditingFavicon = false;


  constructor(
    private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private themingService: ThemingService
  ) {}

  ngOnInit(): void {
    const shopId = this.themingService.shopTheming[0]?.shopId;
    const stage = environment.stage;
    this.shopFavicon = `https://mobydyg-files.s3.amazonaws.com/${shopId}/${stage}/images/logos/favicon.ico`;
    this.shopName = this.themingService.shopTheming[0]?.shopName;
  }
  

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (!file) return;
    const url = URL.createObjectURL(file);
    this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.faviconFile = file;
  
    const ext = file.name.split('.').pop();
    this.faviconKey = `${this.themingService.shopId}/${environment.stage}/images/logos/favicon.ico`;
  }
  

  switchEditingFavicon(): void {
    this.isEditingFavicon = !this.isEditingFavicon;
    if (this.isEditingFavicon && this.shopFavicon) {
      this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(this.shopFavicon);
    }
  }

  async onSaveFavicon(): Promise<void> {
    if (!this.faviconFile || !this.faviconKey) return;
    this.loading = true;
  
    try {
      await this.apiService.uploadFile(this.faviconKey, this.faviconFile, {
        position: 0,
        type: 'favicon'
      });
  
      this.shopFavicon = `https://mobydyg-files.s3.amazonaws.com/${this.themingService.shopTheming[0].shopId}/${environment.stage}/images/logos/favicon.ico`;
      this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(this.shopFavicon);
      this.isEditingFavicon = false;
      $('#successFaviconModal').modal('show');
   
    } catch (err) {
      console.error('Error al subir favicon:', err);
      $('#errorFaviconsModal').modal('show');
    } finally {
      this.loading = false;
    }
  }
   
  closeModal(): void {
    $('#successFaviconModal').modal('hide');
    $('#errorFaviconsModal').modal('hide');
    $('#errorFaviconTypeModal').modal('hide');
  }
  
}
