import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare let $: any;

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss'],
})

export class FrequentQuestionsComponent implements OnInit {
  panelOpenState = false;
  frequentQuestions: any[] = [];
  loading: boolean = false;
  displayedColumns: string[] = ['faqId', 'questionAnswer', 'actions'];
  modalDelete: number | boolean = false;
  editingFaqId: number | null = null;
  isAddingNewQuestion: boolean = false; 

  faqs = {
    answer: '',
    question: '',
  };

  updateFaqs = {
    answer: '',
    question: '',
    faqId: 0,
  };

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
      this.getFrequentQuestions();
  }

  async getFrequentQuestions() {
    this.loading = true;
    try {
      const response: any = await this.apiService.getFrequentQuestions(this.themingService.shopId);
      this.frequentQuestions = response.data;
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      this.loading = false;
    }
  }

  startEditing(faq: any) {
    this.editingFaqId = faq.faqId;
    this.updateFaqs = { ...faq };
  }

  cancelEditing() {
    this.editingFaqId = null;
  }
  
  openDeleteModal(faqId: number) {
    this.modalDelete = faqId;
  }

  toggleAddMode() {
    this.isAddingNewQuestion = !this.isAddingNewQuestion;
  }

  createFrequentQuestions() {
    this.loading = true;
  
    if (this.faqs.question && this.faqs.answer) {
      // Sanitizar la pregunta y la respuesta antes de enviarlas
      let sanitizedQuestion = JSON.stringify(this.faqs.question).slice(1, -1);
      let sanitizedAnswer = JSON.stringify(this.faqs.answer).slice(1, -1);
  
      this.apiService
        .createFrequentQuestions(this.themingService.shopId, sanitizedAnswer, sanitizedQuestion)
        .then((data: any) => {
          if (data.status === 200) { 
            this.getFrequentQuestions();
            $('#createSuccessFaqModal').modal('show'); 
            this.faqs = { answer: '', question: '' };
            this.isAddingNewQuestion = false;
          } else {
            $('#createErrorFaqModal').modal('show'); 
          }
        })
        .catch((error) => {
          console.error("Error al crear la pregunta:", error);
          $('#createErrorFaqModal').modal('show'); 
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      $('#createErrorFaqModal').modal('show'); 
    }
  }
  
  updateFrequentQuestions() {
    this.loading = true;
  
    if (this.updateFaqs.faqId && this.updateFaqs.question && this.updateFaqs.answer) {
      let sanitizedQuestion = JSON.stringify(this.updateFaqs.question).slice(1, -1);
      let sanitizedAnswer = JSON.stringify(this.updateFaqs.answer).slice(1, -1);
  
      this.apiService
        .updateFrequentQuestions(
          this.themingService.shopId,
          this.updateFaqs.faqId,
          sanitizedAnswer,
          sanitizedQuestion
        )
        .then((data: any) => {
          if (data.status === 200) {
            $('#updateSuccessFaqModal').modal('show');
            this.editingFaqId = null;
            this.getFrequentQuestions();
          } else {
            $('#updateErrorFaqModal').modal('show'); 
          }
        })
        .catch((error) => {
          console.error("Error al actualizar la pregunta:", error);
          $('#updateErrorFaqModal').modal('show'); 
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      $('#updateErrorFaqModal').modal('show'); 
    }
  }
  
  confirmDelete(confirm: boolean) {
    if (confirm && this.modalDelete) {
      this.deleteFrequentQuestions(this.modalDelete);
    } else {
      this.modalDelete = false;
    }
  }

  deleteFrequentQuestions(faqId: number | boolean) {
    this.loading = true;
    if (faqId && typeof faqId === 'number') {
      this.apiService
        .deleteFrequentQuestions(this.themingService.shopId, faqId)
        .then(() => {
          $('#deleteFaqModal').modal('show'); 
          this.getFrequentQuestions(); 
          this.modalDelete = false; 
          this.loading = false;
        })
        .catch((error) => console.error(error));
    } else {
      this.modalDelete = false; 
      this.loading = false;
    }
  } 
  

  closeModal(){
    $('#deleteFaqModal').modal('hide');
    $('#createSuccessFaqModal').modal('hide');
    $('#createErrorFaqModal').modal('hide');
    $('#updateSuccessFaqModal').modal('hide');
    $('#updateErrorFaqModal').modal('hide');
  }

}
