import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import Quill from 'quill'; 
declare let $: any;

@Component({
  selector: 'app-create-component',
  templateUrl: './create-component.component.html',
  styleUrls: ['./create-component.component.scss']
})
export class CreateComponentComponent implements OnInit {
@Output() creationSuccess = new EventEmitter<void>();
@Input() pageId: string | null = null;
  componentForm: FormGroup;
  componentTypes: any[] = [];
  loading: boolean = false;
  selectedParentId: number = 0;
  groupedDesigns: any;
  uniqueParentIds: any[] = [];
  selectedDesignDescription: string = '';
  selectedDesignImageUrl: string = '';
  selectedDesign: any = null;
  minItems: number = 0;
  maxItems: number = 0;
  errorMessage: string = '';
  categories: any[] = [];
  collections: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];
  pages: any [] = [];
  selectedOption: string = '';
  collectionId:  number = 0;
  categoryId:  number = 0;
  subCategoryId:  number = 0;
  subSubCategoryId:  number = 0;
  productComponentType: number = 0;
  designEnabled: boolean = false;
  itemQuantity: number = 4;
  componentName: any;
  descriptionText: any;
  infoDescriptionText: string = ''; 
  infoTextLength: number = 0;  
  infoMaxLength: number = 2500;  
  maxLength = 2500;
  textLength = 0;
  previewUrl!: string | ArrayBuffer | null;
  uploadedFileUrl: any;
  remainingCharacters: number = 0;
  remainingInfoCharacters: number = 0; 

  parentIdNames: { [key: number]: string } = {
    1: 'Banners',
    2: 'Tarjetas',
    3: 'Productos'
  };

  fieldVisibilityConfig: { [key: number]: string[] } = {
    1: ['infoImageUrl', 'infoTitle', 'infoDescription','redirectUrl', 'urlName'], // Slider texto imagen
    2: ['infoImageUrl', 'infoDescription','redirectUrl'], // Slider Manual
    3: ['title', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'], // 1 Tarjeta con imagen titulo y descripcion
    4: ['title', 'description', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'], // 3 Tarjetas con imagen de fondo
    5: ['title', 'description', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'], // 4, 8 o 12 tarjetas con imagen.
    6: ['title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl'], // 3-12 Tarjetas de texto
    7: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'], // 3 tarjetas con imagen y enlace
    8: ['title', 'subtitle', 'itemQuantity'], // Diseño por defecto
    9: ['title', 'subtitle', 'itemQuantity'], // Diseño moderno
    10: ['title', 'subtitle', 'itemQuantity'], // Diseño con carrusel
    11: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'], // 2 tarjetas con imagen
    12: ['infoImageUrl', 'redirectUrl'], // Slider-Banner
    13: ['title', 'subtitle', 'description', 'redirectUrl'], //1 Tarjeta de texto
    14: ['title', 'subtitle', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'], // 1-12 tarjetas de precios
    15: ['infoImageUrl', 'redirectUrl', 'urlName'], // Banner con 1 o 2 enlaces e imagenes
    16: ['infoImageUrl', 'redirectUrl'], // Banner
    17: ['infoVideoUrl', 'redirectUrl', 'infoTitle', 'infoDescription'], // Video Banner con titulo y descripcion
    18: ['infoVideoUrl', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'], // 1 Tarjeta con video
    19: ['title', 'infoImageUrl', 'redirectUrl'], // Slider-Gallery
    20: ['title', 'subtitle', 'infoImageUrl', 'redirectUrl'], // Vertical-Gallery
    21: ['title', 'subtitle', 'infoImageUrl', 'redirectUrl'], // Horzontal-Gallery
    22: ['infoVideoUrl','redirectUrl'], // Video Banner
  };

  registerFonts() {
    const FontAttributor = Quill.import('attributors/class/font');
    FontAttributor.whitelist = [
            'arial',
            'inter',
            'lato',
            'merriweather',
            'montserrat',
            'nunito',
            'pacifico',
            'poppins',
            'raleway',
            'roboto',
            'serif',
    ];

    // Registrar las fuentes en Quill
    Quill.register(FontAttributor, true);
  }

  modules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'],},], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ['link'], 
      ['emoji'],
      ['clean'], // remove formatting button
      [
        {
          font: [
            'arial',
            'inter',
            'lato',
            'merriweather',
            'montserrat',
            'nunito',
            'pacifico',
            'poppins',
            'raleway',
            'roboto',
            'serif'
          ],
        },
      ],
    ],
  };
  
  constructor(
    public appComponent: AppComponent,
    private fb: FormBuilder,
    private apiService: ApiService,
    private themingService: ThemingService,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.componentForm = this.fb.group({
      component: this.fb.group({
        componentId: [0],
        title: [''],
        subtitle: [''],
        description: [''],
        infoTitle: [''],
        infoDescription: [''],
        redirectUrl: [''],
        urlName: [''],
        componentTypeId: [0, Validators.required],
        status: [0],
        position: [0],
        itemQuantity: [0],
        itemListId: [0],
        itemSort: [0],
        categoryId: [0],
        subCategoryId: [0],
        subSubCategoryId: [0],
        collectionId: [0]
      }),
      infoFields: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loading = true;
    Promise.all([
      this.getComponentTypes(),
      this.getCategories(),
      this.getCollections(),
      this.getSubCategories(),
      this.getSubSubCategories(),
      this.registerFonts(), // Registrar las fuentes en Quill
    ]).then(() => {
      this.loading = false;
    }).catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }
  
  async getComponentTypes(): Promise<void> {
    try {
      const response: any = await this.apiService.getComponentTypes(this.themingService.shopId);
      this.componentTypes = response.data;
      this.uniqueParentIds = this.getUniqueParentIds();
      this.groupedDesigns = this.getDesignsByParentId();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getPages() {
    try {
      const response: any = await this.apiService.getPages(this.themingService.shopId);
      this.pages = response.data
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }
  
  async getCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getCategories(this.themingService.shopId);
      this.categories = data.categories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getCollections(): Promise<void> {
    try {
      const data: any = await this.apiService.getCollections(this.themingService.shopId);
      this.collections = data.collections;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubCategories(this.themingService.shopId);
      this.subCategories = data.subCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubSubCategories(this.themingService.shopId);
      this.subSubCategories = data.subSubCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  onOptionChange(option: string) {
    this.selectedOption = option;
    this.resetSelections();

    switch (option) {
      case 'categories':
        this.productComponentType = 1;
        break;
      case 'subCategories':
        this.productComponentType = 2;
        break;
      case 'subSubcategories':
        this.productComponentType = 3;
        break;
      case 'collections':
        this.productComponentType = 4;
        break;
      case 'custom':
        this.productComponentType = 5;
        break;
    }
  }

  titleChanged(event: any) {
    if (event.event === 'text-change') {
        const title = event.html;
        this.componentForm.get('title')?.setValue(title);
        this.cdr.detectChanges();
      }
    }
  
  
  subtitleChanged(event: any) {
    if (event.event === 'text-change') {
      const subtitle = event.html;
      this.componentForm.get('subtitle')?.setValue(subtitle);
      this.cdr.detectChanges();
    }
  }
  
  infoTitleChanged(event: any, index: number) {
    if (event.event === 'text-change') {
      const infoTitle = event.html;
      this.componentForm.get('infoTitle')?.setValue(infoTitle);
      this.cdr.detectChanges();
    }
  }

  descriptionChanged(event: any) {
    if (event.event === 'text-change') {
      const quill = event.editor;
      this.textLength = quill.getLength() - 1;  
      this.remainingCharacters = this.maxLength - this.textLength; 

      if (this.textLength > this.maxLength) {
        quill.deleteText(this.maxLength, this.textLength - this.maxLength);
        this.remainingCharacters = 0; 
      } else {
        const description = event.html;
        this.componentForm.get('description')?.setValue(description);
        this.cdr.detectChanges();
      }
    }
  }
  
  
  infoDescriptionChanged(event: any) {
    if (event.event === 'text-change') {
      const quill = event.editor;
      this.infoTextLength = quill.getLength() - 1; 
      this.remainingInfoCharacters = this.infoMaxLength - this.infoTextLength; 
      if (this.infoTextLength > this.infoMaxLength) {
        quill.deleteText(this.infoMaxLength, this.infoTextLength - this.infoMaxLength);
        this.remainingInfoCharacters = 0;  
      } else {
        const infoDescription = event.html;
        this.componentForm.get('infoDescription')?.setValue(infoDescription);
        this.cdr.detectChanges();
      }
    }
  }


  onItemQuantityChange(event: any) {
    this.itemQuantity = event.target.value;
 }
    
  onCategoryChange(event: any) {
    this.categoryId = event.target.value;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
    this.designEnabled = true; 
 }
    
  onSubCategoryChange(event: any) {
      this.subCategoryId = event.target.value;
      this.subSubCategoryId = 0;
      this.collectionId = 0;
      this.designEnabled = true; 
  }
    
  onSubSubCategoryChange(event: any) {
      this.subSubCategoryId = event.target.value;
      this.collectionId = 0;
      this.designEnabled = true; 
  }
    
  onCollectionChange(event: any) {
      this.collectionId = event.target.value;
      this.categoryId = 0;
      this.subCategoryId = 0;
      this.subSubCategoryId = 0;
      this.designEnabled = true; 
  }
    
  resetSelections() {
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
    this.designEnabled = false;
  }

  onChangeParentId(event: any) {
    this.selectedParentId = event.target.value;
    this.resetForm(false);
    this.componentForm.get('component.componentTypeId')?.setValue(0);
    this.designEnabled = this.selectedParentId != 3;
  }

  getUniqueParentIds() {
    const uniqueParentIds = Array.from(new Set(this.componentTypes.map(item => item.componentParentId)));
    return uniqueParentIds
    .filter(parentId => this.parentIdNames.hasOwnProperty(parentId))
    .map(parentId => {
      const firstComponentWithType = this.componentTypes.find(item => item.componentParentId === parentId);
      return { id: parentId, name: firstComponentWithType ? this.parentIdNames[parentId] : '' };
    });
  }
  
  private getDesignsByParentId(): any {
    return this.componentTypes.reduce((acc, curr) => {
      if (!acc[curr.componentParentId]) {
        acc[curr.componentParentId] = [];
      }
      acc[curr.componentParentId].push({ id: curr.componentTypeDesignId, name: curr.componentTypeName, typeId: curr.componentTypeId, description: curr.componentTypeDescription, imageUrl: curr.imageUrl });
      return acc;
    }, {});
  }

  changeComponentTypeId(event: any) {
    const componentTypeId = parseInt(event.target.value, 10);
    this.componentForm.get('component.componentTypeId')?.setValue(componentTypeId);
    
    this.selectedDesign = this.groupedDesigns[this.selectedParentId]?.find((design: { typeId: any; }) => design.typeId === componentTypeId);
    if (this.selectedDesign) {
      this.selectedDesignDescription = this.selectedDesign.description;
      this.selectedDesignImageUrl = this.selectedDesign.imageUrl;
    }
    
    const componentType = this.componentTypes.find(ct => ct.componentTypeId === componentTypeId);
    if (componentType) {
      this.minItems = componentType.minItems;
      this.maxItems = componentType.maxItems;

      this.infoFields.clear();
      for (let i = 0; i < this.minItems; i++) {
        this.addNewField(false); 
      }
    } else {
      this.errorMessage = 'No se pudo encontrar el tipo de componente seleccionado.';
    }
  }
  
  getFieldVisibilityConfig(): string[] {
    return this.fieldVisibilityConfig[this.componentForm.get('component.componentTypeId')?.value] || [];
  }

  get infoFields(): FormArray {
    return this.componentForm.get('infoFields') as FormArray;
  }

  addNewField(validate: boolean = true) {
    const visibilityConfig = this.getFieldVisibilityConfig();
    const newField = this.fb.group({});
  
    if (visibilityConfig.includes('infoTitle')) {
      newField.addControl('infoTitle', this.fb.control('', Validators.required));
    } else {
      newField.addControl('infoTitle', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('infoDescription')) {
      newField.addControl('infoDescription', this.fb.control('', Validators.required));
    } else {
      newField.addControl('infoDescription', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('redirectUrl')) {
      newField.addControl('redirectUrl', this.fb.control('', Validators.required));
    } else {
      newField.addControl('redirectUrl', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('urlName')) {
      newField.addControl('urlName', this.fb.control('', Validators.required));
    } else {
      newField.addControl('urlName', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('infoImageUrl')) {
      newField.addControl('imageURL', this.fb.control('', Validators.required));
    } else {
      newField.addControl('imageURL', this.fb.control(''));
    }

    if (visibilityConfig.includes('infoVideoUrl')) {
      newField.addControl('videoURL', this.fb.control(''));
      newField.addControl('videoManualURL', this.fb.control('')); 
    }

    if (visibilityConfig.includes('infoVideoUrl')) {
      newField.addControl('videoUrlType', this.fb.control(1));
    }
    if (validate && this.infoFields.length >= this.maxItems) {
      this.errorMessage = `El máximo de items para este componente es de ${this.maxItems}.`;
      $('#errorMaxMinItemModal').modal('show');
    } else {
      this.infoFields.push(newField);
      this.cdr.detectChanges();
      this.errorMessage = ''; 
    }
  }
    
  removeField(index: number) {
    if (this.infoFields.length <= this.minItems) {
      this.errorMessage = `El mínimo de items para este componente es de ${this.minItems}.`;
      $('#errorMaxMinItemModal').modal('show');
    } else {
      this.infoFields.removeAt(index);
      this.errorMessage = ''; 
    }
  }
  
  handleInfoFieldChange(index: number, field: 'infoTitle' | 'infoDescription' | 'redirectUrl' | 'imageURL' | 'urlName' | 'videoUrl', value: string) {
    this.infoFields.at(index).get(field)?.setValue(value);
  }
  
  sanitizeURL(mediaURL: string): any {
    if (!mediaURL) return '';
    if (mediaURL.match(/\.(jpeg|jpg|gif|png|webp)$/i)) {
      return this.sanitizer.bypassSecurityTrustUrl(mediaURL);
    }
    if (mediaURL.match(/\.(mp4|webm|ogg)$/i)) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(mediaURL);
    }
    return this.sanitizer.bypassSecurityTrustUrl(mediaURL);
  }

  isYouTubeVideo(url: string): boolean {
    return url.includes('youtube.com') || url.includes('youtu.be');
  }

  sanitizeVideoURL(videoURL: string): SafeUrl {
    if (this.isYouTubeVideo(videoURL)) {
      const embedUrl = videoURL.replace('watch?v=', 'embed/');
      return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoURL);
  }
  
  triggerFileInput(index: number, type: 'image' | 'video'): void {
    const inputId = type === 'image' ? `btn-upload-image-${index}` : `btn-upload-video-${index}`;
    const uploadButton = document.getElementById(inputId) as HTMLInputElement;
    
    if (uploadButton) {
      uploadButton.click();
    }
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();
      
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };
 
  removeMedia(index: number, field: 'imageURL' | 'videoURL') {
    this.infoFields.at(index).get(field)?.setValue(''); 
  
    const fileInputId = field === 'imageURL' ? `btn-upload-image-${index}` : `btn-upload-video-${index}`;
    const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
  
    if (fileInput) {
      fileInput.value = ''; 
    }
  }
  
  captureThumbnail(video: HTMLVideoElement, index: number) {
    video.currentTime = 0; 
    video.pause(); 
  }

  onVideoSourceChange(index: number, event: any): void {
    const selectedValue = event.target.value;
    const fieldGroup = this.infoFields.at(index) as FormGroup;
  
    fieldGroup.get('videoUrlType')?.setValue(selectedValue);
  
    if (selectedValue == 1) {
      fieldGroup.get('videoManualURL')?.setValue('');
    } else {
      fieldGroup.get('videoURL')?.setValue('');
      fieldGroup.get('videoBlob')?.setValue(null);
    }
  }
  
  handleVideoManualURLChange(index: number, event: any): void {
    const url = event.target.value.trim();
    const fieldGroup = this.infoFields.at(index) as FormGroup;
    fieldGroup.get('videoManualURL')?.setValue(url);
    fieldGroup.get('videoURL')?.setValue('');
    fieldGroup.get('videoBlob')?.setValue(null);
  }

  handleFileSelection(index: number, event: any, type: 'image' | 'video'): void {
    const file: File = event.target.files[0];
    if (!file) return;

    const MAX_FILE_SIZE = 12 * 1024 * 1024; 

    if (file.size > MAX_FILE_SIZE) {
        $('#errorCreateFileSizeModal').modal('show'); 
        return;
    }

    const tempURL = URL.createObjectURL(file);
    const ext = file.name.split('.').pop();
    const timestamp = Date.now();
    const subfolder = type === 'image' ? 'images' : 'videos';
    const key = `${this.themingService.shopId}/${environment.stage}/${subfolder}/components/component_${timestamp}.${ext}`;

    const fieldGroup = this.infoFields.at(index) as FormGroup;

    if (!fieldGroup.get('blob')) fieldGroup.addControl('blob', new FormControl(null));
    if (!fieldGroup.get('videoBlob')) fieldGroup.addControl('videoBlob', new FormControl(null));

    if (type === 'image') {
        fieldGroup.get('imageURL')?.setValue(tempURL);
        fieldGroup.get('blob')?.setValue({ file, key });
    } else {
        fieldGroup.get('videoURL')?.setValue(tempURL);
        fieldGroup.get('videoBlob')?.setValue({ file, key });
        fieldGroup.get('videoManualURL')?.setValue('');
        fieldGroup.get('videoUrlType')?.setValue(1);
    }

    this.cdr.detectChanges();
}

  async uploadFileToS3(fileObj: { file: File, key: string }): Promise<string> {
    try {
      const { file, key } = fileObj;
      const uploadResponse: any = await this.apiService.uploadFile(key, file, { position: 1, type: 'original' });

      return uploadResponse.imageURL || uploadResponse.videoURL;
    } catch (error) {
      console.error(`Error uploading file:`, error);
      throw error;
    }
  }

  async uploadComponentFiles(): Promise<{ images: string[], videos: string[] }> {
    const uploadedImages: string[] = [];
    const uploadedVideos: string[] = [];

    for (const field of this.infoFields.controls) {
      const fieldGroup = field as FormGroup;

      if (fieldGroup.get('blob')?.value) {
        const { file, key } = fieldGroup.get('blob')?.value;
        const uploaded = await this.uploadFileToS3({ file, key });
        fieldGroup.get('imageURL')?.setValue(uploaded);
        uploadedImages.push(uploaded);
      } else {
        uploadedImages.push(fieldGroup.get('imageURL')?.value || '');
      }

      if (fieldGroup.get('videoUrlType')?.value == 1 && fieldGroup.get('videoBlob')?.value) {
        const { file, key } = fieldGroup.get('videoBlob')?.value;
        const uploaded = await this.uploadFileToS3({ file, key });
        fieldGroup.get('videoURL')?.setValue(uploaded);
        uploadedVideos.push(uploaded);
      } else if (fieldGroup.get('videoUrlType')?.value == 2) {
        uploadedVideos.push(fieldGroup.get('videoManualURL')?.value || '');
      } else {
        uploadedVideos.push('');
      }
    }
    return { images: uploadedImages, videos: uploadedVideos };
  }

  async validateFormData(): Promise<void> {
    this.loading = true;

    if (!this.componentForm.get('component.componentTypeId')?.value) {
      this.errorMessage = 'Por favor, seleccione un diseño antes de crear el componente.';
      $('#errorMaxMinItemModal').modal('show');
      this.loading = false;
      return;
    }

    if (!this.componentName) {
      this.errorMessage = 'Por favor, ingrese un nombre para el componente.';
      $('#errorMaxMinItemModal').modal('show');
      this.loading = false;
      return;
    }

    try {
      const { images, videos } = await this.uploadComponentFiles();

      const componentData = this.componentForm.value;
      const descriptions = componentData.infoFields.map((field: any) => this.sanitizeString(field.infoDescription)).join('|');
      const redirectUrls = componentData.infoFields.map((field: any) => this.sanitizeString(field.redirectUrl)).join('|');
      const infoTitles = componentData.infoFields.map((field: any) => this.sanitizeString(field.infoTitle)).join('|');
      const urlNames = componentData.infoFields.map((field: any) => this.sanitizeString(field.urlName)).join('|');

      await this.apiService.createComponent(
        this.componentForm.get('component.componentId')?.value,
        this.themingService.shopId,
        this.sanitizeString(this.componentName),
        this.sanitizeString(componentData.component.title),
        this.sanitizeString(componentData.component.subtitle),
        componentData.component.position,
        componentData.component.status,
        this.sanitizeString(componentData.component.description),
        componentData.component.componentTypeId,
        this.itemQuantity,
        componentData.component.itemListId,
        componentData.component.itemSort,
        images.join('|'), 
        descriptions,
        redirectUrls,
        infoTitles,
        urlNames,
        this.collectionId,
        this.categoryId,
        this.subCategoryId,
        this.subSubCategoryId,
        this.productComponentType,
        videos.join('|'),
        componentData.infoFields.map((field: any) => field.videoUrlType).join('|'),
        parseInt(this.pageId || '0') 
      );

      this.loading = false;
      $('#successcomponentModal').modal('show');
      $('#successcomponentModal').on('hidden.bs.modal', () => {
        this.creationSuccess.emit();
      });

    } catch (error) {
      console.error(error);
      this.loading = false;
      $('#errorcomponentModal').modal('show');
    }
  }

  sanitizeString(str: string): string {
    if (!str) return '';
    return str
    .replace(/\\/g, '\\\\')   
    .replace(/"/g, '\\"')     
    .replace(/\n/g, '\\n')    
    .replace(/\r/g, '\\r');   
  }
  
  setDynamicValidations(componentTypeId: number) {
    const visibilityConfig = this.fieldVisibilityConfig[componentTypeId] || [];
    const componentControls = this.componentForm.get('component') as FormGroup;
  
    Object.keys(componentControls.controls).forEach(key => {
      componentControls.get(key)?.clearValidators();
      componentControls.get(key)?.updateValueAndValidity();
    });

    visibilityConfig.forEach(field => {
      componentControls.get(field)?.setValidators([Validators.required]);
      componentControls.get(field)?.updateValueAndValidity();
    });
  } 

  resetForm(resetParent: boolean = true): void {
    this.componentForm.reset({
      component: {
        componentId: 0,
        title: '',
        subtitle: '',
        description: '',
        infoTitle: '',
        redirectUrl: '',
        urlName: '',
        infoDescription: '',
        componentTypeId: resetParent ? 0 : this.selectedParentId,
        status: 0,
        position: 0,
        itemQuantity: 0,
        itemListId: 0,
        itemSort: 0,
        categoryId: 0,
        subCategoryId: 0,
        subSubCategoryId: 0,
        collectionId: 0
      },
      infoFields: []
    });
  
    // Clear form arrays
    this.infoFields.clear();
  
    // Reset selection variables
    this.selectedOption = '';
    this.selectedDesignDescription = '';
    this.selectedDesignImageUrl = '';
    this.selectedDesign = null;
    this.minItems = 0;
    this.maxItems = 0;
    this.errorMessage = '';
    if (resetParent) {
      this.selectedParentId = 0;
    }
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;

    this.getCategories();
    this.getCollections();
    this.getSubCategories();
    this.getSubSubCategories();
  
    this.cdr.detectChanges();
  }
  
  closeModal(): void {
    $('#successcomponentModal').modal('hide');
    $('#errorcomponentModal').modal('hide');
    $('#errorMaxMinItemModal').modal('hide');
    $('#errorCreateFileSizeModal').modal('hide');
  }
}
