<div class="container-client my-4">
  <div class="header">
    <h1 class="title">{{'NEW_PROJECT' | translate}}</h1>
    <app-back-arrow-settings [callback]="setListingProjects"></app-back-arrow-settings>
  </div>
  <div class="form-card">
    <form [formGroup]="projectForm" (ngSubmit)="createProject()">
      <div class="row mb-3">
        <div class="col-md-6">
          <label class="form-label" for="name">{{'PROJECT_NAME' | translate}}</label>
          <input class="form-control" id="name" formControlName="name" type="text" placeholder="">
        </div>
        <div class="col-md-6">
          <div class="form-label-with-tooltip">
            <label class="form-label" for="slug">{{ 'IDENTIFIER' | translate }}</label>
            <div class="tooltip-box">
              <div class="hover-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path fill="#6BBECD"
                    d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z" />
                </svg>
                <span class="tooltip-text col-md-8" id="top">{{ 'TOOLTIP_IDENTIFIER_DESC' | translate }}</span>
              </div>
            </div>
          </div>
          <input class="form-control" id="slug" formControlName="slug" type="text" placeholder="Ej: my-test-project">
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6">
          <div class="form-label-with-tooltip">
            <label class="form-label">{{ 'ALLOW_CONFERENCE' | translate }}</label>
            <div class="tooltip-box">
              <div class="hover-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path fill="#6BBECD"
                    d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z" />
                </svg>
                <span class="tooltip-text">{{ 'TOOLTIP_ALLOW_CONFERENCE_DESC' | translate }}</span>
              </div>
            </div>
          </div>
          <select class="form-select" formControlName="allow_conference">
            <option value="1">{{ 'YES' | translate }}</option>
            <option value="0">{{ 'NO' | translate }}</option>
          </select>
        </div>

        <div class="col-md-6">
          <label class="form-label" for="resource">{{'RESOURCE' | translate}}</label>
          <select class="form-select" id="resource" formControlName="resource" (change)="changeResource($event)">
            <option *ngFor="let resource of resources" [value]="resource.resourceId">{{ resource.resourceName }}
            </option>
          </select>
        </div>
      </div>

      <div formGroupName="booking">
        <h4 class="subtitle">{{'BOOKINGS_DETAIL' | translate}}</h4>
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label">{{'DESCRIPTION' | translate}}</label>
            <input type="text" class="form-control" formControlName="description" placeholder="">
          </div>
          <div class="col-md-6">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'EVENT' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD"
                      d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z" />
                  </svg>
                  <span class="tooltip-text">{{ 'TOOLTIP_EVENT_DESC' | translate }}</span>
                </div>
              </div>
            </div>
            <input type="text" class="form-control" formControlName="what">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'LOCATION' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                  </svg>
                  <span class="tooltip-text">
                    {{ 'TOOLTIP_WHERE_DESC' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <input type="text" class="form-control" formControlName="where" placeholder="">
          </div>

          <div class="col-md-6 d-none">
            <label class="form-label">Gráfico</label>
            <input type="text" class="form-control" formControlName="graph">
          </div>
        </div>
      </div>

      <div formGroupName="availability">
        <h1 class="subtitle">{{'INFO_SESSION' | translate}}</h1>
        <div class="row mb-3">
          <div class="col-md-3 d-none">
            <label class="form-label">{{'MODE' | translate}}</label>
            <input type="text" class="form-control" formControlName="mode">
          </div>
          <div class="col-md-4">
            <label class="form-label">{{'DURATION' | translate}}</label>
            <select class="form-control" formControlName="length">
              <option *ngFor="let hour of hours" [value]="hour + ' hours'">{{ hour }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'FROM' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                  </svg>
                  <span class="tooltip-text">
                    {{ 'TOOLTIP_FROM_DESC' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <select class="form-control" formControlName="from">
              <option *ngFor="let hour of hours" [value]="hour + ' hours'">{{ hour }}</option>
            </select>
          </div>

          <div class="col-md-4">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'TO' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                  </svg>
                  <span class="tooltip-text">
                    {{ 'TOOLTIP_TO_DESC' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <select class="form-control" formControlName="to">
              <option *ngFor="let month of months" [value]="month + ' months'">{{ month }}</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'SPACING_SESSIONS' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                  </svg>
                  <span class="tooltip-text">
                    {{ 'TOOLTIP_SPACING_SESSIONS_DESC' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <select class="form-control" formControlName="buffer">
              <option *ngFor="let minute of minutes" [value]="minute + ' minutes'">{{ minute }}</option>
            </select>
          </div>

          <div class="col-md-6">
            <div class="form-label-with-tooltip">
              <label class="form-label">{{ 'IGNORE_EVENTS' | translate }}</label>
              <div class="tooltip-box">
                <div class="hover-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                  </svg>
                  <span class="tooltip-text">
                    {{ 'TOOLTIP_IGNORE_EVENTS_DESC' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <select class="form-select" formControlName="ignore_all_day_events">
              <option [ngValue]="true">{{ 'YES' | translate }}</option>
              <option [ngValue]="false">{{ 'NO' | translate }}</option>
            </select>
          </div>
        </div>
      </div>

      <div formArrayName="availability_constraints">
        <h4 class="subtitle">{{'AVAILABILITY_RESTRICTIONS' | translate}}</h4>
        <div *ngFor="let constraint of availabilityConstraints.controls; let i = index">
          <div [formGroupName]="i">
            <div formGroupName="allow_day_and_time">
              <div class="row mb-3">
                <div class="col-md-3">
                  <label class="form-label">{{'DAY' | translate}}</label>
                  <select class="form-select" formControlName="day">
                    <option *ngFor="let day of daysOfWeekInSpanish; let i = index" [value]="getDayInEnglish(i)">{{ day
                      }}</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label class="form-label">{{'START_PROJECT' | translate}}</label>
                  <input type="time" class="form-control" formControlName="start">
                </div>
                <div class="col-md-3">
                  <label class="form-label">{{'END' | translate}}</label>
                  <input type="time" class="form-control" formControlName="end">
                </div>
                <div class="col-md-3">
                  <button type="button" class="btn btn-danger btn-sm mt-4"
                    (click)="removeAvailabilityConstraint(i)">{{'DELETE' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="button" class="btn primary-btn mb-3" (click)="addAvailabilityConstraint()">{{'ADD_RESTRICTION' |
          translate}}
        </button>
      </div>

      <div formArrayName="block_hours">
        <h5 class="subtitle">{{ 'TIME_LOCKING' | translate }}</h5>
        <div *ngFor="let blockHour of blockHoursControls; let i = index" [formGroupName]="i">
          <div class="row  mb-3">
            <div class="col-md-3">
              <label class="form-label">{{ 'START_TIME' | translate }}</label>
              <input type="time" class="form-control" formControlName="start">
            </div>
            <div class="col-md-3">
              <label class="form-label">{{ 'START_END' | translate }}</label>
              <input type="time" class="form-control" formControlName="end">
            </div>
            <div class="col-md-3">
              <button type="button" class="btn btn-danger btn-sm mt-4" (click)="removeBlockHours(i)">
                {{'DELETE' | translate}}
              </button>
            </div>
          </div>
        </div>

        <button type="button" class="btn primary-btn mb-3" (click)="addBlockHours()">
          {{ 'ADD_BLOCKING' | translate }}
        </button>
      </div>

      <div formArrayName="customer_fields">
        <h3 class="subtitle">{{'CUSTOMER_FIELDS' | translate}}</h3>

        <div *ngFor="let field of customerFields.controls; let i = index" [formGroupName]="i">
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">{{'TITLE' | translate}}:</label>
              <input class="form-control" type="text" formControlName="title" placeholder="{{'TITLE' | translate}}" />
            </div>
            <div class="col-md-6">
              <div class="form-label-with-tooltip">
                <label class="form-label">Prefilled:</label>
                <div class="tooltip-box">
                  <div class="hover-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                    </svg>
                    <span class="tooltip-text">
                      {{ 'TOOLTIP_PREFILLED_DESC' | translate }}
                    </span>
                  </div>
                </div>
              </div>

              <input class="form-control" type="text" formControlName="prefilled" placeholder="Prefilled" />
            </div>

            <div class="col-md-6">
              <div class="form-label-with-tooltip">
                <label class="form-label">{{ 'REQUIRED' | translate }}:</label>
                <div class="tooltip-box">
                  <div class="hover-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                    </svg>
                    <span class="tooltip-text">
                      {{ 'TOOLTIP_REQUIRED_DESC' | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <input class="form-control" type="text" formControlName="required" placeholder="True - False" />
            </div>

            <div class="col-md-6">
              <div class="form-label-with-tooltip">
                <label class="form-label">{{ 'ONLY_READ' | translate }}:</label>
                <div class="tooltip-box">
                  <div class="hover-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/>
                    </svg>
                    <span class="tooltip-text">
                      {{ 'TOOLTIP_ONLY_READ_DESC' | translate }}
                    </span>
                  </div>
                </div>
              </div>

              <input class="form-control" type="text" formControlName="readonly" placeholder="{{ 'ONLY_READ' | translate }}" />
            </div>


            <div class="col-md-3">
              <button class="btn btn-danger mt-4" type="button" (click)="removeCustomerField(i)">{{'DELETE' |
                translate}}</button>
            </div>
          </div>
        </div>

        <button class="btn primary-btn mb-3" type="button" (click)="addCustomerField()">{{'ADD_FIELD' |
          translate}}</button>

      </div>

      <label class="form-label">{{'ASSIGN' | translate}}</label>
      <div class="radios">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="categoryOption" value="categories"
            (change)="onOptionChange('categories')">
          <label class="form-check-label" for="categories">{{ 'CATEGORY' | translate }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="categoryOption" value="subCategories"
            (change)="onOptionChange('subCategories')">
          <label class="form-check-label" for="subCategories">{{ 'SUBCATEGORY' | translate }}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="categoryOption" value="subSubcategories"
            (change)="onOptionChange('subSubcategories')">
          <label class="form-check-label" for="subSubcategories">{{ 'SUB_SUBCATEGORIES' | translate }}</label>
        </div>
      </div>
      <div class="select-container">
        <div *ngIf="selectedOption === 'categories'" class="form-floating col-md-10">
          <select class="form-select" (change)="onCategoryChange($event)" required>
            <option selected disabled>{{ 'SELECT_CATEGORY' | translate }}</option>
            <option *ngFor="let category of categories" [value]="category.categoryId">
              {{ category.categoryName }}
            </option>
          </select>
        </div>

        <div *ngIf="selectedOption === 'subCategories'" class="form-floating col-md-10">
          <select class="form-select" (change)="onSubCategoryChange($event)" required>
            <option selected disabled>{{ 'SELECT_SUBCATEGORY' | translate }}</option>
            <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
              {{ subCategory.subCategoryName }}
            </option>
          </select>
        </div>

        <div *ngIf="selectedOption === 'subSubcategories'" class="form-floating col-md-10">
          <select class="form-select" (change)="onSubSubCategoryChange($event)" required>
            <option selected disabled>{{ 'SELECT_SUB_SUBCATEGORY' | translate }}</option>
            <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
              {{ subSubCategory.subSubCategoryName }}
            </option>
          </select>
        </div>
      </div>
      <button type="button" class="btn primary-btn" (click)="validateFormData()">{{'CREATE_PROJECT' |
        translate}}</button>


    </form>
  </div>
</div>



<div class="modal client-confirm-modal" id="successProjectModal" tabindex="-1">
  <div class="modal-dialog client-modal-dialog" role="document">
    <div class="modal-content client-modal-content">
      <div class="modal-body client-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{'PROJECT_CREATE_SUCCESS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeSuccessModal()">{{'CLOSE' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="requiredProjectModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{ 'ALL_FIELDS_REQUIRED' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="errorProjectModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_CREATE_PROJECT' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
